import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import {
  DisplayedDecision,
  DisplayedStep,
  SelectedDecision,
  StepDTO,
} from '@/model/domain/instruction/step.ts';
import { BasicActionDefinitionDTO } from '@/model/domain/instruction/instruction.ts';
import InstructionTitleCard from './instruction_title_card';
import InstructionStepCard from './instruction_step_card';
import { ActionTitleCardProps } from '@/screens/edit_instruction/components/instruction_title_card/interface';
import { toDisplayedUntilDecision } from '@/model/domain/instruction/step_helper';

const Content = styled.div`
  margin-left: 16px;
  flex-direction: column;
  display: flex;
  width: 100%;
  flex-shrink: 9;
  align-items: center;

  > div {
    width: 100%;
    //margin-bottom: 16px;
    justify-self: center;
  }
`;

const TitleCardWrapper = styled.div`
  padding-right: 24px;
`;

const StepDivider = styled.div`
  scroll-behavior: smooth;
  flex-direction: column;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 24px;
  overflow: visible;
  //max-height: calc(100vh - 320px);

  > div {
    width: 100%;
    //margin-bottom: 16px;
    justify-self: center;
  }
`;

const ButtonContainer = styled(CenteredButtons)`
  padding-bottom: 24px;
`;

const INITIAL_STEP: StepDTO = {
  media: {
    full: '',
    thumb: '',
    fileType: 'PICTURE',
  },
  step: 1,
  decisionOptions: [],
  type: 'INSTRUCTION',
  additionalInfos: [],
  translations: [],
};

export type StepAction = 'delete' | 'increase' | 'decrease';

export interface ActionCardContentProps {
  readonly item: BasicActionDefinitionDTO;
  readonly onChange: ActionTitleCardProps['onChange'];
  readonly onCopyStep: (step: StepDTO) => void;
  readonly onPasteStep: (
    step: StepDTO,
    position: 'below' | 'above',
    parent?: DisplayedDecision
  ) => void;
  readonly onAddEmptyStep: (
    step: StepDTO,
    position: 'below' | 'above',
    parent?: DisplayedDecision
  ) => void;
  readonly onChangeStep: (
    oldStep: StepDTO,
    newStep: StepDTO,
    parent?: DisplayedDecision
  ) => void;
  readonly onChangeOrder: (step: StepDTO, action: StepAction) => void;
  readonly onAddFirstEmptyStep: () => void;
  readonly allowedToModify: boolean;
  readonly allowedToModifyConfig: boolean;
  readonly onAddDecision: (step: StepDTO, parent?: StepDTO) => void;
  readonly onSelect: (s: DisplayedStep, d: DisplayedDecision) => void;
  readonly selectedDecisions: readonly SelectedDecision[];
  readonly onEditDecision: (step: StepDTO) => void;
  readonly selectedLanguage?: string;
}

const InstructionContent = ({
  item,
  onChange,
  onChangeStep,
  onCopyStep,
  onPasteStep,
  onAddEmptyStep,
  onChangeOrder,
  onAddFirstEmptyStep,
  allowedToModify,
  allowedToModifyConfig,
  onAddDecision,
  onSelect,
  selectedDecisions,
  onEditDecision,
  selectedLanguage,
}: ActionCardContentProps) => {
  const [stepsTopLevel, setStepsTopLevel] = useState<readonly StepDTO[]>(
    item.steps
  );

  useEffect(() => {
    setStepsTopLevel(item.steps);
  }, [item, selectedLanguage]);

  const displayedSteps = toDisplayedUntilDecision(
    stepsTopLevel,
    selectedDecisions
  );

  const stepsEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(function () {
      stepsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 1);
  };

  const onInternalSelect = (s: DisplayedStep, d: DisplayedDecision) => {
    onSelect(s, d);
    scrollToBottom();
  };

  return (
    <Content>
      <TitleCardWrapper id="solution_title">
        <InstructionTitleCard
          item={item}
          onChange={onChange}
          allowedToModify={allowedToModify}
          allowedToModifyConfig={allowedToModifyConfig}
          selectedLanguage={selectedLanguage}
        />
      </TitleCardWrapper>
      <StepDivider>
        {displayedSteps.length > 0 ? (
          displayedSteps.map((almostStep, index) => {
            return (
              <>
                <div className="step" key={index}>
                  <InstructionStepCard
                    length={displayedSteps.length}
                    onEditDecision={onEditDecision}
                    index={almostStep.orig.step}
                    key={almostStep.orig.id || almostStep.orig.dummyId}
                    onCopyStep={onCopyStep}
                    // onCutStep={onCutStep}
                    onPasteStep={onPasteStep}
                    onAddEmptyStep={onAddEmptyStep}
                    onChangeStep={onChangeStep}
                    onChangeOrder={onChangeOrder}
                    item={almostStep}
                    onSelect={onInternalSelect}
                    allowedToModify={allowedToModify}
                    canBeMovedDown={
                      !(
                        displayedSteps.length > index + 1 &&
                        displayedSteps[index + 1].decisionOptions.length > 0
                      ) && !(displayedSteps.length - 1 === index)
                    }
                    selectedLanguage={selectedLanguage}
                  />
                </div>
                {index === displayedSteps.length - 1 &&
                  almostStep.decisionOptions.length === 0 && (
                    <ButtonContainer>
                      <div id="add_step">
                        <Button
                          type="secondary"
                          label="add_step"
                          onClick={() => {
                            onAddEmptyStep(
                              almostStep.orig,
                              'below',
                              almostStep.parent
                            );
                            scrollToBottom();
                          }}
                          disabled={!allowedToModify}
                        />
                      </div>
                      <div id="add_decision">
                        <Button
                          type="secondary"
                          label="add_decision"
                          onClick={() => {
                            onAddDecision(INITIAL_STEP, almostStep.orig);
                            scrollToBottom();
                          }}
                          disabled={!allowedToModify}
                        />
                      </div>
                    </ButtonContainer>
                  )}
              </>
            );
          })
        ) : (
          <ButtonContainer>
            <div id="add_step">
              <Button
                type="secondary"
                label="add_step"
                onClick={() => onAddFirstEmptyStep()}
                disabled={!allowedToModify}
              />
            </div>
            <div id="add_decision">
              <Button
                type="secondary"
                label="add_decision"
                onClick={() => {
                  onAddDecision(INITIAL_STEP);
                  scrollToBottom();
                }}
                disabled={!allowedToModify}
              />
            </div>
          </ButtonContainer>
        )}
        <div ref={stepsEndRef} />
      </StepDivider>
    </Content>
  );
};

export default InstructionContent;
