import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { Dropdown } from 'semantic-ui-react';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import { StepNumber } from '@/components/StepNumber.tsx';
import { AdditionSolutionInfo } from '@/model/domain/instruction/instruction.ts';
import TextAreaWithCount from '../../../components/text_area_with_count';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import AdditionalSolutionInfo from './additional_solution_info';
import { StepAction } from './editable_content';
import {
  ActionStepContainer,
  DecisionBtn,
  DecisionBtnEditRowContainer,
  DecisionFooterContainer,
  DecisionHeaderContainer,
  FixedWidthDecisionBtn,
} from '@/components/instruction/components.tsx';
import StepMedia from '../../../components/instruction/step_media';
import {
  DisplayedDecision,
  DisplayedStep,
  StepDTO,
  Translation,
} from '@/model/domain/instruction/step';
import {
  areStandardDecisionOptions,
  getTranslation,
  sortTranslationsByLang,
} from '@/model/domain/instruction/helper.ts';
import _ from 'lodash';

interface LineProps {
  hidden: boolean;
}

const StyledTextArea = styled(TextAreaWithCount)`
  & {
    width: 100%;
    opacity: 100% !important;
  }
`;
const ActionStepHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
  box-shadow: inset 0 -1px 0 0 #efefef;
`;
const HeadLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionStepContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  width: 1px;
  height: 24px;
  margin: 0 auto;
  border: solid 2px hsl(0, 0%, 59%);

  visibility: ${(props: LineProps) => (props.hidden ? 'hidden' : 'visible')};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 60px;
`;

interface DecisionButtonsProps {
  readonly value: DisplayedStep;
  readonly onSelect: (s: DisplayedStep, d: DisplayedDecision) => void;
}

const StandardDecisions = ({
  value,
  onSelect,
}: Readonly<DecisionButtonsProps>) => {
  const [yesDecision, noDecision] = value.decisionOptions;

  return (
    <>
      <FixedWidthDecisionBtn
        type="green"
        addStep={yesDecision.orig.steps.length <= 0}
        text={yesDecision.orig.name}
        inactive={false}
        selected={yesDecision.isSelected}
        onClick={() => onSelect(value, yesDecision)}
      />
      <FixedWidthDecisionBtn
        type="red"
        addStep={noDecision.orig.steps.length <= 0}
        text={noDecision.orig.name}
        inactive={false}
        selected={noDecision.isSelected}
        onClick={() => onSelect(value, noDecision)}
      />
    </>
  );
};

const CustomDecisions = ({
  value,
  onSelect,
}: Readonly<DecisionButtonsProps>) => {
  return (
    <>
      {value.decisionOptions.map((decision) => (
        <DecisionBtn
          type="custom"
          key={decision.orig.id}
          addStep={decision.orig.steps.length <= 0}
          text={decision.orig.name}
          inactive={false}
          selected={decision.isSelected}
          onClick={() => onSelect(value, decision)}
        />
      ))}
    </>
  );
};

export interface XDecisionRowProps {
  item: DisplayedStep;
  inactive?: boolean;
  decisionDescription?: string;
  onDescriptionBlur: () => void;
  onDescriptionChange: (description: string) => void;
  onDelete: () => void;
  onSelect: (s: DisplayedStep, d: DisplayedDecision) => void;
  onChangeStep: (
    oldStep: StepDTO,
    step: StepDTO,
    parent?: DisplayedDecision
  ) => void;
  onEditDecisions: () => void;
  allowedToModify: boolean;
  onAddEmptyStep: (
    step: StepDTO,
    position: 'below' | 'above',
    parent?: DisplayedDecision
  ) => void;
}

const XDecisionRow = ({
  item,
  onEditDecisions,
  decisionDescription,
  onDescriptionBlur,
  onDescriptionChange,
  onDelete,
  onSelect,
  onChangeStep,
  allowedToModify,
  onAddEmptyStep,
}: Readonly<XDecisionRowProps>) => {
  const intl = useIntl();
  const theme = useTheme();
  const hasStandardDecisions = areStandardDecisionOptions(
    item.decisionOptions.map((d) => d.orig)
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onLocalDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    onDescriptionChange(e.target.value);
  };

  return (
    <ActionStepContainer>
      <DecisionHeaderContainer>
        <IconWrapper>
          <Icon color={theme.colors.black} name="decision" size={28} />
        </IconWrapper>
        <Button
          type="tertiary"
          noMargin
          icon="more"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <Dropdown
            onClick={() => setDropdownOpen(!dropdownOpen)}
            icon={<Icon name="more" color={theme.colors.black} size={22} />}
            style={{ display: 'flex', alignItems: 'center' }}
            open={dropdownOpen}
            onClose={() => setDropdownOpen(false)}
          >
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                text={intl.formatMessage({ id: 'add_step_above' })}
                onClick={() => {
                  onAddEmptyStep(item.orig, 'above', item.parent);
                }}
              />
              <Dropdown.Item
                text={intl.formatMessage({ id: 'remove' })}
                onClick={onDelete}
                disabled={!allowedToModify}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Button>
      </DecisionHeaderContainer>

      <ActionStepContent>
        <StepMedia
          step={item.orig}
          onChangeStep={(oldStep, newStep) =>
            onChangeStep(oldStep, newStep, item.parent)
          }
          allowedToModify={allowedToModify}
        />
      </ActionStepContent>

      <StyledTextArea
        onBlur={onDescriptionBlur}
        name="description"
        required
        onChange={onLocalDescriptionChange}
        defaultValue={decisionDescription || ''}
        value={decisionDescription}
        placeholder={
          item.orig.error
            ? item.orig.error
            : intl.formatMessage({ id: 'add_solution_decision_hint' })
        }
        showCount
        maxLength={500}
        disabled={!allowedToModify}
      />
      <DecisionFooterContainer>
        <DecisionBtnEditRowContainer>
          {hasStandardDecisions ? (
            <StandardDecisions value={item} onSelect={onSelect} />
          ) : (
            <CustomDecisions value={item} onSelect={onSelect} />
          )}
          <Button type="tertiary" icon="edit" onClick={onEditDecisions} />
        </DecisionBtnEditRowContainer>
      </DecisionFooterContainer>
    </ActionStepContainer>
  );
};

interface ActionStepCardProps {
  readonly length: number;
  readonly item: DisplayedStep;
  readonly onAddEmptyStep: (
    step: StepDTO,
    position: 'below' | 'above',
    parent?: DisplayedDecision
  ) => void;
  readonly onCopyStep: (step: StepDTO) => void;
  readonly onPasteStep: (
    referencedStep: StepDTO,
    position: 'below' | 'above',
    parent?: DisplayedDecision
  ) => void;
  readonly onSelect: (s: DisplayedStep, d: DisplayedDecision) => void;
  readonly index: number;
  readonly onChangeStep: (
    oldStep: StepDTO,
    step: StepDTO,
    parent?: DisplayedDecision
  ) => void;
  readonly onChangeOrder: (step: StepDTO, action: StepAction) => void;
  readonly onEditDecision: (step: StepDTO) => void;
  readonly allowedToModify: boolean;
  readonly canBeMovedDown: boolean;
  readonly selectedLanguage?: string;
}

const InstructionStepCard = ({
  item,
  length,
  onCopyStep,
  onPasteStep,
  onAddEmptyStep,
  onChangeStep,
  onChangeOrder,
  onSelect,
  allowedToModify,
  onEditDecision,
  canBeMovedDown,
  selectedLanguage,
}: ActionStepCardProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const inputEl = useRef<HTMLTextAreaElement>(null);
  const [description, setDescription] = useState<string>(
    getTranslation(item.orig.translations, '', selectedLanguage) || ''
  );
  const [additionalInfos, setAdditionalInfos] = useState<
    readonly AdditionSolutionInfo[]
  >(item.orig.additionalInfos || []);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    inputEl.current !== null && inputEl.current.focus();
  }, [item]);

  const origDescription = useMemo(() => {
    return getTranslation(item.orig.translations, '', selectedLanguage);
  }, [item.orig, selectedLanguage]);

  //const prevLang = usePrevious(selectedLanguage);

  useEffect(() => {
    if (origDescription !== description) {
      setDescription(origDescription || '');
    }
  }, [origDescription]);

  const onChangeStepLocal = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    if (
      !(!additionalInfos.length && !item.orig.additionalInfos.length) ||
      !_.isEqual(additionalInfos, item.orig.additionalInfos)
    ) {
      onChangeStep(item.orig, { ...item.orig, additionalInfos }, item.parent);
    }
  }, [additionalInfos]);

  useEffect(() => {
    if (origDescription !== description) {
      handleOnBlur();
    }
  }, [description]);

  const handleOnBlur = () => {
    if (selectedLanguage) {
      const updatedTranslations: Translation[] = sortTranslationsByLang([
        ...item.orig.translations.filter(
          (l) => l.language !== selectedLanguage
        ),
        { language: selectedLanguage, translation: description },
      ]);
      onChangeStep(
        item.orig,
        {
          ...item.orig,
          translations: updatedTranslations,
        },
        item.parent
      );
    }
  };

  if (item.decisionOptions && item.decisionOptions.length > 0) {
    return (
      <Card
        style={{
          marginBottom: '24px',
          paddingBottom: 12,
        }}
        id="decision"
      >
        <XDecisionRow
          item={item}
          onEditDecisions={() => onEditDecision(item.orig)}
          decisionDescription={description}
          onDescriptionChange={setDescription}
          onDescriptionBlur={handleOnBlur}
          onDelete={() => {
            onChangeOrder(item.orig, 'delete');
          }}
          onSelect={onSelect}
          onChangeStep={onChangeStep}
          allowedToModify={allowedToModify}
          onAddEmptyStep={onAddEmptyStep}
        />
      </Card>
    );
  } else {
    return (
      <>
        <Card
          style={{
            marginBottom:
              item.orig.step === length && item.decisionOptions.length === 0
                ? '24px'
                : 0,
            paddingBottom: 12,
          }}
        >
          <ActionStepHeader>
            <HeadLine>
              <h2 className="SectionTitleLeftLarge StepTitle">
                <FormattedMessage id="step" />
              </h2>
              <StepNumber>{item ? item.orig.step : 1}</StepNumber>
            </HeadLine>
            <RightAlignedButtons>
              <Button
                type="secondary"
                icon="arrow-up-light"
                onClick={() => onChangeOrder(item.orig, 'increase')}
                disabled={!allowedToModify || item.localIndex === 0}
              />
              <Button
                type="secondary"
                icon="arrow-down-light"
                onClick={() => onChangeOrder(item.orig, 'decrease')}
                disabled={!allowedToModify || !canBeMovedDown}
              />
              <Button
                type="tertiary"
                noMargin
                icon="more"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <Dropdown
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  icon={
                    <Icon name="more" color={theme.colors.black} size={22} />
                  }
                  style={{ display: 'flex', alignItems: 'center' }}
                  open={dropdownOpen}
                  onClose={() => setDropdownOpen(false)}
                >
                  <Dropdown.Menu direction="left">
                    <Dropdown.Item
                      text={intl.formatMessage({ id: 'cut' })}
                      onClick={() => {
                        onCopyStep(item.orig);
                        onChangeOrder(item.orig, 'delete');
                      }}
                      disabled={!allowedToModify}
                    />
                    <Dropdown.Item
                      text={intl.formatMessage({ id: 'copy' })}
                      onClick={() => onCopyStep(item.orig)}
                    />
                    <Dropdown.Item
                      disabled={
                        !(dropdownOpen && localStorage.getItem('copiedStep'))
                      }
                      text={intl.formatMessage({ id: 'paste_below' })}
                      onClick={() =>
                        onPasteStep(item.orig, 'below', item.parent)
                      }
                    />
                    <Dropdown.Item
                      text={intl.formatMessage({ id: 'add_step_above' })}
                      onClick={() => {
                        onAddEmptyStep(item.orig, 'above', item.parent);
                      }}
                    />
                    <Dropdown.Item
                      text={intl.formatMessage({ id: 'add_step_below' })}
                      onClick={() => {
                        onAddEmptyStep(item.orig, 'below', item.parent);
                      }}
                    />
                    <Dropdown.Item
                      text={intl.formatMessage({ id: 'remove' })}
                      onClick={() => onChangeOrder(item.orig, 'delete')}
                      disabled={!allowedToModify}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Button>
            </RightAlignedButtons>
          </ActionStepHeader>
          <ActionStepContent>
            <StepMedia
              step={item.orig}
              onChangeStep={(oldStep, newStep) =>
                onChangeStep(oldStep, newStep, item.parent)
              }
              allowedToModify={allowedToModify}
            />
          </ActionStepContent>
          <StyledTextArea
            onBlur={handleOnBlur}
            name="description"
            required
            disabled={!allowedToModify}
            onChange={onChangeStepLocal}
            value={description}
            placeholder={
              item.orig.error
                ? item.orig.error
                : intl.formatMessage({ id: 'add_solution_step_hint' })
            }
            showCount
            maxLength={500}
          />
          <AdditionalSolutionInfo
            infos={additionalInfos}
            editMode
            allowedToModify={allowedToModify}
            updateInfos={setAdditionalInfos}
          />
        </Card>
        <div>
          <Line hidden={item.orig.step === length} />
        </div>
      </>
    );
  }
};

export default InstructionStepCard;
