import _ from 'lodash';
import { Grid } from 'semantic-ui-react';
import { usePrompt } from '@dev/base-web/dist/view/helpers/navigation_helpers';
import EventCard from '../../components/Card/EventCard';
import AddItems from '../../components/overlays/addItems';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import styled from 'styled-components';
import { useNotificationForOperation } from '@dev/base-web/dist/view/helpers/notification_helpers';
import { EventConfigurationCard } from './components/configuration_card';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { HeaderButtons } from '../../components/StyledComponents';
import { ErrorScreen } from '@dev/base-web/dist/view/screens/error';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { CONFIG as EVENTS_CONFIG } from '../events/interfaces';
import { EventDefinitionProps } from './interface';
import {
  EventDefinition,
  EventDefinitionTranslation,
  EventRule,
} from '@/model/domain/event/event_definition.ts';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { EventDefinitionPropertiesOnChangeValue } from './components/event_definition_properties';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import Header from '@dev/base-web/dist/view/components/global/header_view';

const Container = styled.div`
  background-color: #f7f7f7;
`;

const ContentContainer = styled.div`
  padding: 12px 0px 24px 48px;
`;

type EventDefinitionData = EventDefinition | undefined;

export const EventDetail: React.FC<EventDefinitionProps> = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [item, setItem] = useState<EventDefinitionData>();
  const [addInstructionShown, setAddInstructionShown] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const eventIdParam = params.id as string;
  const configurationTab = Number.parseInt(searchParams.get('tab') ?? '0');
  const setConfigurationTab = (tabIndex: number) =>
    setSearchParams({ tab: String(tabIndex) });

  usePrompt(
    unsavedChanges,
    intl.formatMessage({ id: 'unsaved_changes_prompt' })
  );

  useEffect(() => {
    props.getAllManufacturingEntities();
    props.getFeatureConfiguration();
  }, []);

  useEffect(() => {
    props.getEvent(eventIdParam);
  }, [intl.locale]);

  useEffect(() => {
    props.event && setItem(props.event);
  }, [props.event]);

  useEffect(() => {
    if (_.isEqual(item, props.event)) {
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
    }
  }, [item]);

  useNotificationForOperation(
    props.eventUpdated,
    'changes_saved',
    'changes_saved',
    'changes_saved',
    intl
  );

  useNotificationForOperation(
    props.instructionAddedToEvent,
    'solution_add_success',
    'solution_add_success',
    'solution_add_success',
    intl
  );

  useNotificationForOperation(
    props.instructionRemovedFromEvent,
    'solution_removed_success',
    'solution_removed_success',
    'solution_removed_success',
    intl
  );

  useNotificationForOperation(
    props.commentRemovedFromEvent,
    'comment_removed_success',
    'comment_removed_success',
    'comment_removed_success',
    intl
  );

  const getEvent = () => {
    props.getEvent(eventIdParam);
  };

  const updateEvent = () => {
    if (item) {
      props.updateEvent(item.id, {
        ...item,
        subscribers: item.subscribers.map((s) => s.id),
        actions: item.actions.map((a) => a.id),
      });
      setUnsavedChanges(false);
    }
  };

  const changeValues = (e: EventDefinitionPropertiesOnChangeValue) => {
    if (item) {
      setUnsavedChanges(true);

      if ('key' in e) {
        if (e.key === 'isHidden')
          setItem({ ...item, isHidden: !item.isHidden });
        else if (e.key === 'excludeFromStats')
          setItem({ ...item, excludeFromStats: !item.excludeFromStats });
      } else if ('target' in e) {
        if (e.target.name in item) {
          setItem({
            ...item,
            [e.target.name]: e.target.value,
          });
        } else if (
          'target' in e &&
          (e.target.name.includes('name-') ||
            e.target.name.includes('description-'))
        ) {
          const nameParts = e.target.name.split('-');
          const language = nameParts[1];
          const isEventData = nameParts[2] === 'true';
          const index = item.translations.findIndex(
            (translation) =>
              translation.language.toLowerCase() === language.toLowerCase()
          );

          if (index !== -1) {
            if (e.target.name.includes('name-')) {
              const newTranslations = [...item.translations];
              const updatedTranslation = {
                ...newTranslations[index],
                name: e.target.value,
              };
              newTranslations[index] = updatedTranslation;

              if (isEventData) {
                setItem({
                  ...item,
                  name: e.target.value,
                  translations: newTranslations,
                });
                return;
              } else {
                setItem({ ...item, translations: newTranslations });
              }
            } else if (
              'target' in e &&
              e.target.name.includes('description-')
            ) {
              const newTranslations = [...item.translations];
              const updatedTranslation = {
                ...newTranslations[index],
                description: e.target.value,
              };
              newTranslations[index] = updatedTranslation;

              if (isEventData) {
                setItem({
                  ...item,
                  description: e.target.value,
                  translations: newTranslations,
                });
              } else {
                setItem({ ...item, translations: newTranslations });
              }
            }
          }
        }
      }
    }
  };

  const addTranslation = (translation: EventDefinitionTranslation) => {
    if (item) {
      setUnsavedChanges(true);
      setItem({
        ...item,
        translations: [...item.translations, translation],
      });
    }
  };

  const deleteTranslation = (language: string) => {
    if (item) {
      const index = item.translations.findIndex(
        (translation) =>
          translation.language.toLowerCase() === language.toLowerCase()
      );

      if (index !== -1) {
        setUnsavedChanges(true);
        const newTranslations = [...item.translations];
        newTranslations.splice(index, 1);
        setItem({
          ...item,
          translations: newTranslations,
        });
      }
    }
  };

  const swapButton = (type: EventType) => {
    if (item) {
      setItem({ ...item, type });
      setUnsavedChanges(true);
    }
  };

  const showAddInstruction = () => {
    setAddInstructionShown(true);
  };

  const closeAddInstruction = () => {
    setAddInstructionShown(false);
  };

  const onIsCommonNotificationChanged = (value: boolean) => {
    if (item) {
      setUnsavedChanges(true);
      setItem({
        ...item,
        isCommon: value,
      });
    }
  };

  const onRuleSaved = (index: number, rule: EventRule) => {
    if (item) {
      setUnsavedChanges(true);
      item.rules[index] = rule;
      setItem(item);
    }
  };

  const onRuleDeleted = (index: number) => {
    if (item) {
      setUnsavedChanges(true);
      item.rules.splice(index, 1);
      setItem(item);
    }
  };

  const onRuleAdded = (rule: EventRule) => {
    if (item) {
      item.rules.push(rule);
      setItem(item);
    }
  };

  const addInstruction = () => {
    getEvent();
    setAddInstructionShown(false);
  };

  const removeInstruction = (id: string) => {
    props.removeInstructionFromEvent(eventIdParam, id);
    if (item) {
      const { actions } = item;
      setItem({
        ...item,
        actions: actions.filter((action) => action.id !== id),
      });
    }
  };

  const {
    manufacturingEntities,
    manufacturingEntitiesLoading,
    featureConfiguration,
  } = props;

  const { write } = useAuthorizationCheckRWD(
    UserPrivilegePermission.EVENT,
    ApplicationCategory.SHANNON
  );

  const eventOperationError = props.eventOperationState.error;
  const isInternal = item ? item.isInternal : false;

  const saveDisabled =
    !unsavedChanges ||
    !write ||
    isInternal ||
    !item ||
    !item.name ||
    item.name === '' ||
    item.translations.some((t) => !t.name || t.name === '');

  return (
    <>
      {eventOperationError ? (
        <ErrorScreen
          title={
            eventOperationError.title === 'OBJECT_NOT_FOUND'
              ? 'not_found_title'
              : 'general_error_title'
          }
          description={
            eventOperationError.title === 'OBJECT_NOT_FOUND'
              ? 'event_not_found'
              : 'generic_error_desc'
          }
          icon={<Icon name="hand" size={64} />}
        />
      ) : (
        <Container>
          <Header
            title="edit_event"
            backButtonTitle="category_event_config"
            onBackPressed={() =>
              navigate(EVENTS_CONFIG.url ? EVENTS_CONFIG.url.path : '/', {
                replace: true,
              })
            }
          >
            <HeaderButtons>
              <Button
                type="primary"
                label={'save'}
                disabled={saveDisabled}
                onClick={updateEvent}
              />
            </HeaderButtons>
          </Header>
          <ContentContainer>
            {props.eventOperationState.loadingInProgress ||
            props.eventUpdated.operationInProgress ? (
              <Loader />
            ) : (
              item && (
                <Grid columns={2} style={{ padding: 0, marginTop: 0 }}>
                  <Grid.Column>
                    <EventCard
                      editable
                      eventDefId={eventIdParam}
                      eventId={''}
                      item={item}
                      openCallback={showAddInstruction}
                      removeInstruction={removeInstruction}
                      isLoading={props.eventOperationState.loadingInProgress}
                      currentUser={null}
                      configs={props.configs}
                      cleanInstruction={props.cleanInstruction}
                      onEventNeedsToBeRefreshed={() =>
                        props.getEvent(eventIdParam)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <EventConfigurationCard
                      item={item}
                      onIsCommonNotificationChanged={
                        onIsCommonNotificationChanged
                      }
                      onAddTranslation={addTranslation}
                      onDeleteTranslation={deleteTranslation}
                      onChange={changeValues}
                      swapButton={swapButton}
                      onRuleSaved={onRuleSaved}
                      onRuleDeleted={onRuleDeleted}
                      onRuleAdded={onRuleAdded}
                      manufacturingEntities={manufacturingEntities}
                      manufacturingEntitiesLoading={
                        manufacturingEntitiesLoading
                      }
                      allowedToModify={write && !isInternal}
                      getEventTrigger={props.getEvent}
                      externalTab={configurationTab}
                      tabCallback={(tab) => setConfigurationTab(tab)}
                      featureConfiguration={featureConfiguration}
                    />
                  </Grid.Column>
                </Grid>
              )
            )}
            <AddItems
              open={addInstructionShown}
              method="action"
              title={intl.formatMessage({ id: 'solutions' })}
              id={params}
              add={props.addInstructionsToEvent}
              closeCallback={closeAddInstruction}
              addCallback={addInstruction}
              items={item ? item.actions : []}
            />
          </ContentContainer>
        </Container>
      )}
    </>
  );
};
