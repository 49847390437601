import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import ManufacturingEntityDropdown from '@dev/base-web/dist/view/components/inputs/manufacturing_entity_dropdown';
import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import {
  EventRule,
  EventRuleGroupOperation,
  EventRuleType,
} from '../../../../model/domain/event/event_definition';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { StepNumber } from '../../../../components/StepNumber';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import OpeningConditionCard from './opening_condition_card';
import ClosingConditionCard from './closing_condition_card';
import DynamicConditionCard from './dynamic_condition_card';
import { useNavigate } from 'react-router';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { AggregationChildRulesTable } from './aggregation_child_rule_table';
import FeatureConfigurationInstance from '@dev/base-web/dist/model/domain/feature/feature_configuration';

const RuleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RuleButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border: 0px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  width: 100%;
  margin-bottom: 8px;
  outline: transparent;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;

  font-family: Relative;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;

  &:hover {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
  }

  &:active {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
    background-color: #fafafa;
  }
`;

const SelectedRuleCardContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 10px 16px 16px;
  margin-bottom: 8px;
`;

const RuleFormContainer = styled.div`
  margin-top: 16px;
`;

interface RuleFormRowProps {
  readonly centered?: boolean;
}

const RuleFormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${(props: RuleFormRowProps) =>
    props.centered ? 'center' : 'top'};
  margin-top: 20px;
`;

const RuleFormLabel = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #8c8c8c;
`;

const RuleStepContainer = styled.div`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RuleActionButtonContainer = styled(RightAlignedButtons)`
  margin-top: 28px;
`;

const StyledEntityDropDown = styled(ManufacturingEntityDropdown)`
  &&&& {
    width: 70%;
    min-width: 0px;
  }
`;

const HorizontalCard = styled.div`
  display: flex;
  flex-direction: row;
`;

const ClickContainer = styled.div`
  cursor: pointer;
`;

const ConditionCardWrapper = styled.div`
  width: 85%;
  overflow: auto;
`;

const LeftAlignWrapper = styled.div`
  width: 70%;
`;

const StyledSortableTableWrapper = styled(LeftAlignWrapper)`
  margin-top: -24px;
`;

const StyledTypeValue = styled.div`
  background-color: rgba(233, 245, 245, 0.5);
  border-radius: 8px;
  border: 1px solid rgba(36, 36, 36, 0.05);
  line-height: 20px;
  text-align: center;
  font-family: Relative;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #242424;
  font-size: 14px;
  padding: 6px 16px;
  width: fit-content;
`;

interface CardRuleProps {
  readonly eventDefinitionId: string;
  readonly key?: string;
  readonly ruleNumber: number;
  readonly rule: EventRule;
  readonly selected: boolean;
  readonly onRuleSelected: (rule: EventRule | null) => void;
  readonly onRuleDeleted: (index: number) => void;
  readonly onRuleSaved: (index: number, rule: EventRule) => void;
  readonly manufacturingEntities: ManufacturingEntity[];
  readonly manufacturingEntitiesLoading: boolean;
  readonly allowedToModify: boolean;
  readonly featureConfiguration: FeatureConfigurationInstance | null;
}

const RuleCard: React.FC<CardRuleProps> = ({
  eventDefinitionId,
  rule,
  ruleNumber,
  selected,
  onRuleSelected,
  //onRuleDeleted,
  manufacturingEntities,
  //manufacturingEntitiesLoading,
  allowedToModify,
  featureConfiguration,
}) => {
  const [tempRule, setTempRule] = useState<EventRule>(rule);

  const navigate = useNavigate();

  const onLocationSelected = (locations: readonly ManufacturingEntity[]) => {
    if (locations.length)
      setTempRule({ ...tempRule, manufacturingEntity: locations[0] });
  };

  const intl = useIntl();

  const manufacturingEntitiyQueryParam =
    !rule.id && tempRule.manufacturingEntity
      ? `&newManufacturingEntity=${tempRule.manufacturingEntity.id}`
      : rule
      ? `&ruleId=${rule.id}`
      : '';
  const wizardPath = `/events/${eventDefinitionId}/wizard?tab=1${manufacturingEntitiyQueryParam}`;

  const renderSelectedRule = (
    rule: EventRule,
    ruleNumber: number,
    onRuleSelected: (rule: EventRule | null) => void,
    manufacturingEntities: ManufacturingEntity[]
  ) => {
    return (
      <SelectedRuleCardContainer>
        <RuleStepContainer>
          <HorizontalCard>
            <FormattedMessage id="event_rule" />
            <StepNumber>{ruleNumber}</StepNumber>
          </HorizontalCard>
          <ClickContainer
            onClick={() => {
              onRuleSelected(null);
            }}
          >
            <Icon name="arrow-up-light" size={16} color="#8c8c8c" />
          </ClickContainer>
        </RuleStepContainer>
        <RuleFormContainer>
          <RuleFormRow centered>
            <RuleFormLabel>
              <FormattedMessage id="location" />
            </RuleFormLabel>
            <StyledEntityDropDown
              manufacturingEntities={manufacturingEntities}
              onManufacturingEntitySelected={onLocationSelected}
              selectedManufacturingEntity={rule.manufacturingEntity}
              ignoreSelectable
              placeholder={intl.formatMessage({
                id: 'choose_production_entity',
              })}
              noResultFoundLabel={intl.formatMessage({
                id: 'no_manufacturing_entity_found',
              })}
              disabled={rule.id !== undefined}
              error={!tempRule.manufacturingEntity}
              //loading={manufacturingEntitiesLoading}
            />
          </RuleFormRow>
          {rule.type === EventRuleType.DYNAMIC && (
            <RuleFormRow>
              <RuleFormLabel>
                <FormattedMessage id="begin" />
              </RuleFormLabel>
              <ConditionCardWrapper>
                <DynamicConditionCard rule={rule} />
              </ConditionCardWrapper>
            </RuleFormRow>
          )}
          {rule.type === EventRuleType.STANDARD && (
            <>
              <RuleFormRow>
                <RuleFormLabel>
                  <FormattedMessage id="begin" />
                </RuleFormLabel>
                <ConditionCardWrapper>
                  <OpeningConditionCard
                    condition={rule.open}
                    featureConfiguration={featureConfiguration}
                  />
                </ConditionCardWrapper>
              </RuleFormRow>

              <RuleFormRow>
                <RuleFormLabel>
                  <FormattedMessage id="end" />
                </RuleFormLabel>
                <ConditionCardWrapper>
                  <ClosingConditionCard
                    condition={rule.close}
                    featureConfiguration={featureConfiguration}
                  />
                </ConditionCardWrapper>
              </RuleFormRow>
            </>
          )}
          {rule.type === EventRuleType.AGGREGATED && (
            <>
              <RuleFormRow centered>
                <RuleFormLabel>
                  <FormattedMessage id="type" />
                </RuleFormLabel>
                <LeftAlignWrapper>
                  <StyledTypeValue>
                    <FormattedMessage id={'aggregated'} />
                  </StyledTypeValue>
                </LeftAlignWrapper>
              </RuleFormRow>
              <RuleFormRow centered>
                <RuleFormLabel>
                  <FormattedMessage id="operation" />
                </RuleFormLabel>
                <LeftAlignWrapper>
                  <StyledDropDown
                    disabled
                    selection
                    options={[
                      {
                        text: 'Any',
                        value: EventRuleGroupOperation.ANY,
                        key: EventRuleGroupOperation.ANY,
                      },
                      {
                        text: 'All',
                        value: EventRuleGroupOperation.ALL,
                        key: EventRuleGroupOperation.ALL,
                      },
                    ]}
                    value={rule.groupOperation}
                  />
                </LeftAlignWrapper>
              </RuleFormRow>
              <RuleFormRow>
                <RuleFormLabel>
                  <FormattedMessage id="events" />
                </RuleFormLabel>
                <StyledSortableTableWrapper>
                  <AggregationChildRulesTable
                    rules={rule.aggregationChildRules ?? []}
                  />
                </StyledSortableTableWrapper>
              </RuleFormRow>
            </>
          )}
          <RuleActionButtonContainer>
            {/*            <Button
              type="tertiary"
              label="delete"
              onClick={() => onRuleDeleted(ruleNumber)}
            />*/}
            {rule.type !== EventRuleType.AGGREGATED && (
              <Button
                type="primary"
                label="edit"
                icon={'edit'}
                onClick={() => navigate(wizardPath)}
                disabled={
                  !tempRule.manufacturingEntity ||
                  !allowedToModify ||
                  rule.type == 'DYNAMIC'
                }
              />
            )}
          </RuleActionButtonContainer>
        </RuleFormContainer>
      </SelectedRuleCardContainer>
    );
  };

  const renderRule = (
    ruleNumber: number,
    rule: EventRule,
    onRuleSelected: (rule: EventRule) => void
  ) => {
    return (
      <RuleButton onClick={() => onRuleSelected(rule)}>
        <RuleStepContainer>
          <FormattedMessage id="event_rule" />
          <StepNumber>{ruleNumber}</StepNumber>
        </RuleStepContainer>
        <Icon name="arrow-down-light" size={16} color="#8c8c8c" />
      </RuleButton>
    );
  };

  return (
    <RuleCardContainer>
      {!selected && renderRule(ruleNumber, rule, onRuleSelected)}
      {selected &&
        renderSelectedRule(
          rule,
          ruleNumber,
          onRuleSelected,
          manufacturingEntities
        )}
    </RuleCardContainer>
  );
};

export default RuleCard;
