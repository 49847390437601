import React, { Component } from 'react';
import styled from 'styled-components';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { CheckboxProps, Divider, DropdownProps, Form } from 'semantic-ui-react';
import ManufacturingEntity, {
  findManufacturingEntities,
} from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import UserUpdateDTO from '@dev/base-web/dist/model/domain/user/user_update_dto';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import UserSubscriptionTopic from '@dev/base-web/dist/model/domain/user/user_subscription_topic';
import { ConfigContainer } from './styled_components';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';
import { ManufacturingEntityMultiSelect } from '@dev/base-web/dist/view/components/inputs/manufacturing_entity_select';

const StyledDropdown = styled(StyledDropDown)`
  &&&& {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 14px;
  }
`;

const StyledManufacturingEntityDropdown = styled(
  ManufacturingEntityMultiSelect
)`
  &&&& {
    margin-top: 8px;
    font-size: 14px;
  }
`;

interface TasksCardExternalProps {
  readonly userData: UserUpdateDTO;
  readonly roleNames: string[];
  readonly isUserEditable?: boolean;
  readonly canChangeRole: boolean;
  readonly updateUserData: (newUserData: UserUpdateDTO) => void;
  readonly manufacturingEntities: ManufacturingEntity[];
}

type TasksCardProps = TasksCardExternalProps & WrappedComponentProps;

class TasksCard extends Component<TasksCardProps> {
  onRoleDropDownChanged = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    this.props.updateUserData({ role: data.value as string });
  };

  onEventTypeNotificationDropDownChanged = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    this.props.updateUserData({
      assignedEventTypes: data.value as EventType[],
    });
  };

  onLocationDropDownChanged = (manufacturingEntityIds: readonly string[]) => {
    const newEntities = findManufacturingEntities(
      this.props.manufacturingEntities,
      (e) => manufacturingEntityIds.includes(e.id)
    );

    const entitiesWithoutChildren = newEntities.map((entity) => ({
      ...entity,
      childs: [],
    }));
    this.props.updateUserData({
      manufacturingEntities: entitiesWithoutChildren,
    });
  };

  onSubscriptionTopicsChanged = (
    subscriptionTopics: UserSubscriptionTopic[]
  ) => {
    this.props.updateUserData({
      subscriptionTopics: subscriptionTopics,
    });
  };

  onReceiveCommonNotificationsChanged = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
    topic: UserSubscriptionTopic
  ) => {
    const checked = data.checked;
    const { subscriptionTopics } = this.props.userData;

    const newTopics = subscriptionTopics || [];

    if (checked) {
      newTopics.push(topic);
    } else {
      newTopics.forEach((item, index) => {
        if (item === topic) newTopics.splice(index, 1);
      });
    }

    this.onSubscriptionTopicsChanged(newTopics);

    event.stopPropagation();
  };

  render() {
    const manufacturingEntities = this.props.userData
      .manufacturingEntities as ManufacturingEntity[];
    const { isUserEditable, roleNames, canChangeRole } = this.props;
    const { role, assignedEventTypes } = this.props.userData;
    const { intl, manufacturingEntities: manufacturingEntityOptions } =
      this.props;
    const roleOptions = roleNames.map((role) => ({
      text: intl.formatMessage({ id: role.toLowerCase() }),
      value: role,
    }));
    const eventTypeOptions = Object.keys(EventType).map((type) => ({
      text: intl.formatMessage({ id: type.toLowerCase() }),
      value: type,
    }));

    return (
      <Card titleId={'responsibility'}>
        <ConfigContainer>
          <Form id={'user_settings'}>
            <Form.Field>
              <LabelWithHint label="role" />
              <StyledDropdown
                selection
                value={role}
                options={roleOptions}
                onChange={this.onRoleDropDownChanged}
                placeholder={intl.formatMessage({ id: 'select_role' })}
                error={!role}
                disabled={!canChangeRole}
              />
            </Form.Field>
            <Form.Field>
              <LabelWithHint
                label={'location'}
                hint={'assigned_location_hint'}
                alignHintText={'left'}
              />
              <StyledManufacturingEntityDropdown
                entities={manufacturingEntityOptions}
                values={
                  manufacturingEntities
                    ? manufacturingEntities.map((e) => e.id)
                    : []
                }
                onChange={this.onLocationDropDownChanged}
                error={!manufacturingEntities || !manufacturingEntities.length}
                disabled={!isUserEditable}
                loading={false}
              />
            </Form.Field>
            <Divider />
            <Form.Field>
              <LabelWithHint
                label={'assigned_event_types'}
                hint={'assigned_event_types_hint'}
                alignHintText={'left'}
              />
              <StyledDropdown
                selection
                multiple
                fluid
                value={assignedEventTypes}
                options={eventTypeOptions}
                onChange={this.onEventTypeNotificationDropDownChanged}
                placeholder={intl.formatMessage({ id: 'select_types' })}
                error={!role}
                disabled={!isUserEditable}
              />
            </Form.Field>
          </Form>
        </ConfigContainer>
      </Card>
    );
  }
}

export default injectIntl(TasksCard);
