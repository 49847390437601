import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { OperationMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import { ActionFeedbackDTO } from '../../model/redux/actions/interface';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import EventDetail from '../../model/domain/event/event';
import ConfigurationParameters from '@dev/base-web/dist/model/domain/configuration/configuration';
import { Instruction } from '../../model/domain/instruction/instruction';

export const CONFIG = ShannonConfig.CurrentEvents;

export interface CurrentEventsStoreProps {
  readonly currentInstruction: Instruction | null;
  readonly currentInstructionMeta: LoadingMetaState;
  readonly currentEvent: EventDetail | null;
  readonly currentEventMeta?: LoadingMetaState;
  readonly currentUser: UserDetails | null;
  readonly feedbackMeta: OperationMetaState;
  readonly closeEventUpdate: OperationMetaState;
  readonly configs: ConfigurationParameters | null;
}

export interface CurrentEventsDispatchProps {
  readonly getCurrentEvent: (id: string) => void;
  readonly sendInstructionFeedback: (
    eventId: string,
    instructionId: string,
    params: ActionFeedbackDTO
  ) => Promise<void>;
  readonly getCurrentInstruction: (
    eventId: string,
    actionId: string,
    language?: string
  ) => void;
  readonly cleanCurrentInstruction: () => void;
  readonly closeEvent: (eventId: string, closeDate?: number) => void;
}

export type CurrentEventsProps = CurrentEventsStoreProps &
  CurrentEventsDispatchProps;
