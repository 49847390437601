import { Translation } from '../../redux/actions/interface';
import { DecisionOptionDTO, StepDTO } from './step';
import { BasicActionDefinitionDTO, ActionDefinitionDTO } from './instruction';
import { isEqual } from 'lodash';

export const getTranslation = (
  translations: readonly Translation[],
  fallback?: string,
  selectedLanguage?: string,
  fallbackLanguage?: string
) => {
  if (selectedLanguage) {
    const tr = translations.find(
      (t) => t.language === selectedLanguage.toUpperCase()
    )?.translation;
    if (tr) return tr;
  }
  if (fallbackLanguage) {
    const tr = translations.find(
      (t) => t.language === fallbackLanguage.toUpperCase()
    )?.translation;
    if (tr) return tr;
  }
  return fallback;
};

export const areStandardDecisionOptions = (
  options: readonly DecisionOptionDTO[]
) =>
  options.length === 2 && options[0].name === 'yes' && options[1].name === 'no';

export const isInstructionValid = (
  instruction: BasicActionDefinitionDTO
): boolean => {
  if (
    !areAllTranslationSet(instruction.translations) ||
    !instruction.steps.length
  )
    return false;
  else return areStepsValid(instruction.steps);
};

export const areStepsValid = (steps: readonly StepDTO[]): boolean => {
  return (
    !!steps.length &&
    !steps.some(
      (s) =>
        !areAllTranslationSet(s.translations) ||
        !areDecisionOptionsValid(s.decisionOptions)
    )
  );
};

export const areAllTranslationSet = (translations: readonly Translation[]) => {
  return !translations.some((t) => !t.translation);
};

const areDecisionOptionsValid = (
  options: readonly DecisionOptionDTO[]
): boolean => {
  return (
    !options.length ||
    (options.length > 1 &&
      !options.some((o) => !o.name || !areStepsValid(o.steps)))
  );
};

export const addLanguageToSteps = (
  steps: readonly StepDTO[],
  newLanguage: string
): readonly StepDTO[] => {
  return steps.map((s) => {
    return {
      ...s,
      translations: addLanguageToTranslationSet(s.translations, newLanguage),
      decisionOptions: s.decisionOptions.map((o) => ({
        ...o,
        steps: addLanguageToSteps(o.steps, newLanguage),
      })),
    };
  });
};

export const addLanguageToTranslationSet = (
  translations: readonly Translation[],
  newLanguage: string
): readonly Translation[] => {
  if (
    translations.some(
      (t) => t.language.toUpperCase() === newLanguage.toUpperCase()
    )
  )
    return translations;
  else
    return [
      ...translations,
      { language: newLanguage.toUpperCase(), translation: '' },
    ];
};

export const removeDeprecatedNameFields = (
  instruction: ActionDefinitionDTO
) => {
  //TODO: remove this after API fix
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { ['name']: _, ...cleanedInstruction } = instruction;
  const s = removeDeprecatedStepDescriptions(cleanedInstruction.steps);
  return {
    ...cleanedInstruction,
    steps: s,
  };
};

export const removeDeprecatedStepDescriptions = (
  steps: readonly StepDTO[]
): StepDTO[] => {
  return steps.map((s) => {
    //TODO: remove this after API fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { ['description']: _, ...cleanedStep } = s;
    return {
      ...cleanedStep,
      decisionOptions: cleanedStep.decisionOptions.map((o) => {
        return {
          ...o,
          steps: removeDeprecatedStepDescriptions(o.steps),
        };
      }),
    };
  });
};

export const sortTranslationsByLang = (
  translations: readonly Translation[]
): Translation[] => {
  return [...translations].sort((a, b) =>
    b.translation.localeCompare(a.translation)
  );
};

export const sortAllTranslationFieldsByLang = (
  instruction: ActionDefinitionDTO
) => {
  return {
    ...instruction,
    translations: sortTranslationsByLang(instruction.translations),
    steps: instruction.steps.map((s) => sortStepTranslationsByLang(s)),
  };
};

const sortStepTranslationsByLang = (step: StepDTO): StepDTO => {
  return {
    ...step,
    translations: sortTranslationsByLang(step.translations),
    decisionOptions: step.decisionOptions.map((o) => {
      return {
        ...o,
        translations: sortTranslationsByLang(o.translations),
        steps: o.steps.map((s) => sortStepTranslationsByLang(s)),
      };
    }),
  };
};

export const sortAllInstructionStepsByNumber = (
  instruction: ActionDefinitionDTO
): ActionDefinitionDTO => {
  return {
    ...instruction,
    steps: sortAllStepsByNumber(instruction.steps),
  };
};

export const sortAllStepsByNumber = (steps: readonly StepDTO[]): StepDTO[] => {
  return steps
    .map((s) => {
      return {
        ...s,
        decisionOptions: s.decisionOptions.map((o) => {
          return {
            ...o,
            steps: sortAllStepsByNumber(o.steps),
          };
        }),
      };
    })
    .sort((a, b) => a.step - b.step);
};

export const anyTranslationsChanged = (
  old: BasicActionDefinitionDTO,
  updated: BasicActionDefinitionDTO
): boolean => {
  if (!isEqual(old.translations, updated.translations)) return true;
  else return anyStepTranslationChanged(old.steps, updated.steps);
};

export const anyStepTranslationChanged = (
  oldSteps: readonly StepDTO[],
  updatedSteps: readonly StepDTO[]
): boolean => {
  for (const updatedStep of updatedSteps) {
    if (!updatedStep.id || !oldSteps.some((s) => s.id === updatedStep.id)) {
      return true;
    }
    // This means this step was not in the original action.
    else {
      const oldStep = oldSteps.find((s) => s.id === updatedStep.id);
      if (
        oldStep &&
        (!isEqual(oldStep.translations, updatedStep.translations) ||
          (updatedStep.decisionOptions.length &&
            anyDecisionOptionTranslationChanged(
              oldStep.decisionOptions,
              updatedStep.decisionOptions
            )))
      )
        return true;
    }
  }

  return false;
};

const anyDecisionOptionTranslationChanged = (
  oldOptions: readonly DecisionOptionDTO[],
  updatedOptions: readonly DecisionOptionDTO[]
): boolean => {
  for (const updatedOption of updatedOptions) {
    if (!updatedOption.id || !oldOptions.some((o) => o.id === updatedOption.id))
      // This means this step was not in the original action.
      return true;
    else {
      const oldOption = oldOptions.find((s) => s.id === updatedOption.id);

      if (
        oldOption &&
        (!isEqual(oldOption.translations, updatedOption.translations) ||
          (updatedOption.steps.length &&
            anyStepTranslationChanged(oldOption.steps, updatedOption.steps)))
      )
        return true;
    }
  }

  return false;
};

export const isEditEqual = (
  update: BasicActionDefinitionDTO,
  existing: BasicActionDefinitionDTO | null
): boolean => {
  if (!existing) return false;
  else
    return !(
      !isEqual(update.translations, existing.translations) ||
      update.isHidden !== existing.isHidden ||
      update.accessLevel !== existing.accessLevel ||
      update.labels !== existing.labels ||
      !isEqual(update.steps, existing.steps)
    );
};
