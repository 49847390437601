import styled from 'styled-components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { Popup } from 'semantic-ui-react';

const StyledTriggerIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 auto important!;
  display: block important!;
`;

const TooltipBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const style = {
  paddingLeft: '2em',
  paddingRight: '2em',
};

interface Props {
  tourElement: any;
}

const TourGuidePopup = ({ tourElement }: Props) => {
  const renderToolTipIcon = () => {
    return (
      <TooltipBox>
        <StyledTriggerIcon name={'documentation'} color={'#242424'} size={38} />
      </TooltipBox>
    );
  };

  return (
    <Popup
      basic
      titleIcon={'info'}
      content={tourElement}
      position={'bottom right'}
      on="click"
      trigger={renderToolTipIcon()}
      style={style}
      hoverable
    />
  );
};

export default TourGuidePopup;
