import { AddEventsModalAllProps } from './interface';
import React, { useEffect, useState } from 'react';
import { BasicModalWithHeaderAndTwoButtons } from '@dev/base-web/dist/view/components/modal/modal';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import useSelectableRows from '@dev/base-web/dist/view/components/sortable_table/components/selectable_table_rows_hook';
import Event from '../../../../model/domain/event/event_definition';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import EventRow from './event_row';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import { IntlShape, useIntl } from 'react-intl';
import {
  createEventTypeDropdownIcon,
  TableContainer,
} from '../../../events/view';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';

export const createColumns = (
  intl: IntlShape,
  manufacturingEntities: ManufacturingEntity[],
  getAllManufacturingEntities: (query?: string) => void,
  manufacturingEntityLoading: boolean
): Array<Column> => [
  {
    title: 'type',
    sortKey: 'type',
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: Object.values(EventType).map((eventType) => {
      const dropdownIcon = createEventTypeDropdownIcon(eventType);
      return {
        image: dropdownIcon,
        value: eventType,
        textId: ' ',
        content: intl.formatMessage({ id: eventType.toLowerCase() }),
      };
    }),
    width: 1,
    fixedWidthInPx: 64,
  },
  {
    title: 'name',
    isLeftAligned: true,
    sortKey: 'name',
    filterType: ColumnFilterType.TEXT,
    width: 9,
  },
  {
    title: 'entity',
    isLeftAligned: true,
    sortKey: 'manufacturingEntity.id',
    filterType: ColumnFilterType.MANUFACTURING_ENTITY,
    filterOptions: manufacturingEntities,
    width: 7,
    getAllManufacturingEntities,
    useIdAsFilterValue: true,
    customFilterOperation: FilterOperation.CT_OPERATOR,
    isLoading: manufacturingEntityLoading,
  } as any,
];

const AddEventsModal: React.FC<AddEventsModalAllProps> = ({
  instructionId,
  modalVisible,
  onCancelPressed,
  addEventsToInstruction,
  onEventsAdded,
  events,
  eventsMeta,
  addEventsToInstructionMeta,
  moreEventsCanBeLoaded,
  manufacturingEntities,
  getAllManufacturingEntities,
  getEvents,
  manufacturingEntitiesLoadingInProgress,
}) => {
  const intl = useIntl();
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    setSelectedRows([]);
  }, [modalVisible]);

  const [selectedRows, setSelectedRows, selectRow, isRowSelected] =
    useSelectableRows<Event>();

  useEffect(() => {
    getAllManufacturingEntities();
  }, [getAllManufacturingEntities]);

  const prevUpdate = usePrevious(addEventsToInstructionMeta);

  useEffect(() => {
    if (
      prevUpdate &&
      prevUpdate.operationInProgress &&
      !addEventsToInstructionMeta.operationInProgress &&
      instructionId
    ) {
      onEventsAdded(selectedRows);
      onCancelPressed();
    }
  }, [addEventsToInstructionMeta]);

  const getNextEvents = (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => {
    const localFilters = instructionId
      ? [
          ...filters,
          new FilterData(
            'actionDefinition.id',
            FilterOperation.NOT_OPERATOR,
            instructionId
          ),
        ]
      : filters;

    getEvents(
      null,
      page,
      localFilters,
      sortKey,
      sortDirection,
      loadAllPagesUntilTheGivenOne
    );
    setPage(page);
  };

  const onOk = () => {
    if (instructionId)
      addEventsToInstruction(
        instructionId,
        selectedRows.map((r) => r.id)
      );
  };

  return (
    <BasicModalWithHeaderAndTwoButtons
      okLabel="add"
      cancelLabel="cancel"
      headerLabel={'add_events'}
      onOk={onOk}
      onClose={() => onCancelPressed()}
      onCancel={() => onCancelPressed()}
      open={modalVisible}
      okDisabled={selectedRows.length == 0}
      okLoading={addEventsToInstructionMeta.operationInProgress}
      modalSize={'large'}
    >
      <TableContainer>
        <SortableTable
          dataLength={events.length}
          hasMoreResults={moreEventsCanBeLoaded}
          columns={createColumns(
            intl,
            manufacturingEntities,
            getAllManufacturingEntities,
            manufacturingEntitiesLoadingInProgress
          )}
          initialSearchData={{
            sortConfig: { key: 'name', direction: SortingDirection.ASCENDING },
          }}
          getNextResults={getNextEvents}
          loadingInProgress={eventsMeta.loadingInProgress}
          error={eventsMeta.error}
          onAllRowsCheckboxChanged={(checked) =>
            setSelectedRows(checked ? events : [])
          }
          page={page}
          height={400}
          openFiltersOnMount
        >
          {events.map((event) => {
            return (
              <EventRow
                data={event}
                onRowChecked={(checked) => selectRow(event, checked)}
                selected={isRowSelected(event)}
              />
            );
          })}
        </SortableTable>
      </TableContainer>
    </BasicModalWithHeaderAndTwoButtons>
  );
};

export default AddEventsModal;
