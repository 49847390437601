import React, { useEffect, useState } from 'react';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { EventsListProps } from './interfaces';
import EventRow from './components/event_row';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { IntlShape, useIntl } from 'react-intl';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import { EventDefinitionStatus } from '../../../../model/domain/event/event_definition';
import styled from 'styled-components';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import { useDebouncedCallback } from 'use-debounce';

const createEventTypeDropdownIcon = (eventType: EventType) => {
  const { color, icon } = useEventTypeLook(eventType);

  return <Icon name={icon} color={color} size={16} />;
};

export const createColumns = (
  intl: IntlShape,
  manufacturingEntities: readonly ManufacturingEntity[],
  getAllManufacturingEntities: (query?: string) => void,
  manufacturingEntityLoading: boolean
): Array<Column> => [
  {
    title: 'type',
    sortKey: 'type',
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: Object.values(EventType).map((eventType) => {
      const dropdownIcon = createEventTypeDropdownIcon(eventType);
      return {
        image: dropdownIcon,
        value: eventType,
        textId: ' ',
        content: intl.formatMessage({ id: eventType.toLowerCase() }),
      };
    }),
    width: 1,
    fixedWidthInPx: 64,
  },
  {
    title: 'name',
    isLeftAligned: true,
    sortKey: 'name',
    filterType: ColumnFilterType.TEXT,
    width: 4,
  },
  {
    title: 'entity',
    isLeftAligned: true,
    sortKey: 'manufacturingEntity.name',
    filterKey: 'manufacturingEntity.id',
    filterType: ColumnFilterType.MANUFACTURING_ENTITY,
    filterOptions: manufacturingEntities,
    width: 4,
    getAllManufacturingEntities,
    useIdAsFilterValue: true,
    customFilterOperation: FilterOperation.CT_OPERATOR,
    isLoading: manufacturingEntityLoading,
  } as any, //TableHeaderManufacturingEntityFilterColumn,
  {
    title: 'solutions',
    isLeftAligned: false,
    sortKey: 'actionCount',
    filterType: ColumnFilterType.NUMBER,
    width: 2,
  },
  {
    title: 'modified_at',
    isLeftAligned: true,
    sortKey: 'modifiedDate',
    filterType: ColumnFilterType.DATE,
    width: 4,
  },
];

const TableContainer = styled.div`
  margin-top: 16px;
`;

const Events: React.FC<EventsListProps> = ({
  events,
  getEvents,
  eventLoadingInProgress,
  moreEventsCanBeLoaded,
  getAllManufacturingEntities,
  manufacturingEntities,
  previousEventSearchData,
  externalFilters,
  minHeight,
  card,
  manufacturingEntitiesMeta,
}) => {
  const intl = useIntl();
  const [lastSearchData, setLastSearchData] = useState<SearchData>(
    previousEventSearchData || {}
  );
  const [initialSearchData] = useState<SearchData | undefined>(
    previousEventSearchData ?? {
      page: 0,
      sortConfig: {
        key: 'modifiedDate',
        direction: SortingDirection.DESCENDING,
      },
    }
  );
  const [page, setPage] = useState<number>(0);

  const getLastSorConfigParams = () => {
    const sortKey = lastSearchData.sortConfig
      ? lastSearchData.sortConfig.key
      : undefined;
    const sortDirection = lastSearchData.sortConfig
      ? lastSearchData.sortConfig.direction
      : undefined;
    return { sortKey, sortDirection };
  };

  const debouncedGetEvents = useDebouncedCallback(getEvents, 700);

  useEffect(() => {
    setPage(0);
    const { sortKey, sortDirection } = getLastSorConfigParams();

    debouncedGetEvents(
      EventDefinitionStatus.ACTIVE,
      lastSearchData.page || 0,
      lastSearchData.filters || [],
      sortKey,
      sortDirection,
      true
    );
  }, [intl.locale]);

  useEffect(() => {
    getAllManufacturingEntities();
  }, [getAllManufacturingEntities]);

  const getNextEvents = (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => {
    debouncedGetEvents(
      EventDefinitionStatus.ACTIVE,
      page,
      filters,
      sortKey,
      sortDirection,
      loadAllPagesUntilTheGivenOne
    );
    setLastSearchData({
      page,
      filters,
      ...(sortKey && sortDirection
        ? { sortConfig: { key: sortKey, direction: sortDirection } }
        : {}),
    });
    setPage(page);
  };

  return (
    <TableContainer>
      <SortableTable
        dataLength={events.length}
        hasMoreResults={moreEventsCanBeLoaded}
        columns={createColumns(
          intl,
          manufacturingEntities,
          getAllManufacturingEntities,
          manufacturingEntitiesMeta.loadingInProgress
        )}
        getNextResults={getNextEvents}
        initialSearchData={initialSearchData}
        loadingInProgress={eventLoadingInProgress}
        page={page}
        card={card}
        externalFilters={externalFilters}
        maxHeight={400}
        minHeight={minHeight ?? '0px'}
      >
        {events.map((event) => {
          return <EventRow data={event} />;
        })}
      </SortableTable>
    </TableContainer>
  );
};

export default Events;
