import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css, useTheme } from 'styled-components';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import InstructionListContainer from '../../Lists/instruction_list_container';
import { getEventTypeLookForType } from '@dev/base-web/dist/view/components/global/common';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import EventElements from './components/event_elements';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { EventDefinition } from '@/model/domain/event/event_definition.ts';
import {
  EventInterface,
  EventStatusType,
  EventType,
} from '@dev/base-web/dist/model/domain/event/event_type';
import { useNavigate } from 'react-router';
import CloseTaskModal from './components/close_task_modal';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import SubsequentEventsList from './components/SubsequentEventsList';
import EventDetail from '../../../model/domain/event/event';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import ConfigurationParameters from '@dev/base-web/dist/model/domain/configuration/configuration';
import { useCallbackToOpenTabWithPathPrefix } from '@dev/base-web/dist/view/helpers/common';
import DarwinConfig from '@dev/base-web/dist/model/domain/authorization/darwin';
import { Dropdown } from 'semantic-ui-react';
import ForwardEventModal from './components/forward_event_modal';
import { toastrError } from '@dev/base-web/dist/view/helpers/notification_helpers';
import CommentList from '../../comments/comment_list';
import CommentsModal from '../../comments/comment_modal';
import {
  getLocations,
  getManufacturingEntity,
} from '@/model/domain/event/helper.ts';

const disorderStyle = css`
  content: 'Störung';
  color: #f26b24;
`;

const Type = styled.p<{ disorder?: boolean }>`
  font-family: Relative;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  margin: 5px 0;
  padding: 0;

  ${(disorder) => disorder && disorderStyle};
`;

const H1 = styled.span`
  font-family: Relative;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #242424;
  margin: 0;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 18px;
`;

const StatusMessage = styled.span`
  display: flex;
  flex-direction: row;
  font-family: Relative;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${(props) => props.color};
`;

const ViewAllButtonContainer = styled(CenteredButtons)`
  padding-top: 24px;
`;

const OverflowContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const FeedbackButtonContainer = styled.div`
  padding-left: 15px;
`;

const DropdownItemContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

interface Props {
  readonly removeInstruction?: (id: string) => void;
  readonly openCallback?: () => void;
  readonly eventId: string;
  readonly item: EventDetail | EventDefinition;
  readonly eventDefId: string;
  readonly isLoading: boolean;
  readonly editable?: boolean;
  readonly onInstructionSelect?: (eventId: string, actionId: string) => void;
  readonly onEventNeedsToBeRefreshed?: () => void;
  readonly currentUser: UserDetails | null;
  readonly initial?: boolean; //if this card is used on the wizard, it is made to be the initial one, so some things do not apply.
  readonly closeEvent?: (eventId: string, closeDate?: number) => void;
  readonly configs: ConfigurationParameters | null;
  readonly cleanInstruction: () => void;
}

const EventCard: React.FC<Props> = ({
  currentUser,
  isLoading,
  item,
  editable,
  initial,
  onInstructionSelect,
  openCallback,
  removeInstruction,
  eventDefId,
  eventId,
  // onEventNeedsToBeRefreshed,
  closeEvent,
  configs,
  cleanInstruction,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();

  const [commentsModalOpen, setCommentsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const createNewInstruction = () => {
    cleanInstruction();
    navigate({
      pathname: `/actions/new`,
      search: `?eventId=${eventDefId || eventId}`,
    });
  };

  const closeTask = (closeDate?: number) => {
    if (closeEvent) {
      closeEvent(item.id, closeDate);
    }
  };

  const { title, color, icon } = getEventTypeLookForType(item.type, intl);

  const locations = getLocations(item);
  const eventDefManufacturingEntity = getManufacturingEntity(item);

  const { write: canEditEvent } = useAuthorizationCheckRWD(
    UserPrivilegePermission.EVENT,
    ApplicationCategory.SHANNON
  );

  const { write: canChangeSolutionRelation } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION_CONFIG,
    ApplicationCategory.SHANNON
  );

  const { write: canAddNewSolutions } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION,
    ApplicationCategory.SHANNON
  );

  const eventStatisticsOnClick =
    'manufacturingEntity' in item && item.manufacturingEntity
      ? useCallbackToOpenTabWithPathPrefix(
          'analytics',
          `${DarwinConfig.Downtimes.url.path}/${item.manufacturingEntity.id}/${eventDefId}`,
          [item]
        )
      : undefined;

  if (isLoading) {
    return (
      <Card>
        <DetailLoader />
      </Card>
    );
  }

  const showEventStatsLink = !!eventStatisticsOnClick;
  const showEventDefLink = canEditEvent && !editable;

  return (
    <Card
      titleContent={
        <div style={{ width: '100%' }}>
          <Type>
            <StatusMessage color={color}>
              <Icon
                iconStyle={{ marginRight: 5 }}
                color={color}
                name={icon}
                size={14}
              />
              {title}
            </StatusMessage>
          </Type>
          <OverflowContainer>
            <H1>
              {item.name ? (
                <TextWithEllipsisAndTooltip
                  text={intl.formatMessage({
                    id: item.name,
                    defaultMessage: item.name,
                  })}
                  lines={2}
                />
              ) : (
                '-'
              )}
            </H1>
          </OverflowContainer>
        </div>
      }
      headerContent={
        <>
          {eventId &&
            closeEvent &&
            item.type === EventType.TASK &&
            (item as EventInterface).status === EventStatusType.ACTIVE && (
              <CloseTaskModal
                closeTask={closeTask}
                eventStartTimestamp={(item as EventInterface).timestamp}
              />
            )}
          <FeedbackButtonContainer>
            {(showEventStatsLink || showEventDefLink) && (
              <Button
                type="tertiary"
                noMargin
                icon="more"
                size={'small'}
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <Dropdown
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  icon={
                    <Icon name="more" color={theme.colors.black} size={22} />
                  }
                  style={{ display: 'flex', alignItems: 'center' }}
                  open={dropdownOpen}
                  onClose={() => setDropdownOpen(false)}
                >
                  <Dropdown.Menu direction="left">
                    {showEventStatsLink && (
                      <Dropdown.Item
                        onClick={eventStatisticsOnClick}
                        children={
                          <DropdownItemContent>
                            <StyledIcon
                              name="bars"
                              color={theme.colors.primary}
                              size={16}
                            />
                            <FormattedMessage id={'statistics'} />
                          </DropdownItemContent>
                        }
                      />
                    )}
                    {showEventDefLink && (
                      <Dropdown.Item
                        onClick={() => navigate('/events/' + eventDefId)}
                        children={
                          <DropdownItemContent>
                            <StyledIcon
                              name="edit"
                              color={theme.colors.primary}
                              size={16}
                            />
                            <FormattedMessage id={'edit_event'} />
                          </DropdownItemContent>
                        }
                      />
                    )}
                    {!editable && (
                      <Dropdown.Item
                        onClick={() => {}}
                        children={
                          <ForwardEventModal event={item as EventDetail} />
                        }
                      />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Button>
            )}
          </FeedbackButtonContainer>
        </>
      }
    >
      <EventElements
        eventId={eventId}
        locations={locations}
        event={item}
        initial={initial}
        manufacturingEntity={eventDefManufacturingEntity}
      />
      <div id="solution_list">
        <InstructionListContainer
          event={item}
          onInstructionSelect={(actionId: string) => {
            if (onInstructionSelect) {
              onInstructionSelect(eventId, actionId);
            }
          }}
          notEditable={!editable}
          canAddNew={
            canAddNewSolutions && (!('isInternal' in item) || !item.isInternal)
          }
          canDelete={
            canChangeSolutionRelation &&
            (!('isInternal' in item) || !item.isInternal)
          }
          removeInstruction={removeInstruction}
          openCallback={openCallback}
          createNewInstruction={createNewInstruction}
          instructions={item.actions}
          currentUser={currentUser}
          configs={configs}
        />
      </div>
      {!editable &&
        !isLoading &&
        'subsequentEvents' in item &&
        !item.isSubsequentEvent && (
          <SubsequentEventsList subEvents={item.subsequentEvents} />
        )}
      <CommentList
        initialComments={item.comments}
        eventDefinitionId={eventDefId}
        eventId={eventId}
        deleteDisabled={!canEditEvent}
        toastrError={toastrError}
      />
      <ViewAllButtonContainer>
        <Button
          type="tertiary"
          label="view_all"
          onClick={() => setCommentsModalOpen(true)}
        />
      </ViewAllButtonContainer>
      <CommentsModal
        initialComments={item.comments}
        eventDefinitionId={eventDefId}
        eventId={eventId}
        open={commentsModalOpen}
        onClose={() => setCommentsModalOpen(false)}
        toastrError={toastrError}
      />
    </Card>
  );
};

export default EventCard;
