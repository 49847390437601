import React, { useEffect, useState } from 'react';
import { EditInstructionProps } from './interfaces';
import MediaCacheProvider from '@dev/base-web/dist/view/components/global/media_cache_provider';
import { ErrorScreen } from '@dev/base-web/dist/view/screens/error';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import {
  LoadingMetaState,
  OperationType,
} from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { ScreenRoot } from '@dev/base-web/dist/view/components/global/styled_components';
import { CONFIG as ACTIONS_CONFIG } from '../instructions';
import Header from '@dev/base-web/dist/view/components/global/header_view';
import { useNavigate } from 'react-router';
import InstructionSideBar from './components/sidebar/instruction_side_bar';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import HeaderActions from './components/header_actions';
import {
  addLanguageToSteps,
  addLanguageToTranslationSet,
  getTranslation,
} from '@/model/domain/instruction/helper';
import { checkAuthorizationRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import InstructionContent from './components/editable_content';
import useInstructionEditing from './components/use_instruction_editing';
import { InstructionCard } from '../../components/instruction/instruction_card';
import AddEventsModal from './components/add_events_modal';
import AddDecisionModal from './components/add_decision_modal';
import GlossaryModal from './components/glossary_modal';
import {
  useNotificationForOperation,
  useNotificationForOperationError,
  toastrInfo,
  toastrError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import {
  useBooleanSearchState,
  useNumberSearchState,
  useStringSearchState,
} from '@dev/base-web/dist/view/components/global/url_param_hooks';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import {
  checkAndGetNewerVersion,
  setEventIfListEmpty,
} from '@/screens/edit_instruction/components/helper';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';
import { isUUID } from '@/components/common';
import { suggestionToSteps } from '@/model/domain/event/solution_suggestion';
import NewTranslationModal from './components/new_translation_modal/new_translation_modal';
import SelectTranslationsToUpdateModal from './components/select_translations_to_update_modal';
import InstructionTranslationCardContent from '@/screens/edit_instruction/components/translation_content/instruction_translation_card_content.tsx';
import useInstructionTranslationEditing from '@/screens/edit_instruction/components/use_instruction_translation_editing.ts';
import {
  instructionTranslationToInstruction,
  switchLanguageInStepTranslation,
  switchLanguageInTranslation,
} from '@/model/domain/instruction/translation_helper.ts';
import { ActionDefinitionHistoryDTO } from '@/model/domain/instruction/instruction.ts';
import useStateHandling from '@/screens/edit_instruction/components/use_state_handling.ts';
import Tour from '@/components/tour/Tour.tsx';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.cards.normalWrapper.margin.vertical}px 12px
    24px 24px;
  background-color: #f7f7f7;
  align-items: stretch;
  width: 100%;
`;

type DUPLICATED_INSTRUCTION_STATUS = 'NONE' | 'PENDING' | 'DUPLICATED';

const EditInstruction: React.FC<EditInstructionProps> = ({
  instruction,
  instructionMeta,
  selectedLanguage,
  translateInstruction,
  currentUser,
  setSelectedInstructionLanguage,
  approveInstruction,
  removeCommentFromInstruction,
  getInstruction,
  deleteInstructions,
  addEventsToInstructionMeta,
  createInstruction,
  updateInstruction,
  instructionUpdateMeta,
  instructionVersions,
  getInstructionHistory,
  instructionDeleteMeta,
  duplicateInstruction,
  locale,
  getEvent,
  getInstructionSuggestion,
  eventDefinition,
  suggestedInstruction,
  config,
  translationMeta,
  translatedInstruction,
  approvalMeta,
  getLabels,
}: EditInstructionProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const intl = useIntl();

  const id = params.id as string;

  const {
    localInstruction,
    setLocalInstruction,
    currentStep,
    setCurrentStep,
    selectedDecisions,
    temporaryAddDecision,
    modifyStep,
    changeStepOrder,
    changeValues,
    onAddFirstEmptyStep,
    onAddEmptyStep,
    copyStep,
    pasteStep,
    onEventsAdded,
    onSelectDecision,
    onAddDecisions,
    isSavable,
    lastEditedLanguage,
    hasMultipleLanguages,
    areAnyTranslationsChanged,
    setLastEditedLanguage,
    setIsRevertActive,
  } = useInstructionEditing(
    instruction,
    selectedLanguage,
    setSelectedInstructionLanguage,
    locale,
    instructionUpdateMeta,
    id
  );

  const {
    localInstructionTranslation,
    changeTitleTranslations,
    modifyStepTranslations,
    isTranslationSavable,
    verifiedLanguages,
    setVerifiedLanguages,
    selectedTranslationsToVerify,
    setSelectedTranslationsToVerify,
  } = useInstructionTranslationEditing(translatedInstruction);

  const { isApproved } = useStateHandling(
    instruction,
    instructionVersions,
    instructionUpdateMeta
  );

  const [readModeParam, setReadMode] = useBooleanSearchState({
    key: 'readMode',
    initialValue: id !== 'new',
    defaultValue: true,
  });

  const [eventId, setEventId] = useStringSearchState({
    key: 'eventId',
  });

  const [revision, setRevision] = useNumberSearchState({
    key: 'revision',
  });

  const [suggestMode, setSuggestMode] = useBooleanSearchState({
    key: 'suggest',
    initialValue: false,
    defaultValue: false,
  });

  const readMode = !!readModeParam;

  const [
    showTranslationUpdateSelectionModal,
    setShowTranslationUpdateSelectionModal,
  ] = useState(false);
  const [showTranslationVerification, setShowTranslationVerification] =
    useState<boolean>(false);
  const [showAddDecisionModal, setShowAddDecisionModal] =
    useState<boolean>(false);
  const [showNewTranslationModal, setShowNewTranslationModal] =
    useState<boolean>(false);
  const [newTranslationLanguage, setNewTranslationLanguage] =
    useState<string>();
  const [showAddEventsModal, setShowAddEventsModal] = useState<boolean>(false);
  const [showGlossaryModal, setShowGlossaryModal] = useState<boolean>(false);
  const [duplicatedInstructionStatus, setDuplicatedInstructionStatus] =
    useState<DUPLICATED_INSTRUCTION_STATUS>('NONE');
  const [duplicatedId, setDuplicatedId] = useState<string | undefined>();
  const [noAutoTranslate, setNoAutoTranslate] = useState(false);

  const { write } = checkAuthorizationRWD(
    UserPrivilegePermission.ACTION,
    ApplicationCategory.SHANNON,
    currentUser
  );
  const { write: configWrite } = checkAuthorizationRWD(
    UserPrivilegePermission.ACTION_CONFIG,
    ApplicationCategory.SHANNON,
    currentUser
  );

  useEffect(() => {
    getLabels();
  }, []);

  useEffect(() => {
    if (
      id !== 'new' &&
      !instructionMeta.loadingInProgress &&
      (!localInstruction ||
        id !== localInstruction.id ||
        (revision && revision !== instruction?.revisionNumber))
    ) {
      getInstruction(id, revision);
      getInstructionHistory(id);
    }

    if (id === 'new' && readMode) setReadMode(false);
  }, [id, revision]);

  //TODO: prevent double load
  useEffect(() => {
    if (instruction) getInstructionHistory(instruction.id);

    if (
      instruction &&
      ((localInstruction?.id && instruction.id !== localInstruction.id) ||
        id === 'new')
    ) {
      navigate(`/actions/${instruction.id}`, { replace: true });
    }

    if (
      instruction &&
      instruction.revisionNumber !== localInstruction?.revisionNumber &&
      instruction.revisionNumber !== revision
    )
      setRevision(instruction.revisionNumber);
  }, [instruction]);

  useEffect(() => {
    if (
      eventId &&
      isUUID(eventId) &&
      id === 'new' &&
      localInstruction &&
      !localInstruction.events.length
    ) {
      if (eventDefinition?.id !== eventId) getEvent(eventId);

      const instructionWithEvent = setEventIfListEmpty(
        eventId,
        eventDefinition,
        localInstruction
      );
      if (instructionWithEvent) {
        setLocalInstruction(instructionWithEvent);
      }
    }
  }, [eventId, localInstruction]);

  useEffect(() => {
    const instructionWithEvent = setEventIfListEmpty(
      eventId,
      eventDefinition,
      localInstruction
    );
    if (instructionWithEvent) {
      setLocalInstruction(instructionWithEvent);
    }
  }, [eventDefinition]);

  useEffect(() => {
    if (eventId && suggestMode) getInstructionSuggestion(eventId, true);
  }, [eventId, suggestMode]);

  useEffect(() => {
    if (
      suggestMode &&
      id === 'new' &&
      localInstruction &&
      suggestedInstruction &&
      selectedLanguage
    )
      setLocalInstruction({
        ...localInstruction,
        translations: [
          {
            language: selectedLanguage,
            translation: intl.formatMessage({ id: 'suggested_solution' }),
          },
        ],
        steps: suggestionToSteps(suggestedInstruction, selectedLanguage),
      });
  }, [suggestedInstruction]);

  //TODO state of current revision?
  const previousReadMode = usePrevious(readMode);
  useEffect(() => {
    if (
      previousReadMode !== readMode &&
      !readMode &&
      instruction?.revisionNumber
    ) {
      const id = checkAndGetNewerVersion(
        instruction.revisionNumber,
        instruction?.pendingUpdates || [],
        instructionVersions.data
      );
      if (id) {
        getInstruction(id);
        toastrInfo(
          intl.formatMessage({ id: 'switching_to_newest_solution_version' })
        );
      }
    }
  }, [readMode, instruction, instructionVersions]);

  useNotificationForOperation(
    instructionUpdateMeta,
    'solution_saved',
    'solution_saved',
    'resource_deleted',
    intl,
    () => {
      if (duplicatedInstructionStatus === 'PENDING')
        setDuplicatedInstructionStatus('DUPLICATED');
      else if (duplicatedInstructionStatus === 'DUPLICATED') {
        setDuplicatedInstructionStatus('NONE');
        setDuplicatedId(undefined);
      }
      resetActionStateFields();
    }
  );

  const resetActionStateFields = () => {
    setNoAutoTranslate(false);
    setVerifiedLanguages([]);
    setSelectedTranslationsToVerify([]);
    setShowTranslationVerification(false);
    setEventId(undefined);
    setSuggestMode(false);
    setIsRevertActive(false);
  };

  useNotificationForOperation(
    instructionDeleteMeta,
    'solution_saved',
    'solution_saved',
    'resource_deleted',
    intl,
    (operation) => {
      if (operation === OperationType.DELETE) {
        if (duplicatedInstructionStatus === 'DUPLICATED' && duplicatedId) {
          getInstruction(duplicatedId);
          setDuplicatedInstructionStatus('NONE');
          setDuplicatedId(undefined);
        } else navigate(ACTIONS_CONFIG.url.path, { replace: true });
      }
    }
  );

  useNotificationForOperation(
    addEventsToInstructionMeta,
    intl.formatMessage({ id: 'event_add_success' }),
    intl.formatMessage({ id: 'event_add_success' }),
    intl.formatMessage({ id: 'event_removed_success' }),
    intl
  );

  useNotificationForOperationError(
    addEventsToInstructionMeta,
    intl,
    intl.formatMessage({ id: 'event_add_error' })
  );

  const prevTranslationMeta = usePrevious(translationMeta);
  useEffect(() => {
    if (
      !translationMeta.loadingInProgress &&
      prevTranslationMeta?.loadingInProgress &&
      !translationMeta.error
    ) {
      setShowTranslationUpdateSelectionModal(false);
      setShowTranslationVerification(true);
      setShowNewTranslationModal(false);
    } else if (translationMeta.error)
      toastrError(intl.formatMessage({ id: translationMeta.error.message }));
  }, [translationMeta]);

  const onLanguageChanged = (lang: string) => {
    if (
      !selectedLanguage ||
      lang.toLowerCase() !== selectedLanguage?.toLowerCase()
    ) {
      if (
        localInstruction &&
        !localInstruction.availableTranslations?.includes(lang.toUpperCase())
      ) {
        setSelectedTranslationsToVerify([]);
        // if it's a new instruction, we do not add a new language, but replacing an existing one
        setLocalInstruction({
          ...localInstruction,
          availableTranslations: [
            ...(!isNew && localInstruction.availableTranslations
              ? localInstruction.availableTranslations
              : []),
            ...[lang.toUpperCase()],
          ],
          translations: isNew
            ? switchLanguageInTranslation(localInstruction.translations, lang)
            : addLanguageToTranslationSet(localInstruction.translations, lang),
          steps: isNew
            ? localInstruction.steps.map((s) =>
                switchLanguageInStepTranslation(s, lang)
              )
            : addLanguageToSteps(localInstruction.steps, lang),
        });
      }

      if (!isNew) {
        setSelectedInstructionLanguage(lang);
      }
    }
  };

  useEffect(() => {
    if (
      isNew &&
      localInstruction &&
      selectedLanguage &&
      localInstruction.availableTranslations?.length &&
      !localInstruction.availableTranslations.includes(selectedLanguage)
    ) {
      setSelectedInstructionLanguage(localInstruction.availableTranslations[0]);
    }
  }, [localInstruction]);

  const onRemoveEvents = (eventId: string) => {
    if (localInstruction)
      setLocalInstruction({
        ...localInstruction,
        events: localInstruction?.events.filter((e) => e.id !== eventId) || [],
      });
  };

  const onApproveVersion = (approved: boolean) => {
    if (localInstruction?.revisionNumber && localInstruction?.id) {
      approveInstruction(
        localInstruction.id,
        localInstruction.revisionNumber,
        approved,
        selectedLanguage || undefined
      );
    }
  };

  const onDuplicateInstructionClicked = () => {
    duplicateInstruction(id, selectedLanguage);
    setDuplicatedInstructionStatus('PENDING');
    setDuplicatedId(id);
  };

  const onNewLanguageTranslateClicked = (language: string) => {
    const lang = language.toUpperCase();
    if (localInstruction && localInstruction.id && instruction?.language) {
      setSelectedTranslationsToVerify([lang]);
      if (instruction.language) setVerifiedLanguages([instruction.language]);
      setLastEditedLanguage(lang);
      translateInstruction(
        localInstruction.id,
        localInstruction,
        instruction.language,
        [lang]
      );
    }
  };

  const onDeleteClicked = () => {
    if (localInstruction?.id) deleteInstructions([localInstruction.id]);
  };

  const onSaveClicked = () => {
    if (localInstruction) {
      if (id !== 'new') {
        if (showTranslationVerification && localInstructionTranslation) {
          updateInstruction(
            id,
            instructionTranslationToInstruction(localInstructionTranslation),
            lastEditedLanguage
          );
        } else if (
          hasMultipleLanguages &&
          areAnyTranslationsChanged &&
          !noAutoTranslate
        )
          setShowTranslationUpdateSelectionModal(true);
        else onUpdateTriggered();
      } else createInstruction(localInstruction, selectedLanguage || undefined);
    }
  };

  const onUpdateTriggered = () => {
    if (localInstruction && id !== 'new')
      updateInstruction(id, localInstruction, selectedLanguage);
  };

  const onRevisionSelected = (revision: ActionDefinitionHistoryDTO) => {
    setRevision(revision.revisionNumber);
    getInstruction(revision.id, revision.revisionNumber);

    if (!revision.isActive) setIsRevertActive(true);
    else setIsRevertActive(false);
  };

  const onTranslateLanguageChanges = (language: string) => {
    setLastEditedLanguage(language);
    if (!verifiedLanguages.includes(language)) {
      const newVerifiedLanguages = [language];

      if (lastEditedLanguage && !verifiedLanguages.includes(lastEditedLanguage))
        newVerifiedLanguages.push(lastEditedLanguage);

      setVerifiedLanguages([...verifiedLanguages, ...newVerifiedLanguages]);
    }
  };

  const isNew = id === 'new';
  const initialLoading =
    !isNew && instructionMeta.loadingInProgress && localInstruction?.id !== id;

  const title = initialLoading
    ? 'solution'
    : id !== 'new' && localInstruction
    ? getTranslation(
        localInstruction.translations,
        '',
        selectedLanguage || undefined
      )
    : 'add_new_solution';

  return (
    <MediaCacheProvider>
      {instructionMeta.error ? (
        <InstructionErrorScreen meta={instructionMeta} />
      ) : (
        <ScreenRoot>
          <Header
            title={title || '-'}
            backButtonTitle="category_action_config"
            onBackPressed={() =>
              navigate(ACTIONS_CONFIG.url.path, { replace: true })
            }
          >
            <HeaderActions
              instructionId={localInstruction?.id}
              readMode={readMode}
              setReadMode={setReadMode}
              currentUser={currentUser || undefined}
              isSavable={
                !showTranslationVerification ? isSavable : isTranslationSavable
              }
              isNewInstruction={isNew}
              duplicateInstruction={onDuplicateInstructionClicked}
              instructionUpdateMeta={instructionUpdateMeta}
              instructionDeleteMeta={instructionDeleteMeta}
              approvalMeta={approvalMeta}
              onSaveClicked={onSaveClicked}
              onDeleteClicked={onDeleteClicked}
              isDuplicatedInstruction={
                duplicatedInstructionStatus === 'DUPLICATED'
              }
              isApproved={isApproved}
            />
            <Tour
              page="solution"
              solutionSteps={
                localInstruction ? [...localInstruction.steps] : []
              }
            />
          </Header>
          <Wrapper>
            {initialLoading ? (
              <Loader />
            ) : (
              <>
                <InstructionSideBar
                  isNew={isNew}
                  readMode={readMode}
                  showTranslationVerification={showTranslationVerification}
                  languagesToVerify={selectedTranslationsToVerify}
                  verifiedLanguages={verifiedLanguages}
                  onTranslateLanguageChanged={onTranslateLanguageChanges}
                  currentUser={currentUser}
                  versionApproved={isApproved}
                  approveVersion={onApproveVersion}
                  item={localInstruction}
                  onRevisionSelected={onRevisionSelected}
                  revisionNumber={revision}
                  isLoading={instructionMeta.loadingInProgress}
                  onRemoveEvents={onRemoveEvents}
                  showAddEventsModal={setShowAddEventsModal}
                  selectedLanguage={selectedLanguage}
                  onLanguageChanged={onLanguageChanged}
                  onNewLanguageTranslated={(lang) => {
                    setShowNewTranslationModal(true);
                    setNewTranslationLanguage(lang);
                  }}
                  deleteComment={(commentId) => {
                    if (localInstruction?.id)
                      removeCommentFromInstruction(
                        commentId,
                        localInstruction.id
                      );
                  }}
                  onGlossaryClicked={() => setShowGlossaryModal(true)}
                  versions={instructionVersions}
                />
                {readMode && localInstruction && (
                  <div style={{ paddingLeft: 18, width: '100%' }}>
                    <InstructionCard
                      item={{
                        id: localInstruction.id || '',
                        name: intl.formatMessage({ id: 'instructions' }),
                        createdTimestamp:
                          localInstruction?.createdTimestamp || 0,
                        steps: localInstruction.steps || [],
                        voteRatio: 0,
                        isPositiveVote: false,
                        feedbackText: '',
                        labels: localInstruction.labels,
                      }}
                      accessLevel={localInstruction.accessLevel}
                      isHidden={localInstruction.isHidden}
                      selectedLanguage={selectedLanguage || undefined}
                    />
                  </div>
                )}
                {!readMode &&
                  !showTranslationVerification &&
                  localInstruction && (
                    <>
                      <InstructionContent
                        key={`${localInstruction.id}_${localInstruction.revisionNumber}`}
                        item={localInstruction}
                        onEditDecision={(step) => {
                          setCurrentStep(step);
                          setShowAddDecisionModal(true);
                        }}
                        selectedDecisions={selectedDecisions}
                        onAddDecision={temporaryAddDecision}
                        onSelect={onSelectDecision}
                        onCopyStep={copyStep}
                        onPasteStep={pasteStep}
                        onAddEmptyStep={onAddEmptyStep}
                        onChange={changeValues}
                        onChangeStep={modifyStep}
                        onChangeOrder={changeStepOrder}
                        onAddFirstEmptyStep={onAddFirstEmptyStep}
                        allowedToModify={write}
                        allowedToModifyConfig={configWrite}
                        selectedLanguage={selectedLanguage || undefined}
                      />
                      <AddDecisionModal
                        open={showAddDecisionModal}
                        onClose={() => setShowAddDecisionModal(false)}
                        addDecisionsCallback={onAddDecisions}
                        step={currentStep}
                      />
                      <AddEventsModal
                        instructionId={localInstruction.id}
                        modalVisible={showAddEventsModal}
                        onCancelPressed={() => setShowAddEventsModal(false)}
                        onEventsAdded={onEventsAdded}
                        existingEvents={localInstruction?.events || []}
                      />
                      <GlossaryModal
                        modalVisible={showGlossaryModal}
                        onCancelPressed={() => setShowGlossaryModal(false)}
                        selectedLanguage={selectedLanguage || 'EN'}
                        availableTranslationLanguages={
                          localInstruction.availableTranslations || []
                        }
                      />
                      <NewTranslationModal
                        open={showNewTranslationModal}
                        fromLanguageCode={instruction?.language ?? null}
                        toLanguageCode={newTranslationLanguage}
                        onClose={() => {
                          setShowNewTranslationModal(false);
                          if (newTranslationLanguage) {
                            setNoAutoTranslate(true);
                            onLanguageChanged(newTranslationLanguage);
                          }
                        }}
                        onOk={() => {
                          if (newTranslationLanguage)
                            onNewLanguageTranslateClicked(
                              newTranslationLanguage
                            );
                        }}
                        config={config}
                        currentLocale={locale}
                        translationMeta={translationMeta}
                      />
                      <SelectTranslationsToUpdateModal
                        instruction={localInstruction}
                        open={showTranslationUpdateSelectionModal}
                        onClose={() => {
                          setShowTranslationUpdateSelectionModal(false);
                          //setShowTranslationVerification(false);
                          setSelectedTranslationsToVerify([]);
                          onUpdateTriggered();
                        }}
                        onOk={(languageCodes) => {
                          //TODO: move to method
                          const notVerifiedLanguages = languageCodes.filter(
                            (language) => !verifiedLanguages.includes(language)
                          );

                          setSelectedTranslationsToVerify(languageCodes);
                          setVerifiedLanguages([]);
                          setLastEditedLanguage(notVerifiedLanguages[0]);

                          if (
                            localInstruction &&
                            notVerifiedLanguages.length &&
                            localInstruction.id &&
                            selectedLanguage
                          ) {
                            translateInstruction(
                              localInstruction.id,
                              localInstruction,
                              selectedLanguage,
                              notVerifiedLanguages
                            );
                          }
                        }}
                        config={config}
                        currentLocale={locale}
                        selectedLanguage={selectedLanguage || undefined}
                        translationMeta={translationMeta}
                      />
                    </>
                  )}
                {showTranslationVerification &&
                  localInstructionTranslation &&
                  lastEditedLanguage && (
                    <InstructionTranslationCardContent
                      translateItem={localInstructionTranslation}
                      selectedLanguage={lastEditedLanguage}
                      onEditDecision={(step) => {
                        setCurrentStep(step);
                        setShowAddDecisionModal(true);
                      }}
                      selectedDecisions={selectedDecisions}
                      onSelect={onSelectDecision}
                      onChangeTitle={changeTitleTranslations}
                      onChangeStep={modifyStepTranslations}
                      allowedToModify={write}
                    />
                  )}
              </>
            )}
          </Wrapper>
        </ScreenRoot>
      )}
    </MediaCacheProvider>
  );
};

const InstructionErrorScreen = (props: { meta: LoadingMetaState }) => {
  const { meta } = props;

  return meta.error ? (
    <ErrorScreen
      title={
        meta.error?.title === 'OBJECT_NOT_FOUND'
          ? 'not_found_title'
          : 'general_error_title'
      }
      description={
        meta.error?.title === 'OBJECT_NOT_FOUND'
          ? 'solution_not_found'
          : 'generic_error_desc'
      }
      icon={<Icon name="hand" size={64} />}
    />
  ) : (
    <></>
  );
};

export default EditInstruction;
