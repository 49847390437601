import {
  Card,
  CardColumn,
  CardRow,
} from '@dev/base-web/dist/view/components/global/card';
import EventsList from './events_list';
import EventSearchList from './event_search_list';
import InstructionList from './instructions_list';
import ActionsSearchList from './instructions_search_list';
import DashBoardGrid from '@dev/base-web/dist/view/components/dashboard/dashboard_grid';
import { DashboardButtonProps } from '@dev/base-web/dist/view/components/dashboard/dashboard_button';
import React, { useEffect, useState } from 'react';
import { NotificationModal } from '@dev/base-web/dist/view/components/modal/modal';
import styled from 'styled-components';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { useDebouncedCallback } from 'use-debounce';
import SearchInput from './search_input';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

const RecentEventAndActionsModal = styled(NotificationModal)`
  &&&& {
    top: 72px;
    border-radius: 8px;
    padding-bottom: 24px;
  }
`;

const SearchInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  padding-top: 24px;
`;

const ModalContentContainer = styled.div`
  max-height: 70vh;
  overflow: auto;
`;

const ModalSearchInputContainer = styled(SearchInputContainer)`
  position: absolute;
  top: -84px;
  left: 0;
`;

const TableTitleContainer = styled.div`
  margin-bottom: 12px;
  margin-top: 24px;
  font-family: Relative;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.black};
`;

interface SupportDashboardProps {
  readonly dashboardItems: DashboardButtonProps[];
}

const SupportDashboard = ({ dashboardItems }: SupportDashboardProps) => {
  const [searchText, setSearchText] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [externalFilters, setExternalFilters] = useState<FilterData[]>([]);

  const debouncedOnSearchTextChanged = useDebouncedCallback((value: string) => {
    const filters = [
      new FilterData('global', FilterOperation.CT_OPERATOR, value),
    ];
    setExternalFilters(filters);
  }, 700);

  const onModelClose = () => {
    setModalOpen(false);
    setSearchText('');
  };

  useEffect(() => {
    if (!modalOpen && searchText) {
      setModalOpen(true);
    } else if (modalOpen && _.isEmpty(searchText)) {
      onModelClose();
    }

    if (searchText && !_.isEmpty(searchText)) {
      debouncedOnSearchTextChanged(searchText);
    }
  }, [searchText]);

  return (
    <>
      <CardRow>
        <SearchInputContainer>
          <SearchInput searchText={searchText} setSearchText={setSearchText} />
        </SearchInputContainer>
      </CardRow>
      <CardRow>
        <CardColumn>
          <Card titleId={'recent_used_events'} margin={'none'}>
            <EventsList minHeight={400} card />
          </Card>
        </CardColumn>
        <CardColumn>
          <Card titleId={'newest_actions'} margin={'none'}>
            <InstructionList minHeight={400} card />
          </Card>
        </CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <DashBoardGrid buttons={dashboardItems} />
        </CardColumn>
      </CardRow>
      <RecentEventAndActionsModal
        contentBackgroundColor={'transparent'}
        modalSize={'fullscreen'}
        open={modalOpen}
        showCloseIcon
        onClose={onModelClose}
      >
        <>
          <ModalSearchInputContainer onClick={onModelClose}>
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </ModalSearchInputContainer>
          <ModalContentContainer>
            <TableTitleContainer>
              <FormattedMessage id={'instructions'} />
            </TableTitleContainer>
            <ActionsSearchList externalFilters={externalFilters} card />
            <TableTitleContainer>
              <FormattedMessage id={'events'} />
            </TableTitleContainer>
            <EventSearchList externalFilters={externalFilters} card />
          </ModalContentContainer>
        </>
      </RecentEventAndActionsModal>
    </>
  );
};

export default SupportDashboard;
