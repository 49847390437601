import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import {
  Card,
  StyledContainer,
} from '@dev/base-web/dist/view/components/global/styled_components';
import { useStringSearchState } from '@dev/base-web/dist/view/components/global/url_param_hooks';
import { NEW_USER_ROUTE_KEY, UserPropertiesProps } from './interfaces';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import GeneralSettingsCard from './components/general_settings_card';
import TasksCard from './components/tasks_card';
import { useNotificationForOperation } from '@dev/base-web/dist/view/helpers/notification_helpers';
import UserImageView from './components/UserImageView';
import UserDetails from './components/UserDetails';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import ActionRowSimplified from './components/ActionRowSimplified';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import UserUpdateDTO from '@dev/base-web/dist/model/domain/user/user_update_dto';
import UserStatus from '@dev/base-web/dist/model/domain/user/user_status';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { CONFIG as USER_ADMIN_CONFIG } from '../user_list/interface';
import { HeaderButtons } from '../../components/StyledComponents';
import EventsCard from './components/events_card';
import { EventDefinitionStatus } from '@/model/domain/event/event_definition.ts';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { emailValidator } from '@dev/base-web/dist/model/helpers/validators';
import { InstructionOverview } from '@/model/domain/instruction/instruction.ts';
import NotificationsCard from './components/notifications/notifications_card';
import { FlexibleCardColumn } from '@dev/base-web/dist/view/components/global/card';
import { usePrompt } from '@dev/base-web/dist/view/helpers/navigation_helpers';
import _ from 'lodash';
import Alert, {
  AlertSeverity,
} from '@dev/base-web/dist/view/components/global/alert';
import { checkAuthorizationRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import Header from '@dev/base-web/dist/view/components/global/header_view';
import { ROUTE_ID_TEMPLATE } from '.';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 32px;
`;

const CardContainer = styled.div`
  margin-bottom: 22px;
`;

const UserDetailsContainer = styled(RowContainer)`
  & {
    align-items: center;
  }
`;

const StyledCard = styled(Card)`
  margin-right: 24px;
  margin-left: 12px;
  margin-top: 36px;
`;

const StyledTabbarContainer = styled.div`
  margin-left: 36px;
`;

const columns: Array<Column> = [
  {
    title: 'name',
    isLeftAligned: true,
    sortKey: 'name',
    filterType: ColumnFilterType.TEXT,
    width: 5,
  },
  {
    title: 'col_rating',
    isLeftAligned: true,
    sortKey: 'votes',
    filterType: ColumnFilterType.NUMBER,
    width: 3,
  },
  {
    title: 'col_access_count',
    isLeftAligned: true,
    sortKey: 'accessCount',
    filterType: ColumnFilterType.NUMBER,
    width: 2,
  },
  {
    title: 'col_created_time',
    sortKey: 'createdTimestamp',
    isLeftAligned: true,
    filterType: ColumnFilterType.DATE,
    width: 2,
  },
];

const USER_INFO_TAB_KEY = 'properties';
const USER_SOLUTION_TAB_KEY = 'profile_user_solution_info';
const SUBSCRIBED_EVENTS_TAB_KEY = 'subscribed_events';
const tabs: string[] = [
  USER_INFO_TAB_KEY,
  USER_SOLUTION_TAB_KEY,
  SUBSCRIBED_EVENTS_TAB_KEY,
];

const UserProperties = ({
  user,
  currentUser,
  instructionLoadingInProgress,
  moreInstructionsCanBeLoaded,
  userLoadingInProgress,
  updateUserOperation,
  pictureUploadResult,
  pictureUploadInProgress,
  manufacturingEntities,
  events,
  eventsLoadingInProgress,
  moreEventsCanBeLoaded,
  roleNames,
  eventOccurrenceCountDuration,
  instructions,
  usernameExists,
  getUserById,
  getUsernameExists,
  getAllManufacturingEntities,
  getAllRoleNames,
  getEvents,
  getInstructions,
  createUser,
  updateUser,
  deleteUsers,
  deleteUserOperation,
  uploadPicture,
  minimumPasswordStrength,
  manufacturingEntitiesMeta,
}: UserPropertiesProps) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [selectedTab, setSelectedTab] = useStringSearchState({
    key: 'tab',
    defaultValue: USER_INFO_TAB_KEY,
    onChange: (value) => {
      if (value === USER_SOLUTION_TAB_KEY) {
        getInstructions(true, false, 0, userFilter);
      } else if (value === SUBSCRIBED_EVENTS_TAB_KEY) {
        getEventsHelper(EventDefinitionStatus.ACTIVE, 0);
      }
    },
  });
  const [userData, setUserData] = useState<UserUpdateDTO>({
    status: UserStatus.ACTIVE,
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [updatedUserData, setUpdatedUserData] = useState<UserUpdateDTO>({});
  const [passwordValid, setPasswordValid] = useState(true);
  const debouncedGetUsernameExists = useDebouncedCallback(
    getUsernameExists,
    500
  );

  const isUpdatedUserDataEmpty = !_.values(updatedUserData).some(
    (x) => x !== undefined
  );

  const isUsernameTaken = useMemo(
    () =>
      !!usernameExists &&
      usernameExists.exists &&
      usernameExists.value !== user?.username,
    [usernameExists, user]
  );

  const { write: canWrite, delete: canDelete } = checkAuthorizationRWD(
    UserPrivilegePermission.USER,
    ApplicationCategory.COMMON,
    currentUser
  );

  const { write: selfWrite } = checkAuthorizationRWD(
    UserPrivilegePermission.USER_SELF_CONFIG,
    ApplicationCategory.COMMON,
    currentUser
  );

  const userId = params.userId;
  const isUserNew = userId === NEW_USER_ROUTE_KEY;

  const canSelfEdit =
    selfWrite && user && currentUser && currentUser.id === user.id;

  const userFilter =
    (user && [
      {
        key: 'userId',
        operation: FilterOperation.EQ_OPERATOR,
        value: user.id,
      },
    ]) ||
    [];

  const userDataValid = useMemo(() => {
    const { manufacturingEntities, role, status, username, password, email } =
      userData;

    return (
      manufacturingEntities !== undefined &&
      manufacturingEntities.length &&
      role !== undefined &&
      status !== undefined &&
      username &&
      !(isUserNew && !password) &&
      (passwordValid ?? true) &&
      !isUsernameTaken &&
      (!email || (email && emailValidator(email)))
    );
  }, [userData, passwordValid, isUserNew, isUsernameTaken]);

  //TODO is this really firing when there are unsaved changes?
  usePrompt((when: { nextLocation: { pathname: string } }) => {
    const { pathname: currentPathname } = location;

    // We don't want to show the prompt if a new user is created or one has been
    // deleted.
    //
    // When deleting a user, the prompt was shown after the deletion was processed.
    const isOnCreatePage = currentPathname.endsWith('new');
    const skipPrompt =
      isUpdatedUserDataEmpty ||
      (isOnCreatePage &&
        updateUserOperation.operation === OperationType.CREATE) ||
      (isDeleting && deleteUserOperation.operation === OperationType.DELETE);
    if (skipPrompt) return false;

    return !when.nextLocation.pathname.endsWith(currentPathname);
  }, intl.formatMessage({ id: 'unsaved_changes_prompt' }));

  const loadUserData = () => {
    if (userId && userId !== NEW_USER_ROUTE_KEY) {
      getUserById(userId);
    }
  };

  const getEventsHelper = (status: EventDefinitionStatus, page: number) => {
    if (userId) {
      getEvents(userId, status, page);
    }
  };

  const updateUserData = (newUserData: UserUpdateDTO) => {
    const fullUserData = { ...userData, ...newUserData };
    let onlyUpdatedUserData: any = {};
    if (user) {
      Object.entries(newUserData).forEach(([key, value]) => {
        const k = key as keyof typeof user;
        if (value !== user[k]) onlyUpdatedUserData[k] = value;
      });

      onlyUpdatedUserData = { ...updatedUserData, ...onlyUpdatedUserData };
    } else onlyUpdatedUserData = { ...updatedUserData, ...newUserData };

    if (!fullUserData.password) {
      delete fullUserData.password;
    }

    setUserData(fullUserData);
    setUpdatedUserData(onlyUpdatedUserData);
  };

  const onSavePressed = () => {
    if (userId) {
      if (userId !== NEW_USER_ROUTE_KEY) {
        updateUser(userId, { ...userData, password: undefined });
      } else {
        createUser(userData);
      }
      setUpdatedUserData({});
    }
  };

  const onCancelPressed = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAllManufacturingEntities();
    getAllRoleNames();
  }, []);

  useEffect(() => {
    updateUserData({}); // make sure that username is validated again
  }, [isUsernameTaken]);

  useEffect(() => {
    loadUserData();
  }, [userId]);

  useEffect(() => {
    if (
      !updateUserOperation.operationInProgress &&
      !updateUserOperation.error &&
      user
    ) {
      if (updateUserOperation.operation === OperationType.UPDATE) {
        loadUserData();
      } else if (updateUserOperation.operation === OperationType.CREATE) {
        navigate(ROUTE_ID_TEMPLATE(user.id), { replace: true });
      }
    }
  }, [updateUserOperation, user?.id]);

  useEffect(() => {
    if (user) {
      setUserData({
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        manufacturingEntities: user.manufacturingEntities,
        role: user.role,
        status: user.status,
        pictures: user.pictures,
        subscriptionTopics: user.subscriptionTopics,
        assignedEventTypes: user.assignedEventTypes,
        email: user.email,
      });
    }
  }, [user]);

  useNotificationForOperation(
    updateUserOperation,
    'user_created',
    'user_updated',
    'user_deleted',
    intl
  );

  useNotificationForOperation(
    deleteUserOperation,
    'user_created',
    'user_updated',
    'user_deleted',
    intl,
    () => navigate(USER_ADMIN_CONFIG.url.path, { replace: true })
  );

  const onUserDelete = () => {
    if (userId) {
      setIsDeleting(true);
      deleteUsers([userId]);
    }
  };

  let userTabs = tabs;
  if (isUserNew || !(canWrite || canSelfEdit)) {
    // only keep the user info in case of new user
    userTabs = [tabs[0]];
  }

  const minimumPasswordStrengthValue =
    minimumPasswordStrength && minimumPasswordStrength.value
      ? minimumPasswordStrength.value
      : 3;

  return (
    <>
      <StyledContainer>
        <Header
          title={intl.formatMessage(
            { id: isUserNew ? 'new_user' : 'user_properties' },
            { user: userData.username }
          )}
          backButtonTitle={USER_ADMIN_CONFIG.label}
          onBackPressed={() =>
            navigate(USER_ADMIN_CONFIG.url.path, { replace: true })
          }
        >
          <HeaderButtons>
            <Button
              type="secondary"
              icon="cross"
              label={'cancel_button'}
              onClick={onCancelPressed}
            />
            <Button
              type="primary"
              label={'save'}
              disabled={!userDataValid || isUpdatedUserDataEmpty}
              onClick={onSavePressed}
            />
          </HeaderButtons>
        </Header>
        <UserDetailsContainer>
          <UserImageView
            user={userData}
            pictureUploadResult={pictureUploadResult}
            pictureUploadInProgress={pictureUploadInProgress}
            uploadPicture={uploadPicture}
            updateUserData={updateUserData}
            canEdit={canSelfEdit || canWrite}
          />
          <UserDetails user={user} />
        </UserDetailsContainer>
        <StyledTabbarContainer>
          <TabBar
            titles={userTabs.map((tab) => (
              <FormattedMessage id={tab} />
            ))}
            onSelectedIndexChange={(index) => {
              setSelectedTab(tabs[index]);
            }}
          />
        </StyledTabbarContainer>
        {userData.status && userData.status === UserStatus.INACTIVE && (
          <div style={{ marginLeft: 18, marginTop: 24 }}>
            <div style={{ display: 'inline-block' }}>
              <Alert
                severity={AlertSeverity.WARNING}
                textId="user_inactive"
                textValues={{}}
              />
            </div>
          </div>
        )}
        {selectedTab === USER_INFO_TAB_KEY && (
          <RowContainer>
            <FlexibleCardColumn>
              <GeneralSettingsCard
                isUserEditable={canSelfEdit || canWrite}
                isAdUser={!!user?.isAdUser}
                isUsernameTaken={isUsernameTaken}
                canDelete={canDelete}
                userData={userData}
                updateUserData={updateUserData}
                updatePasswordValidity={setPasswordValid}
                getUsernameExists={debouncedGetUsernameExists}
                isUserNew={isUserNew}
                minimumPasswordStrength={minimumPasswordStrengthValue}
                deleteUser={onUserDelete}
                userId={userId}
              />
            </FlexibleCardColumn>
            <FlexibleCardColumn>
              <CardContainer>
                <TasksCard
                  isUserEditable={canSelfEdit || canWrite}
                  canChangeRole={canWrite}
                  roleNames={roleNames}
                  userData={userData}
                  manufacturingEntities={manufacturingEntities}
                  updateUserData={updateUserData}
                />
              </CardContainer>
              <NotificationsCard
                isUserEditable={canSelfEdit || canWrite}
                userData={userData}
                updateUserData={updateUserData}
              />
            </FlexibleCardColumn>
          </RowContainer>
        )}
        {selectedTab === USER_SOLUTION_TAB_KEY && (
          <StyledCard>
            <SortableTable
              dataLength={instructions.length}
              hasMoreResults={moreInstructionsCanBeLoaded}
              columns={columns}
              getNextResults={(
                page: number,
                filters: readonly FilterData[],
                sortKey?: string,
                sortDirection?: SortingDirection
              ) => {
                getInstructions(
                  true,
                  false,
                  page,
                  filters.concat(userFilter),
                  sortKey,
                  sortDirection
                );
              }}
              loadingInProgress={instructionLoadingInProgress}
            >
              {instructions.map((action: InstructionOverview) => {
                return <ActionRowSimplified data={action} />;
              })}
            </SortableTable>
          </StyledCard>
        )}
        {selectedTab === SUBSCRIBED_EVENTS_TAB_KEY && (
          <EventsCard
            events={events}
            eventOccurrenceCountDuration={eventOccurrenceCountDuration}
            hasMoreEvents={moreEventsCanBeLoaded}
            loadingInProgress={eventsLoadingInProgress}
            manufacturingEntities={manufacturingEntities}
            getAllManufacturingEntities={getAllManufacturingEntities}
            manufacturingEntitiesMeta={manufacturingEntitiesMeta}
            getEvents={getEventsHelper}
          />
        )}
      </StyledContainer>
      {(userLoadingInProgress ||
        updateUserOperation.operationInProgress ||
        deleteUserOperation.operationInProgress) && <Loader />}
    </>
  );
};

export default UserProperties;
