import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import FilterTags from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/filter_tags';
import {
  FilterTag,
  FilterType,
  isDateFilter,
  ManufacturingEntityFilterTag,
  PopupFilterProps,
} from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/interfaces';
import FilterPopup, {
  getFilterData,
} from '@dev/base-web/dist/view/components/filter_tags_and_sorting_dropdown/components/filter_popup';
import { CurrentEventsCardProps } from './interface';
import CardEventsList from './components/events_list';
import { useWindowSize } from '@dev/base-web/dist/view/themes/helpers';
import { useUserConfiguration } from '@dev/base-web/dist/view/components/global/current_user_context';
import { FilterOperation } from '@dev/base-web/dist/model/domain/common/filter_data';

const FilterTagsWrapper = styled.div`
  padding: 10px ${({ theme }) => theme.cards.normalWrapper.padding.horizontal}px;
`;

const EventsListCard = ({
  eventType,
  onEventSelect,
  selectedEvent,
  activeEvents,
  closedEvents,
  manufacturingEntities,
  manufacturingEntitiesLoading,
  getActiveEvents,
  reloadActiveEvents,
  getClosedEventsFirstPage,
  getMoreClosedEvents,
  reloadClosedEvents,
  getAllManufacturingEntities,
}: CurrentEventsCardProps) => {
  const { heigth } = useWindowSize();

  const [userConfiguration] = useUserConfiguration();

  const filterProps: PopupFilterProps[] = [
    {
      filterLabelKey: 'eval_time_span',
      filterRangeStartKey: 'startTimestamp',
      filterRangeEndKey:
        eventType === 'closed' ? 'endTimestamp' : 'startTimestamp',
      isInclusive: false,
      filterType: FilterType.DATE_RANGE,
    },
    {
      filterLabelKey: 'event_name',
      filterType: FilterType.TEXT,
      filterKey: 'name',
      iconName: 'info',
    },
    {
      filterLabelKey: 'event_category',
      filterType: FilterType.EVENT_TYPE,
      filterKey: 'type',
      multiselect: true,
    },
    {
      filterLabelKey: 'manufacturing_entity',
      filterType: FilterType.MANUFACTURING_ENTITY,
      filterKey: 'manufacturingEntity.id',
      manufacturingEntities: manufacturingEntities,
      loadingInProgress: manufacturingEntitiesLoading,
    },
    {
      filterLabelKey: 'include_subsequent_events',
      filterType: FilterType.BOOLEAN,
      filterKey: 'includeSubsequentEvents',
      iconName: 'info',
    },
  ];

  const [filterTagsRaw, setFilterTagsRaw] = useState<readonly FilterTag[]>([]);
  const [isLoadingDisabled, setLoadingDisabled] = useState(true);

  useEffect(() => {
    if (!manufacturingEntitiesLoading && manufacturingEntities.length === 0) {
      getAllManufacturingEntities();
    }
  }, []);

  useEffect(() => {
    if (userConfiguration.selectedMainManufacturingEntity) {
      const filter: ManufacturingEntityFilterTag = {
        filterData: [
          {
            key: 'manufacturingEntity.id',
            operation: FilterOperation.EQ_OPERATOR,
            value: [userConfiguration.selectedMainManufacturingEntity.id],
          },
        ],
        manufacturingEntities: manufacturingEntities,
        type: FilterType.MANUFACTURING_ENTITY,
        icon: 'location',
      };
      setFilterTagsRaw([
        filter,
        ...filterTagsRaw.filter(
          (f) => f.type !== FilterType.MANUFACTURING_ENTITY
        ),
      ]);
    }
    setLoadingDisabled(false);
  }, [userConfiguration, manufacturingEntities]);

  // the date filters should filter events a bit differently for open and closed events
  // the range end key field depends on it
  const filterTags = useMemo(
    () =>
      filterTagsRaw.map<FilterTag>((f) => {
        if (isDateFilter(f)) {
          return {
            ...f,
            filterData: [
              f.filterData[0],
              {
                ...f.filterData[1],
                key: eventType === 'closed' ? 'endTimestamp' : 'startTimestamp',
              },
            ],
          };
        }

        return f;
      }),
    [eventType, filterTagsRaw]
  );

  const filters = useMemo(() => getFilterData(filterTags), [filterTags]);

  return (
    <Card
      titleId={eventType === 'open' ? 'tab_curr_events' : 'tab_closed_events'}
      headerContent={
        <FilterPopup
          filters={filterTags}
          filterProps={filterProps}
          onFiltersChanged={(tags) => {
            setFilterTagsRaw(tags);
          }}
        />
      }
      margin="none"
    >
      <FilterTagsWrapper>
        <FilterTags
          filterTags={filterTags}
          tagMaxWidth={250}
          onChange={(filters) => {
            setFilterTagsRaw(filters);
          }}
        />
      </FilterTagsWrapper>

      <CardEventsList
        height={heigth - 335}
        bottomGradientHeight={64}
        topGradientHeight={12}
        filters={filters}
        onEventSelect={onEventSelect}
        selectedEvent={selectedEvent}
        events={
          eventType === 'open'
            ? activeEvents.data.results
            : closedEvents.data.results
        }
        hasMoreResults={
          eventType === 'open'
            ? activeEvents.data.hasMoreResults
            : closedEvents.data.hasMoreResults
        }
        loadingInProgress={
          eventType === 'open'
            ? activeEvents.meta.loadingInProgress
            : closedEvents.meta.loadingInProgress
        }
        loadError={
          eventType === 'open'
            ? activeEvents.meta.error
            : closedEvents.meta.error
        }
        loadEvents={
          eventType === 'open' ? getActiveEvents : getClosedEventsFirstPage
        }
        reloadEvents={
          eventType === 'open' ? reloadActiveEvents : reloadClosedEvents
        }
        loadMoreEvents={
          eventType === 'closed' ? getMoreClosedEvents : undefined
        }
        isLoadingDisabled={isLoadingDisabled}
      />
    </Card>
  );
};

export default EventsListCard;
