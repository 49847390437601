import React, { useEffect, useRef } from 'react';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import ChartLegend from '@dev/base-web/dist/view/components/global/chart_legend';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import AggregatedEventResultsChart, {
  CHART_LEGEND_ITEMS,
} from './components/AggregatedEventResultsChart';
import { DowntimeCardPropsInterface } from './interface';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import { FilterOperation } from '@dev/base-web/dist/model/domain/common/filter_data';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import onLocaleChanged from '../../../../components/helpers/LocaleChangeHandler';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';
import { useBaseUrlWithPathPrefix } from '@dev/base-web/dist/view/helpers/use_path_prefix';
import DarwinConfig, {
  DARWIN_BASE_URL,
} from '@dev/base-web/dist/model/domain/authorization/darwin';

const DowntimeCard = ({
  interval,
  aggregatedDowntimes,
  aggregatedDowntimesMeta,
  getAggregatedDowntimes,
  resetAggregatedDowntimes,
  considerPlannedStops,
  configs,
  selectedEntity,
}: DowntimeCardPropsInterface) => {
  const callAggregatedDowntimes = () => {
    if (!interval) return;

    getAggregatedDowntimes(
      new Date(interval.start),
      new Date(interval.end),
      [
        {
          key: 'excludePlannedStops',
          operation: FilterOperation.EQ_OPERATOR,
          value: considerPlannedStops || false,
        },
        ...(selectedEntity
          ? [
              {
                key: 'manufacturingEntity.id',
                operation: FilterOperation.EQ_OPERATOR,
                value: selectedEntity.id,
              },
            ]
          : []),
      ],
      'duration',
      SortingDirection.DESCENDING
    );
  };

  const prevLocaleRef = useRef();
  const intl = useIntl();
  useEffect(() => {
    (prevLocaleRef.current as any) = intl.locale;
    const locale = intl.locale;
    prevLocale &&
      locale &&
      onLocaleChanged(
        { intl: { locale: prevLocale } as IntlShape },
        { intl: { locale } as IntlShape },
        callAggregatedDowntimes
      );
  });
  const prevLocale = prevLocaleRef.current as unknown as string;

  const prevSelectedEntity = usePrevious(selectedEntity);

  useEffect(() => {
    if (prevSelectedEntity !== selectedEntity) resetAggregatedDowntimes();

    const timer = setInterval(() => {
      callAggregatedDowntimes();
    }, 20000);

    return () => {
      clearInterval(timer);
    };
  }, [selectedEntity, considerPlannedStops, interval]);

  useEffect(() => {
    if (configs !== undefined && interval) callAggregatedDowntimes();
  }, [considerPlannedStops, interval, selectedEntity]);

  const limitedAggregatedDowntimes = aggregatedDowntimes.slice(0, 9);

  const analyticsUrlWithPrefix = useBaseUrlWithPathPrefix(DARWIN_BASE_URL);

  return (
    <Card
      titleContent={<FormattedMessage id={'top_events'} />}
      headerContent={
        <>
          {limitedAggregatedDowntimes.length > 0 &&
            aggregatedDowntimesMeta.loadingInProgress && (
              <DetailLoader style={{ width: 100, height: 40 }} />
            )}
          <Button
            type="secondary"
            label={'details'}
            onClick={() => {
              window.location.href = `${analyticsUrlWithPrefix}${DarwinConfig.Downtimes.url.path}`;
            }}
          />
          <ChartLegend legendItems={CHART_LEGEND_ITEMS} />
        </>
      }
    >
      <AggregatedEventResultsChart
        aggregatedDowntimes={limitedAggregatedDowntimes}
        height={430}
        showEventCount
        meta={aggregatedDowntimesMeta}
        showManufacturingEntityHierarchy={
          !!(
            configs &&
            configs.showManufacturingEntityHierarchy &&
            configs.showManufacturingEntityHierarchy.value
          )
        }
        interval={interval}
        considerPlannedStops={considerPlannedStops}
      />
    </Card>
  );
};

export default DowntimeCard;
