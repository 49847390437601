import React, { useState, useEffect } from 'react';
import { ParallelEventsScreenPropsInterface } from './interface';
import { TimeSpan } from '@dev/base-web/dist/model/domain/common/time_span';
import { endOfDay, startOfDay } from 'date-fns';
import {
  ScreenContent,
  ScreenRoot,
  ScreenToolbar,
} from '@dev/base-web/dist/view/components/global/styled_components';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import { useNavigate } from 'react-router';
import { FullWidthCard } from '@dev/base-web/dist/view/components/global/card';
import styled from 'styled-components';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { DateRangePicker } from '@dev/base-web/dist/view/components/inputs/date_range_picker';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { Column } from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { ParallelEventsRow } from './components/parallel_events_row';
import Tooltip from '@dev/base-web/dist/view/components/global/tooltip';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { renderTooltipContent } from '../common/common';
import MergeModal from './components/merge_modal';
import { EventRule } from '../../model/domain/parallel_events/parallel_events';
import { EventDefinitionAdd } from '../../model/domain/event/event_definition';
import {
  useNotificationForOperation,
  useNotificationForOperationError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import Header from '@dev/base-web/dist/view/components/global/header_view';

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 24px;
`;

const TimespanContainer = styled.div`
  margin-right: 16px;
`;

const RightContainer = styled.div`
  text-align: right;
  margin-right: 15px;
  margin-left: 22px;
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
`;

const StyledTriggerIcon = styled(Icon)`
  margin-bottom: 8px;
  margin-left: 4px;
  cursor: pointer;
  margin-bottom: 0px;
`;

const columns = (intl: IntlShape): Array<Column> => [
  {
    title: 'manufacturing_entity',
    isLeftAligned: true,
    width: 4,
  },
  {
    title: 'events',
    isLeftAligned: true,
    width: 5,
  },
  {
    title: 'parallel_occurrences',
    width: 3,
    popupText: intl.formatMessage({ id: 'parallel_events_count_hint' }),
  },
  {
    title: 'single_occurrences',
    width: 3,
    popupText: intl.formatMessage({ id: 'parallel_events_single_count_hint' }),
  },
  {
    title: 'last_occurrence',
    isLeftAligned: true,
    width: 4,
  },
];

const ParallelEventsView = ({
  timespan,
  parallelEvents,
  loadingInProgress,
  loadingFailed,
  getEvent,
  event,
  eventOperationState,
  createEvent,
  eventCreationOperationState,
  getParallelEvents,
}: ParallelEventsScreenPropsInterface) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [timespanChosen, setTimespan] = useState<TimeSpan>(
    timespan
      ? timespan
      : {
          start: startOfDay(new Date()).valueOf(),
          end: endOfDay(new Date()).valueOf(),
        }
  );
  const [eventRules, setEventRules] = useState<EventRule[]>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getParallelEvents(timespanChosen);
  }, [timespanChosen]);

  const selectEvents = (rules: EventRule[]) => {
    setEventRules(rules);
    setShowModal(true);
  };

  const mergeEvents = (newEvent: EventDefinitionAdd) => {
    void createEvent(newEvent);
  };

  useNotificationForOperation(
    eventCreationOperationState,
    intl.formatMessage({ id: 'event_created' }),
    intl.formatMessage({ id: 'event_creation_failed' }),
    intl.formatMessage({ id: 'event_creation_failed' }),
    intl,
    () => {
      setShowModal(false);
      getParallelEvents(timespanChosen);
    }
  );

  useNotificationForOperationError(
    eventCreationOperationState,
    intl,
    intl.formatMessage({ id: 'event_creation_failed' })
  );

  const ToolTipIcon = () => (
    <div>
      <StyledTriggerIcon name="unknown" color="#0DA6A0" size={28} />
    </div>
  );

  return (
    <ScreenRoot>
      <Header
        title={'parallel_events'}
        backButtonTitle={ShannonConfig.Dashboard.label}
        onBackPressed={() => navigate('/', { replace: true })}
      ></Header>
      <ScreenContent>
        <ScreenToolbar></ScreenToolbar>
        <FullWidthCard>
          <TableHeaderContainer>
            {/* placeholder for future TabBar */}
            <div></div>
            {/* <TabBar
                titles={tabBarElements}
                tabIndex={activeTab}
                onSelectedIndexChange={onTabIndexChanged}
            /> */}
            <StyledRightContainer>
              <TimespanContainer>
                <FormattedMessage id="timespan" />
              </TimespanContainer>
              <DateRangePicker
                onDateRangeChanged={(startDate: Date, endDate: Date) => {
                  setTimespan({
                    start: startDate.valueOf(),
                    end: endDate.valueOf(),
                  });
                }}
                selectedStartDate={new Date(timespanChosen.start)}
                selectedEndDate={new Date(timespanChosen.end)}
                maxDateRangeInMilliseconds={604800000}
                clearable
              />
              <RightContainer>
                <Tooltip
                  titleLabel={'parallel_events'}
                  titleIcon={'info'}
                  content={renderTooltipContent('parallel_events_hint')}
                  position={'right'}
                  trigger={ToolTipIcon()}
                />
              </RightContainer>
            </StyledRightContainer>
          </TableHeaderContainer>
          <SortableTable
            error={loadingFailed}
            dataLength={0}
            getNextResults={() => {}}
            hasMoreResults={false}
            loadingInProgress={loadingInProgress}
            columns={columns(intl)}
            page={0}
          >
            {eventRules ? (
              <MergeModal
                open={showModal}
                eventRules={eventRules}
                getEvent={getEvent}
                event={event}
                eventOperationState={eventOperationState}
                eventCreationOperationState={eventCreationOperationState}
                close={() => setShowModal(false)}
                merge={mergeEvents}
              />
            ) : (
              []
            )}
            {parallelEvents.map((entry) => {
              return (
                <ParallelEventsRow
                  parallelEvents={entry}
                  key={entry.eventRules[0].id}
                  selectEvents={selectEvents}
                />
              );
            })}
          </SortableTable>
        </FullWidthCard>
      </ScreenContent>
    </ScreenRoot>
  );
};

export default ParallelEventsView;
