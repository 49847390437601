import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import ModalWithHeaderAndTwoButtons from '@dev/base-web/dist/view/components/modal/modal';
import { AdditionSolutionInfo } from '../../../model/domain/instruction/instruction';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { StyledInput } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import LabelWithHint from '@dev/base-web/dist/view/components/global/label_with_hint';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
`;

const InputErrorContainer = styled.div`
  color: #e30021;
  text-align: left;
  margin-top: 8px;
  width: 100%;
`;

const ModalInput = styled(StyledInput)`
  width: 100%;
  margin-top: 8px;
`;

function isValidUrl(link: string | undefined) {
  if (!link) return false;

  try {
    const url = new URL(link);
    return !!url;
  } catch (_) {
    return false;
  }
}

const AdditionalSolutionInfoModal = ({
  info,
  triggerButton,
  isUpdate,
  close,
  update,
  triggerDisabled,
}: {
  info?: AdditionSolutionInfo;
  triggerButton: ReactNode;
  triggerDisabled: boolean;
  isUpdate: boolean;
  close: () => void;
  update: (info: AdditionSolutionInfo) => void;
}) => {
  const intl = useIntl();

  const [label, setLabel] = useState<string>();
  const [link, setLink] = useState<string>();

  const onLabelChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => setLabel(data.value);

  const onLinkChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => setLink(data.value);

  useEffect(() => {
    setLabel(info?.label);
    setLink(info?.url);
  }, [info]);

  const isUrlValid = useMemo(() => {
    return isValidUrl(link);
  }, [link]);

  return (
    <ModalWithHeaderAndTwoButtons
      headerLabel={
        isUpdate
          ? 'update_additional_information'
          : 'add_additional_information'
      }
      okLabel={isUpdate ? 'update' : 'add'}
      okDisabled={!(label && isUrlValid)}
      cancelLabel="cancel_button"
      onModalCancelPressed={close}
      onModalOkPressed={() => {
        label && link && update({ label: label, url: link });
      }}
      triggerButton={triggerButton}
      triggerDisabled={triggerDisabled}
    >
      <Form>
        <Form.Field error={!isUrlValid}>
          <LabelWithHint label={'link'} />
          <ModalInput
            placeholder={intl.formatMessage({ id: 'link_placeholder' })}
            value={link}
            onChange={onLinkChange}
            error={!isUrlValid}
          />
          {!isUrlValid && (
            <InputErrorContainer>
              <FormattedMessage id="invalid_url" />
            </InputErrorContainer>
          )}
        </Form.Field>
        <Form.Field error={!label}>
          <LabelWithHint label={'label'} />
          <ModalInput
            placeholder={intl.formatMessage({ id: 'label_placeholder' })}
            value={label}
            onChange={onLabelChange}
            error={!label}
          />
        </Form.Field>
        <InfoContainer>
          <Hint messageKey={'additional_info_hint'} />
        </InfoContainer>
      </Form>
    </ModalWithHeaderAndTwoButtons>
  );
};

export default AdditionalSolutionInfoModal;
