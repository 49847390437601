import { SizeMe } from 'react-sizeme';
import React, { useState } from 'react';
import styled from 'styled-components';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { useIntl } from 'react-intl';
import { checkAuthorizationRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import InstructionCommentsSideCard from './instruction_comment_side_card';
import InstructionSideTranslationsCard from './instruction_side_translation_card';
import ApproveCard from './instruction_approve_card';
import InstructionHistorySideCard from './instruction_history_card';
import InstructionLanguagesSideCard from './instruction_language_card';
import InstructionRelatedEventsCard from './related_events/instruction_related_event_card';
import InstructionFigures from './instruction_figures';
import {
  ActionDefinitionHistoryDTO,
  BasicActionDefinitionDTO,
} from '@/model/domain/instruction/instruction.ts';
import { LoadingListInterface } from '@dev/base-web/dist/model/redux/helpers/interfaces';

const Sidebar = styled.div`
  height: fit-content;
  max-width: 400px;
  flex-shrink: 1;
`;

const SidebarContentContainer = styled.div<{
  showTranslationVerification?: boolean;
}>`
  height: fit-content;
  pointer-events: ${(props) =>
    props.showTranslationVerification ? 'none' : 'auto'};
  opacity: ${(props) => (props.showTranslationVerification ? '50%' : '100%')};
`;

interface InstructionSideBarProps {
  readonly isNew: boolean;
  readonly readMode: boolean;
  readonly currentUser: UserDetails | null;
  readonly versionApproved: boolean;
  readonly approveVersion: (approved: boolean) => void;
  readonly item: BasicActionDefinitionDTO | undefined;
  readonly revisionNumber?: number;
  readonly onRevisionSelected: (revision: ActionDefinitionHistoryDTO) => void;
  readonly isLoading: boolean;
  readonly onRemoveEvents: (id: string) => void;
  readonly showAddEventsModal: (visible: boolean) => void;
  readonly selectedLanguage: string | null;
  readonly onLanguageChanged: (languageCode: string) => void;
  readonly onNewLanguageTranslated: (languageCode: string) => void;
  readonly deleteComment: (id: string) => void;
  readonly showTranslationVerification?: boolean;
  readonly languagesToVerify: string[];
  readonly onTranslateLanguageChanged: (toLanguage: string) => void;
  readonly verifiedLanguages: string[];
  readonly onGlossaryClicked: () => void;
  readonly versions: LoadingListInterface<ActionDefinitionHistoryDTO>;
}

const InstructionSideBar = ({
  isNew,
  readMode,
  currentUser,
  versionApproved,
  approveVersion,
  item,
  onRevisionSelected,
  isLoading,
  onRemoveEvents,
  showAddEventsModal,
  onLanguageChanged,
  onNewLanguageTranslated,
  selectedLanguage,
  deleteComment,
  showTranslationVerification,
  languagesToVerify,
  onTranslateLanguageChanged,
  verifiedLanguages,
  onGlossaryClicked,
  versions,
  revisionNumber,
}: InstructionSideBarProps) => {
  const intl = useIntl();

  const [sidebarHeight, setSidebarHeight] = useState<number>();

  const { write: configWrite, delete: configDelete } = checkAuthorizationRWD(
    UserPrivilegePermission.ACTION_CONFIG,
    ApplicationCategory.SHANNON,
    currentUser
  );

  const { write: canApprove } = checkAuthorizationRWD(
    UserPrivilegePermission.ACTION_APPROVE,
    ApplicationCategory.SHANNON,
    currentUser
  );

  const languageCodes = languagesToVerify ?? [];

  const isTranslationModeActive =
    showTranslationVerification && languagesToVerify;

  return (
    <SizeMe
      monitorHeight
      refreshRate={500}
      children={({ size }) => {
        if (size.height && size.height != sidebarHeight) {
          setSidebarHeight(size.height);
        }

        return (
          <Sidebar>
            {isTranslationModeActive && (
              <InstructionSideTranslationsCard
                verifiedLanguages={verifiedLanguages}
                languageCodes={languageCodes}
                onLanguageChanged={onTranslateLanguageChanged}
              />
            )}
            <SidebarContentContainer
              showTranslationVerification={showTranslationVerification}
            >
              {!isTranslationModeActive &&
                !readMode &&
                !isNew &&
                (canApprove || !versionApproved) && (
                  <ApproveCard
                    authorized={canApprove}
                    approved={versionApproved}
                    onSwitch={approveVersion}
                  />
                )}
              {!isNew && (
                <>
                  <InstructionFigures figures={item?.metrics} />
                  <br />
                </>
              )}
              {!isTranslationModeActive &&
                !readMode &&
                !isNew &&
                item &&
                versions && (
                  <>
                    <InstructionHistorySideCard
                      title={intl.formatMessage({ id: 'versions' })}
                      versions={versions}
                      pendingUpdates={item.pendingUpdates || []}
                      onRevisionSelected={onRevisionSelected}
                      currentRevisionNumber={
                        item.revisionNumber ? item.revisionNumber : 0
                      }
                      canChangeVersion={configWrite}
                      selectedRevisionNumber={revisionNumber}
                      isLoading={isLoading}
                    />
                    <br />
                  </>
                )}
              {!showTranslationVerification &&
                item &&
                (isNew || item.availableTranslations) && (
                  <InstructionLanguagesSideCard
                    title={intl.formatMessage({ id: 'translations' })}
                    isNewAction={isNew}
                    existingTranslations={item.availableTranslations || []}
                    onLanguageChanged={onLanguageChanged}
                    onNewLanguageTranslated={onNewLanguageTranslated}
                    selectedLanguage={selectedLanguage}
                    canEdit={configWrite}
                    readMode={readMode}
                    onGlossaryClicked={onGlossaryClicked}
                  />
                )}
              {item && item.events && (!isNew || item.events.length > 0) && (
                <>
                  <br />
                  <div id="related_events">
                    <InstructionRelatedEventsCard
                      isNewInstruction={isNew || readMode}
                      deletedItemCallback={onRemoveEvents}
                      showAddEventsModal={showAddEventsModal}
                      events={item.events}
                      title={intl.formatMessage({ id: 'related_events' })}
                      item={item}
                      canEdit={configWrite && !readMode}
                      canDelete={configWrite && !readMode}
                    />
                  </div>
                </>
              )}
              {item && item.comments && (
                <>
                  <br />
                  <InstructionCommentsSideCard
                    title={intl.formatMessage({ id: 'comments' })}
                    comments={item.comments}
                    onDeleteClicked={deleteComment}
                    canDelete={configDelete && !readMode}
                  />
                </>
              )}
              <br />
            </SidebarContentContainer>
          </Sidebar>
        );
      }}
    />
  );
};

export default InstructionSideBar;
