import React, { useEffect, useMemo, useState } from 'react';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { InstructionListProps } from './interfaces';
import InstructionRow from './components/instruction_row';
import { FilterData } from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  Column,
  ColumnFilterOption,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import { useIntl } from 'react-intl';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';

const columns = (accessLevelOptions: ColumnFilterOption[]): Array<Column> => [
  {
    title: 'name',
    isLeftAligned: true,
    sortKey: 'name',
    filterType: ColumnFilterType.TEXT,
    width: 6,
  },
  {
    title: 'access_level',
    isLeftAligned: true,
    sortKey: 'accessLevel',
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: accessLevelOptions,
    width: 3,
  },
  {
    title: 'col_author',
    isLeftAligned: true,
    sortKey: 'createdBy',
    filterType: ColumnFilterType.TEXT,
    width: 4,
  },
  {
    title: 'col_created_time',
    sortKey: 'createdDate',
    isLeftAligned: true,
    filterType: ColumnFilterType.DATE,
    width: 3,
  },
];

const TableContainer = styled.div`
  margin-top: 16px;
`;

const InstructionList: React.FC<InstructionListProps> = ({
  instructions,
  getInstructions,
  instructionLoadingInProgress,
  moreInstructionsCanBeLoaded,
  previousInstructionsSearchData,
  externalFilters,
  minHeight,
  card,
}) => {
  const { read: userHasMaintainerPrivilege } = useAuthorizationCheckRWD(
    UserPrivilegePermission.MAINTAINER_ACTION_DEFINITIONS,
    ApplicationCategory.SHANNON
  );

  const [lastSearchData, setLastSearchData] = useState<SearchData>(
    previousInstructionsSearchData ?? {}
  );
  const [initialSearchData] = useState<SearchData | undefined>(
    previousInstructionsSearchData ?? {
      page: 0,
      sortConfig: {
        key: 'createdDate',
        direction: SortingDirection.DESCENDING,
      },
    }
  );
  const [page, setPage] = useState<number>(0);

  const intl = useIntl();

  const getLastSortConfigParams = () => {
    const sortKey = lastSearchData.sortConfig
      ? lastSearchData.sortConfig.key
      : undefined;
    const sortDirection = lastSearchData.sortConfig
      ? lastSearchData.sortConfig.direction
      : undefined;
    return { sortKey, sortDirection };
  };

  const debouncedGetInstructions = useDebouncedCallback(getInstructions, 700);

  useEffect(() => {
    const { sortKey, sortDirection } = getLastSortConfigParams();

    debouncedGetInstructions(
      true,
      false,
      0,
      lastSearchData.filters ?? [],
      sortKey,
      sortDirection
    );

    setPage(0);
  }, [intl.locale]);

  const getNextInstructions = (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => {
    debouncedGetInstructions(
      true,
      false,
      page,
      filters,
      sortKey,
      sortDirection,
      loadAllPagesUntilTheGivenOne
    );
    setPage(page);
    setLastSearchData({
      page,
      filters,
      ...(sortKey && sortDirection
        ? { sortConfig: { key: sortKey, direction: sortDirection } }
        : {}),
    });
  };

  const accessLevelOptions: ColumnFilterOption[] = useMemo(
    () =>
      ['OPERATOR', 'EXPERT', 'INTERNAL'].map((l) => ({
        value: l,
        textId: intl.formatMessage({ id: l.toLowerCase() }),
      })),
    []
  );

  return (
    <TableContainer>
      <SortableTable
        dataLength={instructions.length}
        hasMoreResults={moreInstructionsCanBeLoaded}
        columns={columns(accessLevelOptions)}
        getNextResults={getNextInstructions}
        initialSearchData={initialSearchData}
        loadingInProgress={instructionLoadingInProgress}
        page={page}
        maxHeight={400}
        minHeight={minHeight ?? '0px'}
        card={card}
        externalFilters={externalFilters}
      >
        {instructions.map((instruction) => {
          return (
            <InstructionRow
              data={instruction}
              disabled={
                instruction.accessLevel === 'EXPERT' &&
                !userHasMaintainerPrivilege
              }
            />
          );
        })}
      </SortableTable>
    </TableContainer>
  );
};

export default InstructionList;
