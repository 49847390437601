import React, { useEffect, useMemo, useState } from 'react';
import {
  ScreenToolbar,
  StyledBackground,
  StyledContainer,
} from '@dev/base-web/dist/view/components/global/styled_components';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import {
  Button,
  RightAlignedButtons,
} from '@dev/base-web/dist/view/components/global/button';
import { CONFIG, UserAdminProps } from './interface';
import { createColumns } from './UserAdminConfig';
import { UserTableRow } from './components/UserRow';
import styled from 'styled-components';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import {
  ROUTE_ID_TEMPLATE as USER_ROUTE_ID_TEMPLATE,
  ROUTE_ID_FOR_NEW as NEW_USER_ROUTE_ID,
} from '../user_properties';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import AddNewRoleModal from './components/AddNewRoleModal';
import {
  HeaderButtons,
  TableHeaderContainer,
} from '../../components/StyledComponents';
import DeleteRoleModal from './components/DeleteRoleModal';
import { UserPrivilege } from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import {
  DropdownItemProps,
  DropdownOnSearchChangeData,
  DropdownProps,
  Popup,
} from 'semantic-ui-react';
import { StyledDropDown } from '@dev/base-web/dist/view/components/inputs/styled_components';
import { debounce } from 'lodash';
import { FullWidthCard } from '@dev/base-web/dist/view/components/global/card';
import UserPrivilegesTable from './components/UserPrivilegesTable';
import Header from '@dev/base-web/dist/view/components/global/header_view';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import {
  useNotificationForOperation,
  useNotificationForOperationError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import { useNavigate } from 'react-router';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';

const Label = styled.div`
  padding-bottom: 10px;
  padding-top: 20px;
`;

const StyledDropdown = styled(StyledDropDown)`
  &&&&&&&&& {
    width: 288px;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ActionsContainer = styled(RightAlignedButtons)`
  margin-top: 16px;
`;

const tabBarElements = [
  <FormattedMessage id="users" />,
  <FormattedMessage id="user_privileges" />,
];

const UserAdmin: React.FC<UserAdminProps> = ({
  users,
  user,
  isSaaS,
  notRegisteredUsers,
  notRegisteredUsersMeta,
  userPrivileges,
  roleOperation,
  lastUserSearchData,
  getUsers,
  getAllManufacturingEntities,
  getUserPrivileges,
  getAllRoleNames,
  updateUsers,
  inviteUser,
  inviteUserMeta,
  getNotRegisteredUsers,
  resetUser,
  updatePrivileges,
  currentUser,
  manufacturingEntitiesMeta,
  userPrivilegesLoading,
  manufacturingEntities,
  roleNames,
  deleteRole,
  createRole,
  hasMoreResults,
  loadingInProgress,
  usersTotalElements,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [initialUserSearchData] = useState<SearchData | undefined>(
    lastUserSearchData
  );
  const [activeTab, setActiveTab] = useState(0);
  const [userPrivilegesChanged, setUserPrivilegesChanged] = useState(false);
  const [dropdownFocus, setDropdownFocus] = useState(false);
  const [localUserPrivileges, setLocalUserPrivileges] = useState<
    UserPrivilege[]
  >([]);
  const [selectedNotRegisteredUser, setSelectedNotRegisteredUser] =
    useState<UserDetails>();

  useEffect(() => {
    getUsers(0, []);
    getAllManufacturingEntities();
    getUserPrivileges(0);
    getAllRoleNames();
  }, []);

  // componentDidMount(): void {
  //   const tab = new URLSearchParams(location.search).get('tab');
  //   if (tab === '0' || tab === '1') {
  //     const activeTab = Number(tab);
  //     this.setState({ activeTab });
  //   }
  // }

  const onUpdatingUsersFinished = (operation?: OperationType) => {
    if (operation === OperationType.DELETE) {
      getNextResults(0, []);
    }
  };

  const onInviteUserFinished = (operation?: OperationType) => {
    if (operation === OperationType.CREATE && user) {
      navigate(USER_ROUTE_ID_TEMPLATE(user.id));
    }
  };

  useNotificationForOperation(
    updateUsers,
    'user_created',
    'user_updated',
    'user_deleted',
    intl,
    onUpdatingUsersFinished
  );

  useNotificationForOperation(
    inviteUserMeta,
    'user_invited',
    'user_invited',
    'user_invited',
    intl,
    onInviteUserFinished
  );

  // componentDidUpdate(prevProps: Readonly<UserAdminProps>): void {
  //   const { updateUsers, inviteUserMeta, intl } = this.props;
  //   const { updateUsers: oldUpdateUsers, inviteUserMeta: oldInviteUserMeta } =
  //     prevProps;
  //
  //   this.handleRoleOperationFinished(prevProps, intl);
  //   this.handleIfUserPrivilegesChanged(prevProps);
  // }

  const prevUserPrivileges = usePrevious(userPrivileges);

  useEffect(() => {
    if (userPrivileges) {
      if (!prevUserPrivileges || !prevUserPrivileges.length) {
        setLocalUserPrivileges(userPrivileges);
      } else {
        // Because the roles are in alphabetical order in all privileges we can safely use index.
        if (roleOperation.operation === OperationType.CREATE) {
          let changedRoleIndex =
            prevUserPrivileges[0].rolePermissions.findIndex(
              (oldRole, index) => {
                return (
                  oldRole.role !== userPrivileges[0].rolePermissions[index].role
                );
              }
            );

          // New role must be at the end.
          if (changedRoleIndex === -1) {
            changedRoleIndex = prevUserPrivileges[0].rolePermissions.length;
          }
          prevUserPrivileges.forEach((oldPrivilege, index) =>
            oldPrivilege.rolePermissions.splice(
              changedRoleIndex,
              0,
              userPrivileges[index].rolePermissions[changedRoleIndex]
            )
          );
        } else if (roleOperation.operation === OperationType.DELETE) {
          const changedRoleIndex = userPrivileges[0].rolePermissions.findIndex(
            (newRole, index) => {
              return (
                newRole.role !==
                prevUserPrivileges[0].rolePermissions[index].role
              );
            }
          );
          prevUserPrivileges.forEach((oldPrivilege) =>
            oldPrivilege.rolePermissions.splice(changedRoleIndex, 1)
          );
        }

        setLocalUserPrivileges(prevUserPrivileges);
      }
    }
  }, [userPrivileges]);

  useNotificationForOperation(
    roleOperation,
    'role_create_success',
    'role_update_success',
    'role_delete_success',
    intl,
    () => {
      getUserPrivileges(0);
      getAllRoleNames();
    }
  );

  useNotificationForOperationError(roleOperation, intl);

  const getNextResults = (
    page: number,
    filters: any,
    sortKey?: string,
    sortDirection?: any
  ) => {
    getUsers(page, filters, sortKey, sortDirection);
  };

  const onNewUserButtonPressed = () => {
    resetUser();
    navigate(NEW_USER_ROUTE_ID);
  };

  const onNotRegisteredUserDropdownSelect = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setSelectedNotRegisteredUser(data.value as unknown as UserDetails);
    setDropdownFocus(false);
  };

  const onNotRegisteredUserSearch = debounce(
    (
      _event: React.SyntheticEvent<HTMLElement>,
      data: DropdownOnSearchChangeData
    ) => {
      getNotRegisteredUsers(data.searchQuery);
    },
    1000
  );

  const onClickInvite = () => {
    if (selectedNotRegisteredUser) inviteUser(selectedNotRegisteredUser.id);
  };

  const renderGeneralTableHeader = (
    contentSticky: boolean,
    activeTab: number
  ) => {
    return (
      <TableHeaderContainer contentSticky={contentSticky}>
        <TabBar
          titles={tabBarElements}
          tabIndex={activeTab}
          onSelectedIndexChange={onTabChanged}
        />
        {activeTab === 0 ? (
          renderUserAdminActions(intl)
        ) : (
          <RightAlignedButtons>
            <DeleteRoleModal
              roleNames={roleNames}
              onModalOkPressed={deleteRole}
            />
            <AddNewRoleModal
              roleNames={roleNames}
              onModalOkPressed={createRole}
            />
          </RightAlignedButtons>
        )}
      </TableHeaderContainer>
    );
  };

  const renderUserAdminActions = (intl: IntlShape) => {
    const notRegisteredUserOptions = notRegisteredUsers.map((user) => ({
      text: user.username,
      value: user,
    }));

    const searchDropdown = (_options: DropdownItemProps[], _value: string) => {
      return notRegisteredUserOptions;
    };

    const onDropdownFocusChange = (focus: boolean) => {
      setDropdownFocus(focus);
    };

    return (
      <RightAlignedButtons>
        {isSaaS && (
          <Popup
            trigger={<Button type="secondary" icon="plus" label="invite" />}
            on={'click'}
            flowing
            position="bottom left"
          >
            <div>
              <Label>
                <FormattedMessage id="selected_user" />
              </Label>
              <DropdownContainer>
                <StyledDropdown
                  placeholder={intl.formatMessage({ id: 'search_for_a_user' })}
                  value={selectedNotRegisteredUser}
                  selection
                  onChange={onNotRegisteredUserDropdownSelect}
                  options={notRegisteredUserOptions}
                  search={searchDropdown}
                  onSearchChange={onNotRegisteredUserSearch}
                  loading={notRegisteredUsersMeta.loadingInProgress}
                  noResultsMessage={intl.formatMessage({
                    id: 'no_users_found',
                  })}
                  open={notRegisteredUserOptions.length > 0 && dropdownFocus}
                  onFocus={() => onDropdownFocusChange(true)}
                  onBlur={() => onDropdownFocusChange(false)}
                  onClick={() => onDropdownFocusChange(true)}
                  scrolling
                />
              </DropdownContainer>
              <ActionsContainer>
                <Button
                  type="primary"
                  label="invite"
                  onClick={onClickInvite}
                  disabled={!selectedNotRegisteredUser}
                  loading={inviteUserMeta.operationInProgress}
                />
              </ActionsContainer>
            </div>
          </Popup>
        )}
        <Button
          type="secondary"
          icon="new"
          label="create"
          onClick={onNewUserButtonPressed}
        />
      </RightAlignedButtons>
    );
  };

  const onPrivilegeChanged = (value: UserPrivilege) => {
    const index = userPrivileges?.findIndex(
      (privilege) =>
        privilege.category === value.category &&
        privilege.permission === value.permission
    );

    if (index !== undefined && index !== -1) {
      userPrivileges?.splice(index, 1, value);
      setLocalUserPrivileges([...userPrivileges]);
      setUserPrivilegesChanged(true);
    }
  };

  const ROUTE_TEMPLATE = (tab?: number) => {
    const queryParams: string[] = [];
    if (tab !== undefined) {
      queryParams.push(`tab=${tab}`);
    }
    return `${CONFIG.url?.path}?${queryParams.join('&')}`;
  };

  const onTabChanged = (tab: number) => {
    setActiveTab(tab);
    navigate(ROUTE_TEMPLATE(tab));
  };

  const roleOptions = useMemo(() => {
    return roleNames.map((l) => ({
      value: l,
      textId: intl.formatMessage({ id: l.toLowerCase() }),
    }));
  }, [roleNames]);

  return (
    <StyledContainer>
      <Header
        title={CONFIG.label ?? ''}
        backButtonTitle={ShannonConfig.Dashboard.label}
        onBackPressed={() => navigate('/', { replace: true })}
      >
        {activeTab === 1 && (
          <HeaderButtons>
            <Button
              type="primary"
              label={'save'}
              disabled={!userPrivilegesChanged}
              onClick={() => {
                if (userPrivileges) {
                  setUserPrivilegesChanged(false);
                  updatePrivileges(userPrivileges);
                }
              }}
              loading={roleOperation.operationInProgress}
            />
          </HeaderButtons>
        )}
      </Header>
      <StyledBackground>
        <ScreenToolbar></ScreenToolbar>
        <FullWidthCard>
          {activeTab === 0 ? (
            <SortableTable
              contentOverHeader={(contentSticky) =>
                renderGeneralTableHeader(contentSticky, activeTab)
              }
              stickyHeaderOffset={72}
              dataLength={users.length}
              hasMoreResults={hasMoreResults}
              columns={createColumns(
                manufacturingEntities,
                getAllManufacturingEntities,
                manufacturingEntitiesMeta.loadingInProgress,
                roleOptions
              )}
              getNextResults={getNextResults}
              loadingInProgress={loadingInProgress}
              initialSearchData={initialUserSearchData}
              openFiltersOnMount
              totalElements={usersTotalElements}
            >
              {users.map((user) => {
                return <UserTableRow user={user} resetUser={resetUser} />;
              })}
            </SortableTable>
          ) : (
            <UserPrivilegesTable
              userPrivileges={localUserPrivileges ?? []}
              currentUser={currentUser}
              onPrivilegeChanged={onPrivilegeChanged}
              userPrivilegesLoading={userPrivilegesLoading}
              contentOverHeader={(contentSticky) =>
                renderGeneralTableHeader(contentSticky, activeTab)
              }
            />
          )}
        </FullWidthCard>
      </StyledBackground>
    </StyledContainer>
  );
};

export default UserAdmin;
