import { RootReducerInterface } from '../../../../model/redux/interfaces';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import EventsList from './view';
import { EventsListStoreProps } from './interfaces';
import { createGetEventsThunk } from '../../../../model/redux/event_definition/thunks';
import ManufacturingEntityThunks from '../../../../model/redux/manufacturing_entity/thunks';

const mapStateToProps = (state: RootReducerInterface): EventsListStoreProps => {
  return {
    events: state.data.eventDefinitionState.events.data.results,
    eventOccurrenceCountDuration:
      state.configurationState.config.data?.eventOccurrenceCountDuration,
    moreEventsCanBeLoaded:
      state.data.eventDefinitionState.events.data.hasMoreResults,
    eventLoadingInProgress:
      state.data.eventDefinitionState.events.meta.loadingInProgress,
    eventLoadingError: state.data.eventDefinitionState.events.meta.error,
    previousEventSearchData:
      state.data.eventDefinitionState.events.meta.previousSearchData,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntitiesMeta:
      state.data.manufacturingEntityState.manufacturingEntities.meta,
  };
};

export default connect(mapStateToProps, {
  getEvents: createGetEventsThunk(),
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getAllManufacturingEntities,
})(injectIntl(EventsList));
