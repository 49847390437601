import { connect } from 'react-redux';
import { RootReducerInterface } from '../../model/redux/interfaces';
import { sendInstructionFeedback } from '../../model/redux/feedback/thunks';
import Thunks from '../../model/redux/events/thunks';
import * as InstructionThunks from '../../model/redux/instruction/thunks';
import CurrentEvents from './view';
import { CONFIG as C, CurrentEventsStoreProps } from './interface';

export const CONFIG = C;

const mapStateToProps = (
  state: RootReducerInterface
): CurrentEventsStoreProps => {
  return {
    currentInstruction: state.data.instructionState.currentInstruction.data,
    currentInstructionMeta: state.data.instructionState.currentInstruction.meta,
    currentEvent: state.data.eventState.currentEvent.data,
    currentEventMeta: state.data.eventState.currentEvent.meta,
    currentUser: state.userState.currentUser.data,
    feedbackMeta: state.data.feedbackState.sendFeedback.meta,
    closeEventUpdate: state.data.eventState.closeEventUpdate.meta,
    configs: state.configurationState.config.data,
  };
};

export default connect(mapStateToProps, {
  getCurrentInstruction: InstructionThunks.getCurrentInstruction,
  cleanCurrentInstruction: InstructionThunks.cleanCurrentInstruction,
  getCurrentEvent: Thunks.getCurrentEvent,
  sendInstructionFeedback,
  closeEvent: Thunks.closeEvent,
})(CurrentEvents);
