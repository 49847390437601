import { RootReducerInterface } from '../../model/redux/interfaces';
import { connect } from 'react-redux';
import { getAllMainEntityKpis } from '../../model/redux/kpis/thunk';
import { DashboardStoreProps } from './interface';
import Dashboard from './view';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import UserThunks from '../../model/redux/user/thunks';

export const CONFIG = ShannonConfig.Dashboard;

const mapStateToProps = (state: RootReducerInterface): DashboardStoreProps => ({
  user: state.userState.currentUser.data,
  configs: state.configurationState.config.data,
  mainEntityKpis: state.data.mainEntityKpiState.mainEntityKpis.data,
});

export default connect(mapStateToProps, {
  getAllMainEntityKpis,
  getUser: UserThunks.getUser,
})(Dashboard);
