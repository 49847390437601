import { RootReducerInterface } from '../../../../model/redux/interfaces';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import EventsList from '../events_list/view';
import { EventsListStoreProps } from '../events_list/interfaces';
import { createGetEventsThunk } from '../../../../model/redux/event_definition/thunks';
import { Actions } from '../../../../model/redux/event_definition/actions';
import ManufacturingEntityThunks from '../../../../model/redux/manufacturing_entity/thunks';

const mapStateToProps = (state: RootReducerInterface): EventsListStoreProps => {
  return {
    events:
      state.data.eventDefinitionState.eventsForDashboardSearch.data.results,
    eventOccurrenceCountDuration:
      state.configurationState.config.data?.eventOccurrenceCountDuration,
    moreEventsCanBeLoaded:
      state.data.eventDefinitionState.eventsForDashboardSearch.data
        .hasMoreResults,
    eventLoadingInProgress:
      state.data.eventDefinitionState.eventsForDashboardSearch.meta
        .loadingInProgress,
    eventLoadingError:
      state.data.eventDefinitionState.eventsForDashboardSearch.meta.error,
    previousEventSearchData:
      state.data.eventDefinitionState.eventsForDashboardSearch.meta
        .previousSearchData,
    manufacturingEntities:
      state.data.manufacturingEntityState.manufacturingEntities.data,
    manufacturingEntitiesMeta:
      state.data.manufacturingEntityState.manufacturingEntities.meta,
  };
};

export default connect(mapStateToProps, {
  getEvents: createGetEventsThunk(Actions.eventsForDashboardSearch),
  getAllManufacturingEntities:
    ManufacturingEntityThunks.getManufacturingEntities,
})(injectIntl(EventsList));
