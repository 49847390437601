import { combineReducers, Reducer } from 'redux';
import {
  createReducersForExecutingOperation,
  createReducersForLoadingSingleItem,
  createReducersForLoadingPagingList,
  createReducersForLoadingList,
} from '@dev/base-web/dist/model/redux/helpers/reducers';
import { ACTION_TYPES, Actions } from './actions';
import { ActionType, createReducer } from 'typesafe-actions';
import {
  InstructionOverview,
  ActionDefinitionReviewStatus,
  ActionDefinitionHistoryDTO,
  ActionDefinitionDTO,
} from '../../domain/instruction/instruction';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { InstructionDefinitionStateInterface } from './interface';
import { InstructionWithTranslation } from '@/model/domain/instruction/translation.ts';

const TAB_ACTION_HANDLERS = {
  SET_ACTIVE_INSTRUCTIONS_TAB: (
    _state: number,
    action: ActionType<typeof Actions.setActiveTab>
  ): any => {
    return action.payload;
  },
};

const LANGUAGE_ACTION_HANDLERS = {
  SET_SELECTED_INSTRUCTION_LANGUAGE: (
    _state: string | null,
    action: ActionType<typeof Actions.setSelectedInstructionLanguage>
  ): any => {
    return action.payload;
  },
};

const reducers: Reducer<InstructionDefinitionStateInterface> = combineReducers({
  instructions: createReducersForLoadingPagingList<InstructionOverview>(
    ACTION_TYPES.INSTRUCTIONS_KEY
  ),
  instructionsForDashboardSearch:
    createReducersForLoadingPagingList<InstructionOverview>(
      ACTION_TYPES.INSTRUCTIONS_FOR_DASHBOARD_SEARCH_KEY
    ),
  instructionsForAddRelationModal:
    createReducersForLoadingPagingList<InstructionOverview>(
      ACTION_TYPES.INSTRUCTIONS_FOR_ADD_RELATION_MODAL_KEY
    ),
  instruction: createReducersForLoadingSingleItem<ActionDefinitionDTO>(
    ACTION_TYPES.INSTRUCTION_KEY
  ),
  instructionVersions: createReducersForLoadingList<ActionDefinitionHistoryDTO>(
    ACTION_TYPES.INSTRUCTION_VERSIONS
  ),
  updateInstruction: createReducersForExecutingOperation(
    ACTION_TYPES.UPDATE_INSTRUCTION_KEY
  ),
  addEventsToInstruction: createReducersForExecutingOperation(
    ACTION_TYPES.ADD_EVENTS_TO_INSTRUCTION_KEY
  ),
  activeTab: createReducer<number>(0, TAB_ACTION_HANDLERS),
  selectedLanguage: createReducer<string | null>(
    null,
    LANGUAGE_ACTION_HANDLERS
  ),
  approveInstruction: createReducersForExecutingOperation(
    ACTION_TYPES.APPROVE_INSTRUCTION
  ),
  deleteComment: createReducersForExecutingOperation(
    ACTION_TYPES.DELETE_COMMENT
  ),
  translateInstruction:
    createReducersForLoadingSingleItem<InstructionWithTranslation>(
      ACTION_TYPES.TRANSLATE_INSTRUCTION_KEY
    ),
  updateTranslation: createReducersForExecutingOperation(
    ACTION_TYPES.UPDATE_TRANSLATION_KEY
  ),
  saveLastEditedLanguageAndTranslate: createReducersForExecutingOperation(
    ACTION_TYPES.SAVE_LAST_EDITED_LANGUAGE_AND_TRANSLATE_KEY
  ),
  instructionsOperation: createReducersForExecutingOperation(
    ACTION_TYPES.INSTRUCTIONS_OPERATION_KEY
  ),
  requestReview: createReducersForExecutingOperation(
    ACTION_TYPES.REQUEST_REVIEW_KEY
  ),
  reviewStatus:
    createReducersForLoadingSingleItem<ActionDefinitionReviewStatus>(
      ACTION_TYPES.REVIEW_STATUS
    ),
  instructionApprovalUsers: createReducersForLoadingList<UserDetails>(
    ACTION_TYPES.SOLUTION_APPROVAL_USERS
  ),
});

export default reducers;
