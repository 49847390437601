import { Checkbox } from '@dev/base-web/dist/view/components/global/checkbox';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import React from 'react';
import Event from '../../../model/domain/event/event_definition';
import { StyledRightColumn } from '../../../components/StyledComponents';
import { useNavigate } from 'react-router';
import { EVENTS_ROUTE_KEY } from '../interfaces';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  CenterAlignedTableCell,
  CheckboxCell,
  LeftAlignedTableCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import TextWithEllipsisAndTooltip, {
  EllipsisContainer,
} from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import MultiManufacturingEntityPopup from '../../../components/multi_manufacturing_entity_popup';
import UserCell from '@dev/base-web/dist/view/components/global/user_cell';

const EventName = styled.div`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #242424;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledEventRow = styled(StyledRow)`
  height: 64px;
`;

interface EventRowProps {
  data: Event;
  onRowChecked: (selected: boolean) => void;
  selected: boolean;
  showManufacturingEntityHierarchy: boolean;
}

const EventRow: React.FC<EventRowProps> = ({
  data,
  selected,
  onRowChecked,
  showManufacturingEntityHierarchy,
}) => {
  const {
    id,
    name,
    type,
    actionCount,
    commentCount,
    manufacturingEntities,
    occurrenceCount,
    createdBy,
  } = data;
  const navigate = useNavigate();
  const intl = useIntl();
  const { color, icon } = useEventTypeLook(type);
  const locationSet = new Set(
    manufacturingEntities.map((entity) => entity.name)
  );
  const locations = [...locationSet].join(', ');

  const onActionChecked = (event: React.FormEvent<HTMLInputElement>) => {
    onRowChecked(!selected);
    event.stopPropagation();
  };

  const onRowSelected = () => {
    navigate(`/${EVENTS_ROUTE_KEY}/${id}`);
  };

  return (
    <StyledEventRow onClick={onRowSelected}>
      <CheckboxCell>
        <Checkbox onChange={onActionChecked} checked={selected} />
      </CheckboxCell>
      <CenterAlignedTableCell>
        <Icon name={icon} color={color} size={14} />
      </CenterAlignedTableCell>
      <LeftAlignedTableCell>
        <EventName>
          <TextWithEllipsisAndTooltip
            text={
              name
                ? intl.formatMessage({ id: name, defaultMessage: name })
                : '-'
            }
            lines={2}
            style={{ width: 'calc(100% - 32px)' }}
          />
        </EventName>
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        {manufacturingEntities.length == 0 ? (
          '-'
        ) : manufacturingEntities.length == 1 ? (
          <ManufacturingEntityHierarchyElement
            manufacturingEntity={manufacturingEntities[0]}
            hideHiddenTypes
            showShortNames
            hiddenTypes={[
              ManufacturingEntityType.FACTORY,
              ManufacturingEntityType.AREA,
              ManufacturingEntityType.LINE,
            ]}
            showHierarchy={showManufacturingEntityHierarchy}
          />
        ) : (
          <MultiManufacturingEntityPopup
            manufacturingEntities={manufacturingEntities}
            showHierarchy={showManufacturingEntityHierarchy}
            trigger={
              <EllipsisContainer lines={2}>{locations}</EllipsisContainer>
            }
          />
        )}
      </LeftAlignedTableCell>
      <CenterAlignedTableCell>{occurrenceCount}</CenterAlignedTableCell>
      <CenterAlignedTableCell>{actionCount}</CenterAlignedTableCell>
      <CenterAlignedTableCell>{commentCount}</CenterAlignedTableCell>
      <LeftAlignedTableCell>
        <UserCell user={createdBy} />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <StyledRightColumn>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledRightColumn>
      </LeftAlignedTableCell>
    </StyledEventRow>
  );
};

export default EventRow;
