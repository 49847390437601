import { Button } from '@dev/base-web/dist/view/components/global/button';
import { HeaderButtons } from '@/components/StyledComponents.tsx';
import { checkAuthorizationRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import InstructionSettingsPopup from './instruction_settings';
import { useState } from 'react';
import { BasicDeleteConfirmationModal } from '@dev/base-web/dist/view/components/modal/delete_confirmation_modal';
import { OperationMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import RequestReviewPopup from '@/screens/edit_instruction/components/request_review_popup';

interface HeaderActionsProps {
  readonly instructionId?: string;
  readonly isNewInstruction: boolean;
  readonly readMode: boolean;
  readonly setReadMode: (readMode: boolean) => void;
  readonly currentUser?: UserDetails;
  readonly isSavable: boolean;
  readonly duplicateInstruction: () => void;
  readonly instructionUpdateMeta: OperationMetaState;
  readonly instructionDeleteMeta: OperationMetaState;
  readonly approvalMeta: OperationMetaState;
  readonly onSaveClicked: () => void;
  readonly onDeleteClicked: () => void;
  readonly isDuplicatedInstruction: boolean;
  readonly isApproved: boolean;
}

const HeaderActions = ({
  instructionId,
  readMode,
  setReadMode,
  currentUser,
  isSavable,
  isNewInstruction,
  duplicateInstruction,
  instructionUpdateMeta,
  instructionDeleteMeta,
  approvalMeta,
  onSaveClicked,
  onDeleteClicked,
  isDuplicatedInstruction,
  isApproved,
}: HeaderActionsProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { write, delete: canDelete } = checkAuthorizationRWD(
    UserPrivilegePermission.ACTION,
    ApplicationCategory.SHANNON,
    currentUser
  );

  return (
    <HeaderButtons>
      {instructionId &&
        !readMode &&
        !isApproved &&
        !instructionUpdateMeta.operationInProgress && (
          <RequestReviewPopup instructionId={instructionId} />
        )}
      {isDuplicatedInstruction && (
        <Button
          type="secondary"
          label="cancel"
          disabled={!write}
          onClick={onDeleteClicked}
        />
      )}
      {!readMode ? (
        <Button
          type={'primary'}
          label={'save'}
          disabled={!(isSavable && write)}
          loading={
            instructionUpdateMeta.operationInProgress ||
            approvalMeta.operationInProgress
          }
          onClick={onSaveClicked}
        />
      ) : (
        <Button
          type="secondary"
          icon="edit"
          label="edit"
          onClick={() => setReadMode(false)}
        />
      )}
      <InstructionSettingsPopup
        isNewInstruction={isNewInstruction}
        readMode={readMode}
        setReadMode={setReadMode}
        canWrite={write}
        canDelete={canDelete}
        duplicateInstruction={duplicateInstruction}
        setDeleteModalOpen={setDeleteModalOpen}
      />
      <BasicDeleteConfirmationModal
        onDeleteConfirmed={onDeleteClicked}
        onCancelPressed={() => {
          setDeleteModalOpen(false);
        }}
        title="delete_solution"
        confirmationTitle="delete_solution_verify"
        confirmationTitleValues={{ count: 1 }}
        modalVisible={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        okLoading={instructionDeleteMeta.operationInProgress}
      />
    </HeaderButtons>
  );
};

export default HeaderActions;
