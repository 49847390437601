import React, { ChangeEvent } from 'react';
import {
  InputIcon,
  StyledInput,
} from '@dev/base-web/dist/view/components/inputs/styled_components';
import styled from 'styled-components';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { useIntl } from 'react-intl';

const InputField = styled(StyledInput)`
  width: 350px;

  && input {
    border-radius: 20px;
  }
`;

interface SearchInputProps {
  readonly searchText?: string;
  readonly setSearchText: (searchText: string) => void;
}

const SearchInput = ({ setSearchText, searchText }: SearchInputProps) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <InputField
      onClick={(event: React.FormEvent<HTMLInputElement>) =>
        event.stopPropagation()
      }
      placeholder={intl.formatMessage({ id: 'search_action_or_event' })}
      value={searchText}
      icon={
        <InputIcon name={'search'} size={16} color={theme.colors.brownGrey} />
      }
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        setSearchText(e.target.value)
      }
    />
  );
};

export default SearchInput;
