import React, { useReducer, useEffect } from 'react';
import JoyRide, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride';
import { FormattedMessage } from 'react-intl';
import { pages, StepWithTitle } from './pages';
import Icon from '@dev/base-web/dist/view/components/global/icon';

const titleFormatter = (title: string) => {
  return (
    <div
      className="joyride-step-text"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Icon name="info" color="#ffffff" size={16} />
      <span
        style={{
          marginLeft: 8,
          fontSize: '18px',
          textAlign: 'left',
          fontFamily: 'Relative',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.33,
          letterSpacing: 'normal',
          paddingTop: '2px',
        }}
        className="active"
      >
        {<FormattedMessage id={title} />}
      </span>
    </div>
  );
};

const FormatTitle = (steps: StepWithTitle[]): Step[] => {
  return steps.map((_step) => {
    const title = _step.titleText ?? '';
    return { ..._step, title: titleFormatter(title) };
  });
};

const defaultOptions = {
  arrowColor: '#262626',
  backgroundColor: '#262626',
  beaconSize: 36,
  texAlign: 'left',
  overlayColor: 'rgba(0, 0, 0, 0.5)',
  primaryColor: '#0da6a0',
  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  textColor: '#ffffff',
  width: '450px',
  zIndex: 100,
};

const tooltipContainer = {
  textAlign: 'left',
  fontSize: '14px',
  fontFamily: 'Relative',
  fontWeight: 500,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.43,
  letterSpacing: 'normal',
  padding: '8px',
};

const tooltipContent = {
  padding: '24px 0px',
};

const tooltip = {
  borderRadius: '12px',
};

const buttonClose = {
  fontSize: '14px',
  fontWeight: 'bold',
};

const buttonNext = {
  opacity: 0,
  height: 0,
};

const buttonBack = {
  color: '#0da6a0',
  fontSize: '14px',
  //height: '18px',
  fontFamily: 'Relative',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  outline: 'none',
};

interface StateInterface {
  key: Date;
  run: boolean;
  continuous: boolean;
  loading: boolean;
  stepIndex: number;
  steps: Step[];
}

interface Props {
  page: string;
}

// Tour component
const NewFunctionTour = ({ page }: Props) => {
  const INITIAL_STATE: StateInterface = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: FormatTitle(pages[page]),
  };

  const reducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case 'START':
        return { ...state, run: true };
      case 'RESET':
        return { ...state, stepIndex: 0 };
      case 'STOP':
        return { ...state, run: false };
      case 'NEXT_OR_PREV':
        return { ...state, ...action.payload };
      case 'RESTART':
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };

  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const tourPage = `new-function-tour-${page}`;

  useEffect(() => {
    //localStorage.removeItem('tour');

    // Auto start the tour if the tour is not viewed before
    if (!localStorage.getItem(tourPage)) {
      dispatch({ type: 'START' });
    }
  }, []);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    localStorage.setItem(tourPage, '1');
  };

  const callback = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: 'STOP' });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  return (
    <>
      <JoyRide
        {...tourState}
        //tooltipComponent
        disableOverlayClose
        callback={callback}
        //showSkipButton={true}
        styles={{
          options: defaultOptions,
          tooltipContainer: tooltipContainer,
          buttonNext: buttonNext,
          buttonBack: buttonBack,
          tooltipContent: tooltipContent,
          buttonClose: buttonClose,
          tooltip: tooltip,
        }}
        locale={{
          last: <FormattedMessage id={'end_tour'} />,
          close: <FormattedMessage id={'close'} />,
          skip: <FormattedMessage id={'skip'} />,
          open: <FormattedMessage id={'open'} />,
          next: <FormattedMessage id={'next'} />,
          back: <FormattedMessage id={'back'} />,
        }}
      />
    </>
  );
};

export default NewFunctionTour;
