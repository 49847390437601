import { WrappedComponentProps } from 'react-intl';

export default function onLocaleChanged(
  props: WrappedComponentProps,
  prevProps: WrappedComponentProps,
  callback: () => void
) {
  const { intl } = props;

  if (intl.locale !== prevProps.intl.locale) {
    callback();
  }
}
