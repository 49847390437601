import React, { useEffect, useState } from 'react';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { FullWidthCard } from '@dev/base-web/dist/view/components/global/card';
import { CONFIG, EVENTS_ROUTE_KEY, EventsProps } from './interfaces';
import EventRow from './components/EventRow';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import Event, {
  EventDefinitionStatus,
} from '../../model/domain/event/event_definition';
import styled from 'styled-components';
import {
  toastrError,
  toastrSuccess,
  useToastrForError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import EventActionButtons from './components/EventActionButtons';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import useSelectableRows from '@dev/base-web/dist/view/components/sortable_table/components/selectable_table_rows_hook';
import { useNavigate, useLocation } from 'react-router';
import BatchEdit from './components/BatchEdit';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import { getActiveTabFromLocation } from '@/model/helpers/UrlQueryTabParser.ts';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import { Location } from 'history';
import { ScreenToolbar } from '@dev/base-web/dist/view/components/global/styled_components';
import { ConfigValue } from '@dev/base-web/dist/model/domain/configuration/configuration';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';
import EventDefinitionImportModal from './components/EventDefinitionImportModal';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { TableHeaderContainer } from '../../components/StyledComponents';
import Header from '@dev/base-web/dist/view/components/global/header_view';

export const TableContainer = styled.div`
  .visible.menu.transition {
    width: 200px;
    margin-top: 6px;
  }
  .ui.selection.dropdown .menu {
    border-top: 1px solid rgba(160, 199, 216) !important;
    border-top-right-radius: 0.2857rem;
    border-top-left-radius: 0.2857rem;
  }
  .ui.selection.visible.dropdown:hover .menu {
    border-top-color: rgba(160, 199, 216) !important;
  }
`;

export const createEventTypeDropdownIcon = (eventType: EventType) => {
  const { color, icon } = useEventTypeLook(eventType);

  return <Icon name={icon} color={color} size={16} />;
};

export const createColumns = (
  intl: IntlShape,
  eventOccurrenceCountDuration: ConfigValue | undefined,
  manufacturingEntities: readonly ManufacturingEntity[],
  getAllManufacturingEntities: (query?: string) => void,
  manufacturingEntityLoading: boolean
): Array<Column> => [
  {
    title: 'type',
    sortKey: 'type',
    filterType: ColumnFilterType.SELECTOR,
    filterOptions: Object.values(EventType).map((eventType) => {
      const dropdownIcon = createEventTypeDropdownIcon(eventType as EventType);
      return {
        image: dropdownIcon,
        value: eventType,
        textId: ' ',
        content: intl.formatMessage({ id: eventType.toLowerCase() }),
      };
    }),
    width: 1,
    fixedWidthInPx: 64,
  },
  {
    title: 'name',
    isLeftAligned: true,
    sortKey: 'name',
    filterType: ColumnFilterType.TEXT,
    width: 4,
  },
  {
    title: 'entity',
    isLeftAligned: true,
    filterKey: 'manufacturingEntity.id',
    sortKey: 'manufacturingEntity.name',
    filterType: ColumnFilterType.MANUFACTURING_ENTITY,
    filterOptions: manufacturingEntities,
    width: 2,
    getAllManufacturingEntities,
    useIdAsFilterValue: true,
    customFilterOperation: FilterOperation.CT_OPERATOR,
    isLoading: manufacturingEntityLoading,
  } as any, //TableHeaderManufacturingEntityFilterColumn,
  {
    title: 'col_occurrance',
    titleValues: eventOccurrenceCountDuration && {
      value: eventOccurrenceCountDuration.value,
      unit: eventOccurrenceCountDuration.unit?.toLowerCase(),
    },
    isLeftAligned: false,
    sortKey: 'occurrenceCount',
    filterType: ColumnFilterType.NUMBER,
    width: 2,
  },
  {
    title: 'solutions',
    isLeftAligned: false,
    sortKey: 'actionCount',
    filterType: ColumnFilterType.NUMBER,
    width: 2,
  },
  {
    title: 'comments',
    sortKey: 'commentCount',
    isLeftAligned: false,
    filterType: ColumnFilterType.NUMBER,
    width: 2,
  },
  {
    title: 'col_author',
    isLeftAligned: true,
    sortKey: 'createdBy',
    filterType: ColumnFilterType.TEXT,
    width: 3,
  },
];

const Events: React.FC<EventsProps> = ({
  events,
  eventImport,
  eventImportInProgress,
  eventImportError,
  eventImportStatus,
  eventImportStatusInProgress,
  eventImportStatusError,
  eventImportConfirmation,
  eventImportConfirmationInProgress,
  eventImportConfirmationError,
  startImport,
  resetImport,
  checkImportStatus,
  resetImportStatus,
  confirmImport,
  eventOccurrenceCountDuration,
  getEvents,
  archiveEvents,
  eventLoadingInProgress,
  moreEventsCanBeLoaded,
  eventLoadingError,
  getAllManufacturingEntities,
  manufacturingEntities,
  previousEventSearchData,
  setActiveTab,
  activeTab,
  multiEventUpdate,
  multiUpdateEvents,
  archiveEventsError,
  archiveEventsLoading,
  showHierarchy,
  manufacturingEntitiesMeta,
  eventsTotalElements,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const [lastSearchData, setLastSearchData] = useState<SearchData>(
    previousEventSearchData || {}
  );
  const [initialSearchData] = useState<SearchData | undefined>(
    previousEventSearchData
  );
  const [batchEditActive, setBatchEditActive] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [selectedRows, setSelectedRows, selectRow, isRowSelected] =
    useSelectableRows<Event>();
  const currentTab = getActiveTabFromLocation(location as Location);
  const [localActiveTab, setLocalActiveTab] = useState<number>(
    currentTab ?? activeTab
  );

  useEffect(() => {
    setLocalActiveTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      if (!multiEventUpdate.operationInProgress && !multiEventUpdate.error) {
        toastrSuccess(
          `${intl.formatMessage(
            { id: 'multi_event_update_success' },
            { count: selectedRows.length }
          )}`
        );
        unselectRowsAndReloadPages();
      } else if (multiEventUpdate.error && !multiEventUpdate.error.handled) {
        toastrError(
          `${intl.formatMessage({ id: multiEventUpdate.error.message })}`
        );
      }
    }
  }, [multiEventUpdate]);

  const showArchive = activeTab === 2;

  const editEventsRequest = (ids: string[], body: any) => {
    multiUpdateEvents(ids, body);
  };

  const batchEditCallBack = (
    archive?: boolean,
    type?: string,
    manufacturingEntity?: ManufacturingEntity
  ) => {
    if (type && type == 'cancel') {
      return setBatchEditActive(false);
    }
    if (selectedRows.length > 0) {
      const ids: string[] = [];
      selectedRows.map((item) => {
        ids.push(item.id);
      });

      let body = {};
      if (type && type !== '') {
        body = { ...body, type: type };
      }
      if (manufacturingEntity) {
        body = { ...body, manufacturingEntity: manufacturingEntity };
      }
      body = { ...body, isHidden: archive };

      editEventsRequest(ids, body);
      //history.go(0);
    }
    setBatchEditActive(false);
  };

  const getStatus = () => {
    if (localActiveTab === 0) {
      return EventDefinitionStatus.ACTIVE;
    } else if (localActiveTab === 1) {
      return EventDefinitionStatus.INACTIVE;
    }
    return EventDefinitionStatus.ARCHIVED;
  };

  const getStatusByTab = (tab: number) => {
    if (tab === 0) {
      return EventDefinitionStatus.ACTIVE;
    } else if (tab === 1) {
      return EventDefinitionStatus.INACTIVE;
    }
    return EventDefinitionStatus.ARCHIVED;
  };

  const getLastSorConfigParams = () => {
    const sortKey = lastSearchData.sortConfig
      ? lastSearchData.sortConfig.key
      : undefined;
    const sortDirection = lastSearchData.sortConfig
      ? lastSearchData.sortConfig.direction
      : undefined;
    return { sortKey, sortDirection };
  };

  useEffect(() => {
    unselectRowsAndReloadPages();
    setPage(0);
  }, [intl.locale]);

  useEffect(() => {
    const newTab = getActiveTabFromLocation(location as Location);
    if (newTab !== undefined && activeTab !== newTab) setActiveTab(newTab);
  }, [location]);

  const ROUTE_TEMPLATE = (tab?: number) => {
    const queryParams: string[] = [];
    if (tab !== undefined) {
      queryParams.push(`tab=${tab}`);
    }
    return `/${EVENTS_ROUTE_KEY}?${queryParams.join('&')}`;
  };

  useEffect(() => {
    getAllManufacturingEntities();
  }, [getAllManufacturingEntities]);

  const getNextEvents = (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => {
    getEvents(
      getStatus(),
      page,
      filters,
      sortKey,
      sortDirection,
      loadAllPagesUntilTheGivenOne
    );
    setLastSearchData({
      page,
      filters,
      ...(sortKey && sortDirection
        ? { sortConfig: { key: sortKey, direction: sortDirection } }
        : {}),
    });
    setPage(page);
  };

  const onTabChanged = (index: number) => {
    setActiveTab(index);

    navigate(ROUTE_TEMPLATE(index));

    const { sortKey, sortDirection } = getLastSorConfigParams();

    setSelectedRows([]);

    getEvents(
      getStatusByTab(index),
      0,
      lastSearchData.filters || [],
      sortKey,
      sortDirection
    );

    setPage(0);
  };

  const unselectRowsAndReloadPages = () => {
    const { sortKey, sortDirection } = getLastSorConfigParams();

    setSelectedRows([]);
    getEvents(
      getStatus(),
      lastSearchData.page || 0,
      lastSearchData.filters || [],
      sortKey,
      sortDirection,
      true
    );
  };

  useToastrForError(archiveEventsError);

  const previousArchiveEventsLoading = usePrevious(archiveEventsLoading);

  useEffect(() => {
    if (
      !!previousArchiveEventsLoading &&
      previousArchiveEventsLoading !== archiveEventsLoading &&
      !archiveEventsLoading &&
      !archiveEventsError
    ) {
      toastrSuccess(
        `${intl.formatMessage({
          id: showArchive ? 'toast_restored' : 'toast_archived',
        })}`
      );
      unselectRowsAndReloadPages();
    }
  }, [
    previousArchiveEventsLoading,
    archiveEventsLoading,
    archiveEventsError,
    showArchive,
  ]);

  const onArchivePressed = () => {
    const items = selectedRows.map((selectedRow) => selectedRow.id);
    archiveEvents(items, !showArchive);
  };

  const onAddPressed = () => {
    navigate(`/${EVENTS_ROUTE_KEY}/new/wizard`);
  };

  useToastrForError(eventLoadingError);
  useToastrForError(multiEventUpdate.error);

  const tabBarElements = [
    <FormattedMessage id="tab_events" />,
    <FormattedMessage id="tab_inactive_events" />,
    <FormattedMessage id="tab_event_archive" />,
  ];

  const { write } = useAuthorizationCheckRWD(
    UserPrivilegePermission.EVENT,
    ApplicationCategory.SHANNON
  );

  const importModal = (
    <EventDefinitionImportModal
      checkImportStatus={checkImportStatus}
      confirmImport={confirmImport}
      resetImport={resetImport}
      resetImportStatus={resetImportStatus}
      startImport={startImport}
      importId={eventImport?.importId ?? null}
      importInProgress={eventImportInProgress}
      importError={eventImportError}
      importStatusData={eventImportStatus}
      importStatusInProgress={eventImportStatusInProgress}
      importStatusError={eventImportStatusError}
      confirmImportData={eventImportConfirmation}
      confirmImportInProgress={eventImportConfirmationInProgress}
      confirmImportError={eventImportConfirmationError}
      triggerButton={
        <Button icon="new" type="secondary" label="import" disabled={!write} />
      }
      triggerDisabled={!write}
    />
  );

  return (
    <div style={{ backgroundColor: '#f7f7f7' }}>
      <Header
        title={CONFIG.label ?? ''}
        backButtonTitle={ShannonConfig.Dashboard.label}
        onBackPressed={() => navigate('/', { replace: true })}
      />
      <ScreenToolbar></ScreenToolbar>
      <FullWidthCard>
        <TableContainer>
          <SortableTable
            contentOverHeader={(contentSticky) => (
              <TableHeaderContainer contentSticky={contentSticky}>
                <TabBar
                  titles={tabBarElements}
                  tabIndex={activeTab}
                  onSelectedIndexChange={onTabChanged}
                />
                <EventActionButtons
                  onEditPressed={() => setBatchEditActive(true)}
                  onArchivePressed={onArchivePressed}
                  onAddPressed={onAddPressed}
                  editDisabled={selectedRows.length === 0}
                  archiveEnabled={!!selectedRows.length}
                  archiveShown={showArchive}
                  importModal={importModal}
                  allowedToModify={write}
                />
              </TableHeaderContainer>
            )}
            stickyHeaderOffset={72}
            dataLength={events.length}
            hasMoreResults={moreEventsCanBeLoaded}
            columns={createColumns(
              intl,
              eventOccurrenceCountDuration,
              manufacturingEntities,
              getAllManufacturingEntities,
              manufacturingEntitiesMeta.loadingInProgress
            )}
            getNextResults={getNextEvents}
            initialSearchData={initialSearchData}
            loadingInProgress={eventLoadingInProgress}
            onAllRowsCheckboxChanged={(checked) =>
              setSelectedRows(checked ? [...events] : [])
            }
            page={page}
            openFiltersOnMount
            totalElements={eventsTotalElements}
          >
            {events.map((event) => {
              return (
                <EventRow
                  data={event}
                  onRowChecked={(checked) => selectRow(event, checked)}
                  selected={isRowSelected(event)}
                  showManufacturingEntityHierarchy={!!showHierarchy}
                />
              );
            })}
          </SortableTable>
        </TableContainer>
        {batchEditActive && (
          <BatchEdit
            selectedEvents={selectedRows}
            batchEditCallback={batchEditCallBack}
            initialArchive={activeTab == 2 ? true : false}
            selectedQuantity={selectedRows.length}
            manufacturingEntities={manufacturingEntities}
            manufacturingEntitiesMeta={manufacturingEntitiesMeta}
          ></BatchEdit>
        )}
      </FullWidthCard>
    </div>
  );
};

export default Events;
