import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import DarwinConfig from '@dev/base-web/dist/model/domain/authorization/darwin';
import {
  EventInterface,
  EventStatusType,
  EventType,
} from '@dev/base-web/dist/model/domain/event/event_type';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { TerminatedMarker } from '../../../../components/Card/EventCard/components/terminated_marker';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import { Row } from '@dev/base-web/dist/view/components/global/styled_components';
import { Popup } from 'semantic-ui-react';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';

const REDIRECT_PATH_FOR_RECOMMENDATIONS_TEMPLATE = (recommendationId: string) =>
  `${
    (DarwinConfig.Recipes.url?.baseUrl || '') +
    (DarwinConfig.Recipes.url?.path || '')
  }/recommendations/${recommendationId}`;

const ItemContainer = styled.div<{
  readonly itemSelected?: boolean;
  readonly itemUnselectable?: boolean;
  readonly borderRadius?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 8px 8px 0px;
  margin: 2px 0 4px;
  cursor: ${({ itemUnselectable }) =>
    itemUnselectable ? 'default' : 'pointer'};
  border-bottom: 1px solid #efefef;
  border-radius: ${({ borderRadius }) => borderRadius || 6}px;
  background: ${({ itemSelected }) =>
    itemSelected ? 'rgba(124, 128, 127, 0.08)' : 'transparent'};

  &:hover,
  &:active {
    background-color: ${({ itemUnselectable }) =>
      itemUnselectable ? 'transparent' : 'rgba(124, 128, 127, 0.04)'};
  }
`;

const ShortRow = styled(Row)`
  justify-content: flex-start;
  max-width: calc(100% - 30px);
`;

const StyledRow = styled(Row)`
  justify-content: flex-start;
  max-width: 100%;
  padding: 4px 0;
  gap: 5px;
`;

const Description = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.38;
  padding-left: 8px;
  max-width: 100%;
`;

const DescriptionGrey = styled(Description)`
  color: #808080;
`;

const Place = styled.span<{ disabled?: boolean }>`
  font-size: 14px;
  line-height: 1.14;
  margin-left: 8px;
  color: ${({ disabled, theme }) =>
    disabled ? '#808080' : theme.colors.black};
`;

const InlineSeparator = styled(Place)`
  margin: 0 4px;
`;

const SolutionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: -8px;
`;

const StyledIcon = styled(Icon)`
  margin-top: 6px;
`;

const LeftColumn = styled.div`
  width: 100%;
`;

const RightColumn = styled.div``;

interface Props {
  readonly item: EventInterface;
  readonly selected?: boolean;
  readonly onClick: (id: string) => void;
  readonly borderRadius?: number;
  readonly unselectable?: boolean;
}

const CurrentEventItem = ({
  item,
  selected,
  onClick,
  borderRadius,
  unselectable,
}: Props) => {
  const { formatDate } = useLocalizedDateFormatters();
  const intl = useIntl();
  const theme = useTheme();
  const { color, icon } = useEventTypeLook(item.type);

  const onItemSelected = () => {
    if (item.type === EventType.RECOMMENDATION && item.recommendationId) {
      window.location.assign(
        REDIRECT_PATH_FOR_RECOMMENDATIONS_TEMPLATE(item.recommendationId)
      );
    } else {
      onClick(item.id);
    }
  };

  const eventName = item.name ? intl.formatMessage({ id: item.name }) : '-';

  return (
    <ItemContainer
      onClick={unselectable ? () => {} : onItemSelected}
      itemSelected={selected}
      borderRadius={borderRadius}
      itemUnselectable={unselectable}
    >
      <LeftColumn>
        <Row style={{ justifyContent: 'space-between' }}>
          <ShortRow>
            <div
              style={{
                width: '16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: 4,
              }}
            >
              <Icon size={16} color={color} name={icon} />
            </div>
            {item.hasFeedback ? (
              <DescriptionGrey>
                <TextWithEllipsisAndTooltip text={eventName} lines={2} />
              </DescriptionGrey>
            ) : (
              <Description>
                <TextWithEllipsisAndTooltip text={eventName} lines={2} />
              </Description>
            )}
          </ShortRow>
        </Row>
        <StyledRow>
          <Icon size={16} color={theme.colors.brownGrey} name="location" />
          <Place disabled={item.hasFeedback}>
            <ManufacturingEntityHierarchyElement
              manufacturingEntity={item.manufacturingEntity}
              hideHiddenTypes
              hideTooltip
              showShortNames
              hiddenTypes={[
                ManufacturingEntityType.FACTORY,
                ManufacturingEntityType.AREA,
                ManufacturingEntityType.LINE,
              ]}
            />
          </Place>
        </StyledRow>
        <StyledRow>
          <Icon size={16} color={theme.colors.brownGrey} name="time" />
          {item.timestamp && (
            <Place disabled={item.hasFeedback}>
              {formatDate(item.timestamp, 'P p')}
            </Place>
          )}
          {item.status === EventStatusType.TERMINATED &&
            !item.isClosedByUser && (
              <>
                <InlineSeparator disabled={item.hasFeedback}>
                  {' '}
                  -{' '}
                </InlineSeparator>
                <TerminatedMarker disabled={item.hasFeedback} />
              </>
            )}
        </StyledRow>
      </LeftColumn>
      <RightColumn>
        <SolutionIconContainer>
          {item.hasSolution ? (
            <Popup
              trigger={
                <div>
                  <Icon
                    size={16}
                    color={theme.colors.brownGrey}
                    name="filter"
                  />
                </div>
              }
            >
              <Popup.Content>
                <FormattedMessage id={'event_has_solution_hint'} />
              </Popup.Content>
            </Popup>
          ) : (
            <div style={{ width: 16, height: 16 }}></div>
          )}
          {(item.hasSubsequentEvents || item.isSubsequentEvent) && (
            <Popup
              trigger={
                <div>
                  <StyledIcon
                    size={16}
                    color={
                      item.isSubsequentEvent
                        ? theme.colors.primary
                        : theme.colors.brownGrey
                    }
                    name="tree"
                  />
                </div>
              }
            >
              <Popup.Content>
                <FormattedMessage
                  id={
                    item.isSubsequentEvent
                      ? 'subsequent_event'
                      : 'event_has_subsequent_events_hint'
                  }
                />
              </Popup.Content>
            </Popup>
          )}
        </SolutionIconContainer>
      </RightColumn>
    </ItemContainer>
  );
};

export default CurrentEventItem;
