import EventDetail from '@/model/domain/event/event.ts';
import { EventDefinition } from '@/model/domain/event/event_definition.ts';

export const getLocations = (item: EventDetail | EventDefinition) => {
  if ('rules' in item && item.rules) {
    const locationsSet = new Set(
      item.rules
        .filter((rule) => rule.manufacturingEntity)
        .map(
          (rule) =>
            rule.manufacturingEntity?.name ?? 'manufacturingEntity IS defined'
        )
    );

    return [...locationsSet].join(', ');
  } else {
    return undefined;
  }
};

export const getManufacturingEntity = (item: EventDetail | EventDefinition) => {
  if ('rules' in item && item.rules && item.rules.length === 1) {
    return item.rules[0].manufacturingEntity;
  } else {
    return undefined;
  }
};
