import { Checkbox } from '@dev/base-web/dist/view/components/global/checkbox';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import React from 'react';
import Event from '../../../../../model/domain/event/event_definition';
import { StyledRightColumn } from '../../../../../components/StyledComponents';
import { useNavigate } from 'react-router';
import { EVENTS_ROUTE_KEY } from '../../../../events/interfaces';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  CenterAlignedTableCell,
  CheckboxCell,
  LeftAlignedTableCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import SimpleKPI from '@dev/base-web/dist/view/components/kpi/simple_kpi';

const EventName = styled.div`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #242424;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledEventRow = styled(StyledRow)`
  height: 64px;
`;

interface EventRowProps {
  data: Event;
  onRowChecked?: (selected: boolean) => void;
  selected?: boolean;
}

const EventRow: React.FC<EventRowProps> = ({
  data,
  selected,
  onRowChecked,
}) => {
  const { id, name, type, actionCount, manufacturingEntities, modifiedDate } =
    data;
  const navigate = useNavigate();
  const intl = useIntl();
  const { color, icon } = useEventTypeLook(type);
  const { formatDate } = useLocalizedDateFormatters();
  const locationSet = new Set(
    manufacturingEntities.map((entity) => entity.name)
  );
  const locations = [...locationSet].join(', ');

  const onActionChecked = (event: React.FormEvent<HTMLInputElement>) => {
    if (onRowChecked) {
      onRowChecked(!selected);
    }
    event.stopPropagation();
  };

  const onRowSelected = () => {
    navigate(`/${EVENTS_ROUTE_KEY}/${id}`);
  };

  return (
    <StyledEventRow onClick={onRowSelected}>
      {onRowChecked && (
        <CheckboxCell>
          <Checkbox onChange={onActionChecked} checked={selected} />
        </CheckboxCell>
      )}
      <CenterAlignedTableCell>
        <Icon name={icon} color={color} size={14} />
      </CenterAlignedTableCell>
      <LeftAlignedTableCell>
        <EventName>
          <TextWithEllipsisAndTooltip
            text={intl.formatMessage({ id: name })}
            lines={2}
            style={{ width: 'calc(100% - 32px)' }}
          />
        </EventName>
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <TextWithEllipsisAndTooltip
          text={locations}
          lines={2}
        ></TextWithEllipsisAndTooltip>
      </LeftAlignedTableCell>
      <CenterAlignedTableCell>
        {/*TODO: for now remove showBorder as overlapping with table cell*/}
        <SimpleKPI value={actionCount} small />
      </CenterAlignedTableCell>
      <LeftAlignedTableCell>
        {formatDate(modifiedDate, 'P, p')}
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <StyledRightColumn>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledRightColumn>
      </LeftAlignedTableCell>
    </StyledEventRow>
  );
};

export default EventRow;
