import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { LoadType } from '@dev/base-web/dist/model/redux/events/interface';
import { RootReducerInterface } from '../../../model/redux/interfaces';
import eventsThunks from '../../../model/redux/events/thunks';
import { Card } from '@dev/base-web/dist/view/components/global/card';
import { EventInterface } from '@dev/base-web/dist/model/domain/event/event_type';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import CardEventsList from '../../current_events/events_list_card/components/events_list';
import { checkLocalStorage } from '../../../components/tour/Tour';
import { CONFIG as CURRENT_EVENTS_CONFIG } from '../../current_events';
import ManufacturingEntity from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';

const CURRENT_EVENTS_LIST_FOOTER_TOP_PADDING = 5;
const CURRENT_EVENTS_LIST_FOOTER_BOTTOM_PADDING = 8;
const CURRENT_EVENTS_LIST_LIST_HEIGHT = 406;

// 72px for the card title and 40px for the "show all events" button height.
export const CURRENT_EVENTS_LIST_CARD_HEIGHT =
  72 +
  CURRENT_EVENTS_LIST_LIST_HEIGHT +
  CURRENT_EVENTS_LIST_FOOTER_TOP_PADDING +
  40 +
  CURRENT_EVENTS_LIST_FOOTER_BOTTOM_PADDING;

const Footer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-top: ${CURRENT_EVENTS_LIST_FOOTER_TOP_PADDING}px;
  padding-bottom: ${CURRENT_EVENTS_LIST_FOOTER_BOTTOM_PADDING}px;
  justify-content: center;
`;

interface CurrentEventsStoreProps {
  readonly events: readonly EventInterface[];
  readonly hasMoreResults: boolean;
  readonly loadingInProgress: LoadType | undefined;
  readonly loadError: any;
}

interface CurrentEventsExternalProps {
  readonly tourActive: boolean;
  readonly selectedEntity?: ManufacturingEntity;
}

interface CurrentEventsDispatchProps {
  readonly getActiveEvents: (filters: readonly FilterData[]) => void;
  readonly reloadActiveEvents: (filters: readonly FilterData[]) => void;
}

type CurrentEventsProps = CurrentEventsStoreProps &
  CurrentEventsDispatchProps &
  CurrentEventsExternalProps;

const CurrentEventsList = ({
  events,
  hasMoreResults,
  loadingInProgress,
  loadError,
  getActiveEvents,
  reloadActiveEvents,
  tourActive,
  selectedEntity,
}: CurrentEventsProps) => {
  const navigate = useNavigate();

  const filters = useMemo(() => {
    return selectedEntity
      ? [
          new FilterData(
            'manufacturingEntity.id',
            FilterOperation.EQ_OPERATOR,
            selectedEntity.id
          ),
        ]
      : [];
  }, [selectedEntity]);

  // only load events when selected entity is initially set (not undefined anymore)
  function getActiveEventsLocal(externalFilters: readonly FilterData[]) {
    if (selectedEntity !== undefined) getActiveEvents(externalFilters);
  }

  function reloadActiveEventsLocal(externalFilters: readonly FilterData[]) {
    if (selectedEntity !== undefined) reloadActiveEvents(externalFilters);
  }

  return (
    <Card titleId="tab_curr_events" margin="none">
      <CardEventsList
        tourActive={tourActive}
        height={CURRENT_EVENTS_LIST_LIST_HEIGHT}
        bottomGradientHeight={64}
        filters={filters}
        events={events}
        hasMoreResults={hasMoreResults}
        loadingInProgress={loadingInProgress}
        loadError={loadError}
        loadEvents={getActiveEventsLocal}
        reloadEvents={reloadActiveEventsLocal}
        onEventSelect={(eventId) => {
          if (checkLocalStorage('tour-dashboard')) {
            localStorage.setItem('tour-dashboard', '4');
          }
          navigate({
            pathname: `/${CURRENT_EVENTS_CONFIG.url.path}/${eventId}`,
          });
        }}
      />
      <Footer>
        <Button
          type="tertiary"
          label="show_all_event"
          onClick={() =>
            navigate({
              pathname: `/${CURRENT_EVENTS_CONFIG.url.path}`,
            })
          }
        />
      </Footer>
    </Card>
  );
};

const mapStateToProps = (
  state: RootReducerInterface
): CurrentEventsStoreProps => ({
  events: state.data.eventsState.activeEvents.data.results,
  hasMoreResults: state.data.eventsState.activeEvents.data.hasMoreResults,
  loadingInProgress: state.data.eventsState.activeEvents.meta.loadingInProgress,
  loadError: state.data.eventsState.activeEvents.meta.error,
});

export default connect(mapStateToProps, {
  getActiveEvents: eventsThunks.getActiveEvents,
  reloadActiveEvents: eventsThunks.reloadActiveEvents,
})(CurrentEventsList);
