import React, { useEffect, useMemo, useState } from 'react';
import { EventWizardProps } from './interface';
import { Grid } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import EventCard from '../../components/Card/EventCard';
import {
  ClosingConditionType,
  EventDefinition,
  EventDefinitionAdd,
  EventRule,
  EventRuleType,
  IterationType,
  OpeningConditionType,
} from '@/model/domain/event/event_definition.ts';
import { EventType } from '@dev/base-web/dist/model/domain/event/event_type';
import styled from 'styled-components';
import WizardSteps from './components/wizard_steps';
import EventDescription from './components/event_description';
import ManufacturingEntity, {
  findManufacturingEntities,
} from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import EventRulesConfig from './components/event_rules_config';
import SubscribersWizard from './components/subscribers';
import { HeaderButtons } from '../../components/StyledComponents';
import { useNavigate, useParams } from 'react-router-dom';
import { CONFIG as EVENTS_CONFIG, CONFIG } from '../events/interfaces';
import {
  areClosingConditionsValid,
  areOpeningConditionsValid,
  isDescriptionValid,
} from './components/event_validator';
import { DurationUnit } from '@dev/base-web/dist/model/domain/durations/duration';
import {
  ScreenContent,
  ScreenRoot,
} from '@dev/base-web/dist/view/components/global/styled_components';
import { FeatureType } from '@dev/base-web/dist/model/domain/feature/feature_configuration';
import AddItems from '../../components/overlays/addItems';
import {
  useNotificationForOperation,
  useNotificationForOperationError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import Loader from '@dev/base-web/dist/view/components/global/loader';
import DeleteModal from './components/delete_modal';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import ChangeTypeModal from './components/type_change_modal';
import VerificationModal from './components/verification_modal';
import { SignalStatistic } from '@dev/base-web/dist/model/domain/plc/plc_signal';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import Header from '@dev/base-web/dist/view/components/global/header_view';
import { useStringSearchState } from '@dev/base-web/dist/view/components/global/url_param_hooks';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';

const CenteredTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 36px;
`;

export interface TabStatus {
  isActive: boolean;
  isValid: boolean;
}

const newEvent: EventDefinition = {
  id: '',
  type: EventType.INFORMATION,
  name: '',
  description: '',
  //actionCount: 0,
  //occurrenceCount: 0,
  //commentCount: 0,
  //manufacturingEntities: [],
  rules: [],
  isCommon: false,
  subscribers: [],
  isHidden: false,
  isInternal: false,
  excludeFromStats: false,
  actions: [],
  comments: [],
  translations: [],
};

const NEW_EVENT_KEY = 'new';

const EventWizard: React.FC<EventWizardProps> = ({
  getEvent,
  event,
  getAllManufacturingEntities,
  manufacturingEntities,
  manufacturingEntitiesLoading,
  featureConfiguration,
  signals,
  signalsMeta,
  moreSignalsCanBeLoaded,
  getConfiguration,
  getSignalsForManufacturingEntity,
  getSignalsForPlc,
  getSignals,
  //feature,
  createEvent,
  createFeature,
  selfUser,
  computeFeatureValues,
  clearFeatureValues,
  featureValues,
  featureValuesLoading,
  featureValuesError,
  eventCreation,
  updateEvent,
  // removeCommentFromEvent,
  removeInstructionFromEvent,
  addInstructionToEvent,
  instructionRemovedFromEvent,
  updateEvents,
  eventOperationState,
  featureCreation,
  updateFeature,
  commentRemovedFromEvent,
  getFeatureTemplates,
  featureTemplateState,
  configs,
  cleanInstruction,
}: EventWizardProps) => {
  const intl = useIntl();
  const { id } = useParams() as { id: string };

  useEffect(() => {}, []);

  useEffect(() => {
    getAllManufacturingEntities();
  }, [getAllManufacturingEntities]);

  useEffect(() => {
    getConfiguration();
  }, [getConfiguration]);

  const [manufact, setManufact] = useState<readonly string[]>([]);

  const [savedEvent, setSavedEvent] = useState<string>('');

  const [item, setItem] = useState<EventDefinition>(newEvent);
  const [showAddInstructionModal, setShowAddInstructionModal] =
    useState<boolean>(false);
  const [removableInstructionId, setRemovableInstructionId] =
    useState<string>();
  const [modalManufacturingEntity, setModalManufacturingEntity] = useState<
    ManufacturingEntity | undefined
  >(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [typeChangeModalOpen, setTypeChangeModalOpen] =
    useState<boolean>(false);
  const [rulesChanged, setRulesChanged] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [selectedRuleId, setSelectedRuleId] = useStringSearchState({
    key: 'ruleId',
  });

  useEffect(() => {
    const tab = new URLSearchParams(location.search).get('tab');
    if (tab === '0' || tab === '1' || tab === '2' || tab === '3') {
      const activeTab = Number(tab);
      setTab(activeTab);
    }
  }, []);

  useEffect(() => {
    if (id !== NEW_EVENT_KEY) {
      getEvent(id);
    } else {
      setItem(newEvent);
      setManufact([]);
    }
  }, [id]);

  const prevEventOperation = usePrevious(eventOperationState);

  useEffect(() => {
    if (event && prevEventOperation && prevEventOperation.loadingInProgress) {
      setItem(event);

      const manus: string[] = event.rules
        .map((r) => r.manufacturingEntity?.id)
        .filter((item): item is string => !!item);

      setManufact(manus);
    }
  }, [event, manufacturingEntities]);

  useEffect(() => {
    const newManufacturingEntityId = new URLSearchParams(location.search).get(
      'newManufacturingEntity'
    );

    if (
      newManufacturingEntityId &&
      newManufacturingEntityId !== '' &&
      !manufact.includes(newManufacturingEntityId)
    ) {
      onManufacturingEntitySelected([...manufact, newManufacturingEntityId]);
    }
  }, [item, manufacturingEntities, id]);

  useEffect(() => {
    if (
      !eventCreation.operationInProgress &&
      !eventCreation.error &&
      savedEvent &&
      savedEvent !== ''
    ) {
      navigate(`${EVENTS_CONFIG.url.path}/${savedEvent}`, { replace: true });
    }
  }, [savedEvent]);

  // useEffect(() => {
  //   item.rules.forEach((rule) => {
  //     if (
  //       (rule &&
  //         rule.id &&
  //         selectedRuleId &&
  //         rule.id === selectedRuleId) ||
  //       (rule &&
  //         rule.temporaryId &&
  //         selectedRuleId &&
  //         rule.temporaryId === selectedRuleId)
  //     ) {
  //       setSelectedRuleId(rule.id);
  //     }
  //   });
  // }, [item.rules]);

  useEffect(() => {
    if (
      !selectedRuleId &&
      item.rules.length > 0 &&
      item.rules[0].manufacturingEntity
    ) {
      setSelectedRuleId(item.rules[0].id);
    }
  }, [item.rules]);

  const initialOpenCondition = {
    type: 'FEATURE',
    value: undefined,
    timestamp: Date.now(),
    iterationType: IterationType.WEEKLY,
    iteration: 0,
  };

  const initialCloseCondition = {
    type: 'FEATURE',
    value: undefined,
    duration: 5,
    unit: DurationUnit.MINUTES,
    onClose: true,
  };

  const [tabs, setTabs] = useState<{ [id: string]: TabStatus }>({
    description: {
      isActive: false,
      isValid: false,
    },
    trigger: {
      isActive: false,
      isValid: false,
    },
    termination: {
      isActive: false,
      isValid: false,
    },
    notifications: {
      isActive: false,
      isValid: false,
    },
  });

  const checkValidity = (event: EventDefinition) => {
    const newTabs = tabs;
    newTabs['description'].isValid = isDescriptionValid(event);
    newTabs['notifications'].isValid = true;
    newTabs['trigger'].isValid = areOpeningConditionsValid(
      featureConfiguration,
      event
    );
    newTabs['termination'].isValid = areClosingConditionsValid(
      featureConfiguration,
      event
    );
    setTabs({ ...newTabs });
  };

  useEffect(() => {
    checkValidity(item);
  }, [item]);

  const navigate = useNavigate();

  const handleOpenModal = (manEnt: ManufacturingEntity) => {
    setModalManufacturingEntity(manEnt);

    setModalOpen(true);
  };

  const handleDelete = () => {
    const newRules = item.rules.filter((rule) => {
      return (
        rule &&
        rule.manufacturingEntity &&
        rule.manufacturingEntity !== modalManufacturingEntity
      );
    });
    ruleCallback([...newRules]);
    setModalOpen(false);
  };

  const handleChange = (newMan: ManufacturingEntity) => {
    const newRules = item.rules.map((rule) => {
      if (!rule.manufacturingEntity) return;
      if (rule.manufacturingEntity !== modalManufacturingEntity) return rule;
      return { ...rule, manufacturingEntity: newMan };
    });

    ruleCallback([...newRules]);
    setModalOpen(false);
  };

  const updateOrCreateFeature = async (
    type: string,
    eventName: string,
    condition: any,
    manufacturingEntity: ManufacturingEntity
  ) => {
    const tempFeature = {
      type: FeatureType.MESSAGE,
      name: type + '_' + eventName,
      description: type,
      manufacturingEntityId: manufacturingEntity.id,
      value: { ...condition.value },
    };

    // check if it's an update
    if (condition.featureId && condition.featureId !== '') {
      return await updateFeature(condition.featureId, tempFeature);
    } else {
      return await createFeature(tempFeature);
    }
  };

  const formatConditions = async (
    type: string,
    condition: any,
    manufacturingEntity: ManufacturingEntity
  ) => {
    if (condition.type === 'FEATURE') {
      const newFeature = await updateOrCreateFeature(
        type,
        item.name,
        condition,
        manufacturingEntity
      );
      return { type: 'FEATURE', featureId: newFeature ? newFeature.id : '' };
    }
    if (condition.type === OpeningConditionType.SCHEDULED) {
      return {
        type: OpeningConditionType.SCHEDULED,
        timestamp: condition.timestamp,
        iterationType: condition.iterationType,
        iteration: condition.iteration,
      };
    }
    if (condition.type === ClosingConditionType.TIMER) {
      return {
        type: ClosingConditionType.TIMER,
        duration: condition.duration,
        unit: condition.unit,
      };
    }
    if (condition.type === ClosingConditionType.USER_ACTION) {
      return { type: ClosingConditionType.USER_ACTION, onClose: true };
    }
  };

  const getRulesChangedForEvent = async () => {
    if (rulesChanged || !event) {
      const rules = item.rules;

      return await Promise.all(
        rules.map(async (rule) => {
          const newRule: any = {};

          if (rule.manufacturingEntity) {
            newRule.manufacturingEntity = rule.manufacturingEntity;
            newRule.open = await formatConditions(
              'open',
              rule.open,
              rule.manufacturingEntity
            );
            newRule.close = await formatConditions(
              'close',
              rule.close,
              rule.manufacturingEntity
            );
          }
          newRule.id = rule.id;
          newRule.type = EventRuleType.STANDARD;

          return newRule;
        })
      );
    } else {
      return event.rules;
    }
  };

  const sendEvent = async () => {
    const newItem: EventDefinitionAdd = {
      id: item.id,
      name: item.name,
      type: item.type,
      description: item.description,
      isHidden: item.isHidden,
      isCommon: item.isCommon,
      excludeFromStats: item.excludeFromStats,
      rules: await getRulesChangedForEvent(),
      subscribers: item.subscribers.map((s) => s.id),
      actions: item.actions.map((a) => a.id),
      translations: [],
    };

    const newEvent = await (item.id === ''
      ? createEvent(newItem)
      : updateEvents([item.id], newItem));

    if (newEvent && 'id' in newEvent) {
      setSavedEvent(newEvent.id);
    }
  };

  const ruleCallback = (rules: any) => {
    const equals = _.isEqual(item.rules, rules);
    setItem({ ...item, rules });
    if ((tab == 0 || tab === 1 || tab === 2) && !equals) {
      setRulesChanged(true);
    }
  };

  const deleteManufacturingEntity = (ids: readonly string[]) => {
    const ruleToDelete = item.rules.filter(
      (rule) =>
        !(rule.manufacturingEntity && ids.includes(rule.manufacturingEntity.id))
    );

    setModalManufacturingEntity(ruleToDelete[0].manufacturingEntity);
    setModalOpen(true);
  };

  const extractManufacturingEntityIdsFromRules = (
    rules: readonly EventRule[]
  ) =>
    rules
      .map((rule) => rule.manufacturingEntity?.id)
      .filter((item): item is string => !!item);

  const onManufacturingEntitySelected = (ids: readonly string[]) => {
    if (item.rules.length < ids.length) {
      const oldIds = extractManufacturingEntityIdsFromRules(item.rules);
      const newIds = ids.filter((id) => !oldIds.includes(id));

      const newEntities = findManufacturingEntities(
        manufacturingEntities,
        (e) => newIds.includes(e.id)
      );

      const newRules: any[] = newEntities.map((item) => ({
        temporaryId: uuidv4(), // Used to identify the rules even if the manufacturing entity changes
        type: EventRuleType.STANDARD,
        manufacturingEntity: item,
        open: initialOpenCondition,
        close: initialCloseCondition,
      }));

      if (ids.length && ids[0]) {
        setItem({ ...item, rules: item.rules.concat(newRules) });

        if (newRules.length === 1) {
          setSelectedRuleId(newRules[0].temporaryId);
        }
      } else {
        setItem({ ...item, rules: [] });
      }
    }

    if (item.rules.length > ids.length) {
      deleteManufacturingEntity(ids);
    }
  };

  const swapButton = (type: EventType) => {
    //this.reportUnsavedChange();

    if (
      item.type === EventType.TASK &&
      item.rules.some(
        (rule) =>
          rule.close && rule.close.type === ClosingConditionType.USER_ACTION
      )
    ) {
      setTypeChangeModalOpen(true);
    }

    setItem({ ...item, type });
  };

  const onSubscribeToEvent = () => {
    if (selfUser != null && !item.subscribers.some((s) => s.id === selfUser.id))
      setItem({ ...item, subscribers: [...item.subscribers, selfUser] });
  };

  const onUnsubscribeFromEvent = () => {
    if (selfUser != null) {
      const index = item.subscribers.findIndex(
        (elem) => elem.id === selfUser.id,
        0
      );

      if (index > -1) {
        const copySubs = [...item.subscribers];

        copySubs.splice(index, 1);

        setItem({ ...item, subscribers: [...copySubs] });
      }
    }
  };

  const removeInstruction = (id: string) => {
    if (item.id !== '') {
      removeInstructionFromEvent(item.id, id);
      setRemovableInstructionId(id);
    }
  };

  const handleTypeChangeFromTask = () => {
    const copyRules = item.rules.map((rule) => {
      if (rule.close && rule.close.type === ClosingConditionType.USER_ACTION) {
        return {
          ...rule,
          close: { ...rule.close, type: ClosingConditionType.FEATURE },
        };
      } else return rule;
    });

    setItem({ ...item, rules: copyRules });
    setTab(2);
    setTypeChangeModalOpen(false);
  };

  const handleCancelTypeChange = () => {
    setItem({ ...item, type: EventType.TASK });
    setTypeChangeModalOpen(false);
  };

  const removeInstructionFromListAfterRemoveSuccess = () => {
    setItem({
      ...item,
      actions: item.actions.filter((e) => e.id !== removableInstructionId),
    });
  };

  useNotificationForOperation(
    instructionRemovedFromEvent,
    'action_removed_success',
    'action_removed_success',
    'action_removed_success',
    intl,
    () => removeInstructionFromListAfterRemoveSuccess()
  );

  useNotificationForOperation(
    commentRemovedFromEvent,
    'comment_removed_success',
    'comment_removed_success',
    'comment_removed_success',
    intl
  );

  useNotificationForOperation(
    eventCreation,
    'event_created_success',
    'event_created_success',
    'event_created_success',
    intl
  );

  useNotificationForOperation(
    updateEvent,
    'event_updated_success',
    'event_updated_success',
    'event_updated_success',
    intl,
    () => navigate(`${CONFIG.url?.path}/${id}`)
  );

  const [openFeatureValues, setOpenFeatureValues] = useState<SignalStatistic[]>(
    []
  );

  useEffect(() => {
    if (tab === 1) {
      setOpenFeatureValues(featureValues);
    }
  }, [featureValues, tab]);

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  useNotificationForOperationError(featureCreation, intl, 'feature_save_error');
  useNotificationForOperationError(updateEvent, intl, 'feature_save_error');

  const ROUTE_TEMPLATE = (tab?: number) => {
    const queryParams: string[] = [];
    if (tab !== undefined) {
      queryParams.push(`tab=${tab}`);
    }
    return `${CONFIG.url?.path}/${id}/wizard?${queryParams.join('&')}`;
  };

  const changeTab = (tabIndex: number) => {
    setTab(tabIndex);
    navigate(ROUTE_TEMPLATE(tabIndex));
  };

  const areValuesValid =
    Object.entries(tabs).filter((value) => !value[1].isValid).length === 0;

  const isNew = id === NEW_EVENT_KEY;

  const isLoading =
    eventCreation.operationInProgress ||
    featureCreation.operationInProgress ||
    eventOperationState.loadingInProgress ||
    updateEvent.operationInProgress;

  const { write } = useAuthorizationCheckRWD(
    UserPrivilegePermission.EVENT,
    ApplicationCategory.SHANNON
  );

  const isSaveDisabled = useMemo(() => {
    return !areValuesValid || !write || isLoading || _.isEqual(item, event);
  }, [areValuesValid, write, isLoading, item, event]);

  return (
    <DndProvider backend={HTML5Backend} context={HTML5Backend}>
      <ScreenRoot>
        <Header
          title={isNew ? 'add_new_event' : 'edit_event_definition'}
          titleValues={{ eventName: event ? event.name : '' }}
          backButtonTitle="category_event_config"
          onBackPressed={() =>
            navigate(EVENTS_CONFIG.url.path, { replace: true })
          }
        >
          <HeaderButtons>
            <Button
              type="primary"
              label={'save'}
              disabled={isSaveDisabled}
              onClick={() => {
                if (
                  rulesChanged &&
                  tab !== 0 &&
                  item.rules.some(
                    (rule) => rule.open && rule.open.type === 'FEATURE'
                  )
                ) {
                  setShowVerificationModal(true);
                } else {
                  void sendEvent();
                }
              }}
            />
          </HeaderButtons>
        </Header>
        <VerificationModal
          open={showVerificationModal}
          onCancel={() => {
            setShowVerificationModal(false);
          }}
          onOk={() => {
            void sendEvent();
            setShowVerificationModal(false);
          }}
          featureValues={openFeatureValues}
        />
        <DeleteModal
          currentManufacturingEntity={modalManufacturingEntity}
          open={modalOpen}
          closeCallback={() => {
            setModalOpen(false);
          }}
          changeCallback={handleChange}
          deleteCallback={handleDelete}
        ></DeleteModal>
        <ChangeTypeModal
          cancelCallback={handleCancelTypeChange}
          changeCallback={handleTypeChangeFromTask}
          closeCallback={handleCancelTypeChange}
          open={typeChangeModalOpen}
        />
        <ScreenContent>
          <CenteredTabs>
            <WizardSteps
              tabs={tabs}
              tabIndex={tab}
              onSelectedIndexChange={changeTab}
            />
          </CenteredTabs>
          {tab === 0 ? (
            <Grid
              columns={2}
              style={{
                padding: 0,
                marginTop: 0,
                marginLeft: '24px',
                marginRight: '24px',
              }}
            >
              <Grid.Column>
                <EventCard
                  eventDefId={item.id}
                  item={item}
                  removeInstruction={removeInstruction}
                  isLoading={false}
                  currentUser={null}
                  eventId={''}
                  configs={configs}
                  cleanInstruction={cleanInstruction}
                />
              </Grid.Column>
              <Grid.Column style={{ textAlign: 'center' }}>
                <EventDescription
                  selectedEntityIds={extractManufacturingEntityIdsFromRules(
                    item.rules
                  )}
                  item={item}
                  onChange={(e) => {
                    //this.reportUnsavedChange();
                    setItem({ ...item, [e.target.name]: e.target.value });
                  }}
                  swapButton={swapButton}
                  allowedToModify={write}
                  manufacturingEntities={manufacturingEntities}
                  manufacturingEntitiesLoading={manufacturingEntitiesLoading}
                  onManufacturingEntitySelected={onManufacturingEntitySelected}
                />
              </Grid.Column>
            </Grid>
          ) : tab === 1 || tab === 2 ? (
            <>
              <EventRulesConfig
                eventType={item.type}
                ruleCallback={ruleCallback}
                getSignalsForPlc={getSignalsForPlc}
                getSignalsForManufacturingEntity={
                  getSignalsForManufacturingEntity
                }
                getSignals={getSignals}
                signals={signals}
                moreSignalsCanBeLoaded={moreSignalsCanBeLoaded}
                signalsMeta={signalsMeta}
                featureConfiguration={featureConfiguration}
                rules={item.rules}
                stage={tab == 1 ? 'open' : 'close'}
                computeFeatureValues={computeFeatureValues}
                clearFeatureValues={clearFeatureValues}
                featureValues={featureValues}
                featureValuesLoading={featureValuesLoading}
                featureValuesError={featureValuesError}
                handleOpenModal={handleOpenModal}
                getFeatureTemplates={getFeatureTemplates}
                featureTemplateState={featureTemplateState}
                allowedToModify={write}
                selectedRuleId={selectedRuleId}
                setSelectedRuleId={setSelectedRuleId}
              ></EventRulesConfig>
            </>
          ) : (
            tab === 3 && (
              <SubscribersWizard
                allowedToModify={true}
                isCommonNotification={item.isCommon}
                setIsCommonNotification={(isCommon) =>
                  setItem({ ...item, isCommon: isCommon })
                }
                subscribers={item.subscribers}
                selfUser={selfUser}
                subscribeToEvent={onSubscribeToEvent}
                unsubscribeFromEvent={onUnsubscribeFromEvent}
              ></SubscribersWizard>
            )
          )}
          <AddItems
            open={showAddInstructionModal}
            method="action"
            title={intl.formatMessage({ id: 'solutions' })}
            id={item.id}
            add={addInstructionToEvent}
            closeCallback={setShowAddInstructionModal}
            addCallback={addInstructionToEvent}
            items={item ? item.actions : []}
          />
        </ScreenContent>
        {isLoading && <Loader />}
      </ScreenRoot>
    </DndProvider>
  );
};

export default EventWizard;
