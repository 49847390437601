import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import UserDetails from '@dev/base-web/dist/model/domain/user/user_details';
import { ActionDTO } from '../../model/domain/instruction/instruction';
import { useTheme } from '@dev/base-web/dist/view/themes/helpers';
import { Popup } from 'semantic-ui-react';

interface Pending {
  readonly isPending: boolean;
}

const HintText = styled.p`
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  color: #939393;
`;

const InstructionEditContainer = styled.div<Pending>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ isPending }) => (isPending ? 0.5 : 1)};
`;

const LIGHT_GREEN_ITEM = css`
  border: solid 1px #d2edec;
  background-color: #e9f5f5;
`;

const LIGHT_GREY_ITEM = css`
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: rgba(124, 128, 127, 0.12);
`;

const DARK_GREY_ITEM = css`
  border: solid 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(124, 128, 127, 0.24);
`;

const notPendingOrAuthorizedActionListElementStyle = css`
  cursor: pointer;
  &:hover {
    ${LIGHT_GREY_ITEM};
  }
  &:active {
    ${DARK_GREY_ITEM};
  }
`;

const InstructionListElement = styled.div<
  Pending & { isApprovalAuthorized: boolean; isDisabled: boolean }
>`
  display: flex;
  min-height: 40px;
  max-height: 50px;
  border-radius: 6px;
  ${({ isDisabled }) => (isDisabled ? LIGHT_GREY_ITEM : LIGHT_GREEN_ITEM)}
  margin: 0px 0px 6px 0px;
  ${({ isDisabled, isPending, isApprovalAuthorized }) =>
    !isDisabled &&
    !(isPending && !isApprovalAuthorized) &&
    notPendingOrAuthorizedActionListElementStyle}
  padding: 8px 8px 8px 0px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledEndIcon = styled(Icon)`
  width: 32px;
`;

const StyledStartIcon = styled(Icon)`
  width: 32px;
  padding-right: 0px;
`;

const MaintainerIcon = styled(Icon)`
  flex-shrink: 0;
  margin-left: 6px;
`;

const InstructionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: baseline;
`;

const InstructionTitle = styled.div`
  text-align: left;
  white-space: normal;
`;

const PaddingContainer = styled.div`
  padding-left: 6px;
`;

export type DisplayedInstructionFields = Readonly<
  Pick<ActionDTO, 'id' | 'name' | 'isApproved' | 'voteRatio' | 'accessLevel'>
>;

export interface ActionItemContainer {
  readonly instruction: DisplayedInstructionFields;
  readonly disabled?: boolean;
  readonly currentUser: UserDetails | null;
  readonly canDelete?: boolean;
  readonly notEditable: boolean;
  readonly onInstructionSelect: (actionId: string) => void;
  readonly removeInstruction?: (id: string) => void;
}

/**
 *
 * Component to render an ActionItem in an ActionList
 */
const InstructionItem = ({
  instruction,
  disabled = false,
  currentUser,
  canDelete = true,
  notEditable,
  onInstructionSelect,
  removeInstruction,
}: ActionItemContainer) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navToItem = (id: string) => {
    navigate(`/actions/${id}`);
  };

  const approvalAuthorized = currentUser
    ? currentUser.role !== 'OPERATOR'
    : false;

  return (
    <InstructionEditContainer isPending={!instruction.isApproved}>
      <InstructionListElement
        isDisabled={disabled}
        isPending={!instruction.isApproved}
        isApprovalAuthorized={approvalAuthorized}
        onClick={
          disabled
            ? undefined
            : () => {
                if (
                  notEditable &&
                  !(!instruction.isApproved && !approvalAuthorized)
                ) {
                  onInstructionSelect(instruction.id);
                } else if (!notEditable) {
                  navToItem(instruction.id);
                }
              }
        }
      >
        {instruction.voteRatio > 0.7 ? (
          <StyledStartIcon name="star-full" size={16} />
        ) : instruction.voteRatio > 0.4 ? (
          <StyledStartIcon name="star-half-full" size={16} />
        ) : (
          <StyledStartIcon name="star-empty" size={16} />
        )}
        <Popup
          disabled={!disabled}
          trigger={
            <InstructionTitleContainer>
              <InstructionTitle>{instruction.name}</InstructionTitle>
              {instruction.accessLevel !== 'OPERATOR' && (
                <MaintainerIcon
                  name="maintenance"
                  color={theme.colors.primary}
                  size={18}
                />
              )}
            </InstructionTitleContainer>
          }
        >
          <HintText style={{ textAlign: 'left' }}>
            <FormattedMessage id={'only_allowed_for_maintainers'} />
          </HintText>
        </Popup>
        <StyledEndIcon
          name="arrow-right-bold"
          color={theme.colors.warmGrey}
          size={16}
        />
      </InstructionListElement>
      {notEditable || !canDelete ? null : (
        <PaddingContainer>
          <Button
            type="tertiary"
            icon="delete"
            iconColor={theme.colors.brownGrey}
            onClick={
              removeInstruction
                ? () => removeInstruction(instruction.id)
                : undefined
            }
          />
        </PaddingContainer>
      )}
    </InstructionEditContainer>
  );
};

export default InstructionItem;
