import { RootReducerInterface } from '../../../../model/redux/interfaces';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import InstructionsList from '../instructions_list/view';
import { createGetInstructionsThunks } from '../../../../model/redux/instruction_definition/thunks';
import { Actions } from '../../../../model/redux/instruction_definition/actions';
import { InstructionListStoreProps } from '../instructions_list/interfaces';

export const CONFIG = ShannonConfig.Actions;

const mapStateToProps = (
  state: RootReducerInterface
): InstructionListStoreProps => {
  return {
    instructions:
      state.data.instructionDefinitionState.instructionsForDashboardSearch.data
        .results,
    moreInstructionsCanBeLoaded:
      state.data.instructionDefinitionState.instructionsForDashboardSearch.data
        .hasMoreResults,
    instructionLoadingInProgress:
      state.data.instructionDefinitionState.instructionsForDashboardSearch.meta
        .loadingInProgress,
    instructionLoadingError:
      state.data.instructionDefinitionState.instructionsForDashboardSearch.meta
        .error,
    previousInstructionsSearchData:
      state.data.instructionDefinitionState.instructionsForDashboardSearch.meta
        .previousSearchData,
  };
};

export default connect(mapStateToProps, {
  getInstructions: createGetInstructionsThunks(
    Actions.instructionsForDashboardSearch
  ),
})(injectIntl(InstructionsList));
