import { combineReducers, Reducer } from 'redux';
import {
  createReducersForExecutingOperation,
  createReducersForLoadingPagingList,
  createReducersForLoadingSingleItem,
} from '@dev/base-web/dist/model/redux/helpers/reducers';
import {
  ADD_EVENT_TO_INSTRUCTION_KEY,
  EVENTS_KEY,
  REMOVE_INSTRUCTION_FROM_EVENT_KEY,
  UPDATE_EVENT_KEY,
  MULTIUPDATE_EVENT_KEY,
  CREATE_EVENT_KEY,
  Actions,
  EVENT,
  SUBSCRIBE_EVENT_KEY,
  UNSUBSCRIBE_EVENT_KEY,
  EVENTS_FOR_DASHBOARD_SEARCH_KEY,
  INSTRUCTION_SUGGESTION_KEY,
  EVENTS_FOR_ADD_RELATION_KEY,
} from './actions';
import { EventDefinitionStateInterface } from './interface';
import EventInterface, {
  EventDefinition,
} from '../../domain/event/event_definition';
import { ActionType, createReducer } from 'typesafe-actions';

const TAB_ACTION_HANDLERS = {
  SET_ACTIVE_EVENTS_TAB: (
    _state: number,
    action: ActionType<typeof Actions.setActiveTab>
  ): any => {
    return action.payload;
  },
};

const eventState: Reducer<EventDefinitionStateInterface> = combineReducers({
  event: createReducersForLoadingSingleItem<EventDefinition>(EVENT),
  events: createReducersForLoadingPagingList<EventInterface>(EVENTS_KEY),
  eventsForDashboardSearch: createReducersForLoadingPagingList<EventInterface>(
    EVENTS_FOR_DASHBOARD_SEARCH_KEY
  ),
  eventsForAddRelationModal: createReducersForLoadingPagingList<EventInterface>(
    EVENTS_FOR_ADD_RELATION_KEY
  ),
  updateEvent: createReducersForExecutingOperation(UPDATE_EVENT_KEY),
  createEvent: createReducersForExecutingOperation(CREATE_EVENT_KEY),
  multiUpdateEvent: createReducersForExecutingOperation(MULTIUPDATE_EVENT_KEY),
  addEventToInstruction: createReducersForExecutingOperation(
    ADD_EVENT_TO_INSTRUCTION_KEY
  ),
  removeInstructionFromEvent: createReducersForExecutingOperation(
    REMOVE_INSTRUCTION_FROM_EVENT_KEY
  ),
  subscribeToEvent: createReducersForExecutingOperation(SUBSCRIBE_EVENT_KEY),
  unsubscribeFromEvent: createReducersForExecutingOperation(
    UNSUBSCRIBE_EVENT_KEY
  ),
  activeTab: createReducer(0, TAB_ACTION_HANDLERS),
  suggestedInstruction: createReducersForLoadingSingleItem<string>(
    INSTRUCTION_SUGGESTION_KEY
  ),
});

export default eventState;
