import ModalWithHeaderAndTwoButtons from '@dev/base-web/dist/view/components/modal/modal';
import {
  Button,
  CenteredButtons,
} from '@dev/base-web/dist/view/components/global/button';
import { Label, P } from './styled_components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Hint } from '@dev/base-web/dist/view/components/global/hint';
import styled from 'styled-components';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import React, { useEffect, useMemo, useState } from 'react';
import DetailLoader from '@dev/base-web/dist/view/components/global/detail_loader';
import { connect } from 'react-redux';
import { getInstructionSuggestionStream } from '@/model/redux/event_definition/thunks.ts';
import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { LoadingMetaState } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import EventDetail from '../../../../model/domain/event/event';
import { EventDefinition } from '@/model/domain/event/event_definition.ts';
import { openInNewTab } from '@dev/base-web/dist/view/helpers/common';
import { CONFIG as EVENTS_CONFIG } from '../../../../screens/events/interfaces';
import { suggestionToDisplayedStep } from '@/model/domain/event/solution_suggestion.ts';
import InstructionStepList from '../../../instruction/instruction_step_list';
import { toastrError } from '@dev/base-web/dist/view/helpers/notification_helpers';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import { getLocations } from '@/model/domain/event/helper.ts';
import { Row } from '@dev/base-web/dist/view/components/global/styled_components';

const Container = styled.div`
  padding-top: 0px;
  padding-bottom: 16px;
`;

const Description = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.38;
  margin: 0;
  padding-left: 8px;
`;

const StyledLabel = styled(Label)`
  font-size: 16px;
  padding-top: 12px;
  font-weight: bold;
  padding-bottom: 0px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 500px);
  margin-bottom: 24px;
  white-space: pre-line;
`;

const WarningText = styled.p`
  margin-left: 7px;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Relative;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: normal;
  color: #242424;
  margin: 32px 0 16px 0;
`;

const StyledRow = styled(Row)`
  justify-content: flex-start;
  max-width: 100%;
  padding: 8px 0;
  gap: 5px;
`;

interface SuggestInstructionModalExternalProps {
  readonly event: EventDetail | EventDefinition;
}

interface SuggestInstructionModalDispatchProps {
  readonly getInstructionSuggestion: (
    eventDefinitionId: string,
    useCache?: boolean
  ) => void;
}

interface SuggestInstructionModalStoreProps {
  readonly suggestionMeta: LoadingMetaState;
  readonly suggestion: string | null;
}

type SuggestInstructionModalProps = SuggestInstructionModalExternalProps &
  SuggestInstructionModalDispatchProps &
  SuggestInstructionModalStoreProps;

const SuggestInstructionModal = ({
  event,
  getInstructionSuggestion,
  suggestionMeta,
  suggestion,
}: SuggestInstructionModalProps) => {
  const { color, icon } = useEventTypeLook(event.type);
  const intl = useIntl();

  const [isWarningAcknowledged, setWarningAcknowledged] = useState(false);

  const eventDefinition = 'rules' in event ? event : undefined;
  const eventDetail = 'subsequentEvents' in event ? event : undefined;
  const eventName = eventDefinition ? eventDefinition.name : eventDetail?.name;
  const eventDefId = eventDefinition ? eventDefinition.id : eventDetail?.defId;
  const manufacturingEntity =
    eventDefinition?.manufacturingEntities &&
    eventDefinition.manufacturingEntities.length === 1
      ? eventDefinition.manufacturingEntities[0]
      : eventDetail?.manufacturingEntity;
  const locations =
    eventDefinition?.manufacturingEntities &&
    eventDefinition.manufacturingEntities?.length > 1
      ? getLocations(eventDefinition)
      : undefined;

  const triggerSaveAsSolution = () => {
    openInNewTab(
      `${EVENTS_CONFIG.url.baseUrl}/actions/new?eventId=${eventDefId}&suggest=true`
    );
  };

  useEffect(() => {
    if (suggestionMeta.error)
      toastrError(intl.formatMessage({ id: 'fetching_suggestion_failed' }));
  }, [suggestionMeta.error]);

  const suggestionSteps = useMemo(() => {
    return suggestion ? suggestionToDisplayedStep(suggestion, intl.locale) : [];
  }, [suggestion, intl.locale]);

  // show manufacturing entity
  const showWarning = false;

  return (
    <ModalWithHeaderAndTwoButtons
      headerLabel={'suggest_solution_title'}
      okLabel={'close'}
      cancelLabel={'save_as_solution'}
      onModalOkPressed={() => {}}
      onModalCancelPressed={triggerSaveAsSolution}
      cancelDisabled={suggestionMeta.loadingInProgress || !suggestion}
      okDisabled={showWarning && !isWarningAcknowledged}
      triggerButton={
        <Button
          type="tertiary"
          icon="magic"
          label="suggest"
          onClick={() => {
            !showWarning &&
              eventDefId &&
              getInstructionSuggestion(eventDefId, true);
          }}
        />
      }
      modalSize={'small'}
    >
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Icon size={16} color={color} name={icon} />
              </div>
              <Description>
                <TextWithEllipsisAndTooltip text={eventName || ''} lines={2} />
              </Description>
            </div>
            <StyledRow>
              <Icon
                name={'location'}
                color={'#939393'}
                size={16}
                iconStyle={{ marginRight: 4 }}
              />
              {manufacturingEntity ? (
                <ManufacturingEntityHierarchyElement
                  manufacturingEntity={manufacturingEntity}
                  lines={3}
                />
              ) : (
                <P>{locations ? locations : '-'}</P>
              )}
            </StyledRow>
          </div>
          {(!showWarning || isWarningAcknowledged) && (
            <Button
              type="tertiary"
              icon="rotate"
              onClick={() => {
                eventDefId && getInstructionSuggestion(eventDefId, false);
              }}
            />
          )}
        </div>
        {showWarning && !isWarningAcknowledged ? (
          <>
            <WarningContainer>
              <Icon name="warning" color={'#DC8500'} size={34} />
              <WarningText>
                <FormattedMessage
                  id={'instruction_suggestion_warning'}
                  values={{ br: <br /> }}
                />
              </WarningText>
            </WarningContainer>
            <CenteredButtons>
              <Button
                type={'primary'}
                label={'generate_instruction'}
                onClick={() => {
                  if (eventDefId) {
                    setWarningAcknowledged(true);
                    getInstructionSuggestion(eventDefId, false);
                  }
                }}
              />
            </CenteredButtons>
          </>
        ) : (
          <>
            <StyledLabel>
              <FormattedMessage id={'suggested_solution'} />
            </StyledLabel>
            <ScrollContainer>
              {suggestionMeta.loadingInProgress && !suggestion ? (
                <DetailLoader />
              ) : suggestionSteps.length > 1 ? (
                <InstructionStepList
                  displayedSteps={suggestionSteps}
                  selectedLanguage={intl.locale}
                />
              ) : (
                <>{suggestion}</>
              )}
            </ScrollContainer>
            <Hint messageKey={'suggest_solution_hint'} />
          </>
        )}
      </Container>
    </ModalWithHeaderAndTwoButtons>
  );
};

const mapStateToProps = (
  state: RootReducerInterface
): SuggestInstructionModalStoreProps => ({
  suggestionMeta: state.data.eventDefinitionState.suggestedInstruction.meta,
  suggestion: state.data.eventDefinitionState.suggestedInstruction.data,
});

export default connect(mapStateToProps, {
  getInstructionSuggestion: getInstructionSuggestionStream,
})(SuggestInstructionModal);
