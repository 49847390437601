import React, { useEffect, useMemo, useState } from 'react';
import SortableTable from '@dev/base-web/dist/view/components/sortable_table';
import { FullWidthCard } from '@dev/base-web/dist/view/components/global/card';
import { INSTRUCTIONS_ROUTE_KEY, InstructionsProps } from './interfaces';
import InstructionRow from './components/instruction_row';
import {
  FilterData,
  FilterOperation,
} from '@dev/base-web/dist/model/domain/common/filter_data';
import { SortingDirection } from '@dev/base-web/dist/model/api/common/data_api_sort_config';
import {
  Column,
  ColumnFilterType,
} from '@dev/base-web/dist/view/components/sortable_table/table_header_view';
import TabBar from '@dev/base-web/dist/view/components/global/tab_bar';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchData from '@dev/base-web/dist/model/api/common/search_data';
import { InstructionOverview } from '@/model/domain/instruction/instruction';
import InstructionActionButtons from './components/instruction_action_buttons';
import { useNavigate } from 'react-router';
import {
  toastrSuccess,
  useToastrForError,
} from '@dev/base-web/dist/view/helpers/notification_helpers';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import { ScreenToolbar } from '@dev/base-web/dist/view/components/global/styled_components';
import { OperationType } from '@dev/base-web/dist/model/redux/helpers/interfaces';
import { usePrevious } from '@dev/base-web/dist/view/helpers/use_previous';
import { useAuthorizationCheckRWD } from '@dev/base-web/dist/view/components/global/user_authorization_hook';
import {
  ApplicationCategory,
  UserPrivilegePermission,
} from '@dev/base-web/dist/model/domain/user_privilege/user_privilege';
import { TableHeaderContainer } from '../../components/StyledComponents';
import useSelectableRows from '@dev/base-web/dist/view/components/sortable_table/components/selectable_table_rows_hook';
import Header from '@dev/base-web/dist/view/components/global/header_view';
import { useAppDispatch, useAppSelector } from '@/model/redux/hooks';
import ManufacturingEntityThunks from '../../model/redux/manufacturing_entity/thunks';
import LabelThunks from '../../model/redux/labels/thunks';
import { useGlobalInstructionTabSearchState } from '@/screens/common/global_config_state.ts';

const useColumns = (): Array<Column> => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const manufacturingEntities = useAppSelector(
    (state) => state.data.manufacturingEntityState.manufacturingEntities
  );
  const labels = useAppSelector((state) => state.data.labelState.labels);

  const getAllManufacturingEntities = () => {
    dispatch(ManufacturingEntityThunks.getManufacturingEntities());
  };

  useEffect(() => {
    getAllManufacturingEntities();
    void dispatch(LabelThunks.getLabels());
  }, []);

  return useMemo(
    () => [
      {
        title: 'name',
        isLeftAligned: true,
        sortKey: 'name',
        filterType: ColumnFilterType.LABEL,
        filterKey: 'label.id',
        filterOptions: labels.data,
        textFilterKey: 'name',
        isLoading: labels.meta.loadingInProgress,
        width: 5,
      },
      {
        title: 'entity',
        isLeftAligned: true,
        sortKey: 'manufacturingEntity.name',
        filterKey: 'manufacturingEntity.id',
        filterType: ColumnFilterType.MANUFACTURING_ENTITY,
        filterOptions: manufacturingEntities.data,
        width: 3,
        getAllManufacturingEntities,
        customFilterOperation: FilterOperation.CT_OPERATOR,
        isLoading: manufacturingEntities.meta.loadingInProgress,
      },
      {
        title: 'col_rating',
        isLeftAligned: true,
        sortKey: 'votes',
        filterType: ColumnFilterType.NUMBER,
        width: 2,
      },
      {
        title: 'col_access_count',
        isLeftAligned: true,
        sortKey: 'accessCount',
        filterType: ColumnFilterType.NUMBER,
        width: 1,
      },
      {
        title: 'access_level',
        isLeftAligned: true,
        sortKey: 'accessLevel',
        filterType: ColumnFilterType.SELECTOR,
        filterOptions: ['OPERATOR', 'EXPERT', 'INTERNAL'].map((l) => ({
          value: l,
          textId: intl.formatMessage({ id: l.toLowerCase() }),
        })),
        width: 2,
      },
      {
        title: 'col_author',
        isLeftAligned: true,
        sortKey: 'createdBy',
        filterType: ColumnFilterType.TEXT,
        width: 3,
      },
      {
        title: 'col_created_time',
        sortKey: 'createdDate',
        isLeftAligned: true,
        filterType: ColumnFilterType.DATE,
        width: 2,
      },
    ],
    [manufacturingEntities, labels]
  );
};

const Instructions: React.FC<InstructionsProps> = ({
  instructions,
  getInstructions,
  instructionsOperation,
  instructionsOperationError,
  instructionsOperationLoading,
  deleteInstructions,
  cleanInstruction,
  archiveInstructions,
  instructionLoadingInProgress,
  moreInstructionsCanBeLoaded,
  instructionLoadingError,
  previousInstructionsSearchData,
  showHierarchy,
  totalElements,
}) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useGlobalInstructionTabSearchState({
    key: 'tab',
  });

  const columns = useColumns();

  const [lastSearchData, setLastSearchData] = useState<SearchData>(
    previousInstructionsSearchData ?? {}
  );
  const [initialSearchData] = useState<SearchData | undefined>(
    previousInstructionsSearchData
  );
  const [selectedRows, setSelectedRows, selectRow, isRowSelected] =
    useSelectableRows<InstructionOverview>();
  const [page, setPage] = useState<number>(0);

  const isApproved = activeTab !== 2;
  const showArchive = activeTab === 1;

  const intl = useIntl();

  const getLastSortConfigParams = () => {
    const sortKey = lastSearchData.sortConfig
      ? lastSearchData.sortConfig.key
      : undefined;
    const sortDirection = lastSearchData.sortConfig
      ? lastSearchData.sortConfig.direction
      : undefined;
    return { sortKey, sortDirection };
  };

  useEffect(() => {
    const { sortKey, sortDirection } = getLastSortConfigParams();

    getInstructions(
      isApproved,
      showArchive,
      0,
      lastSearchData.filters ?? [],
      sortKey,
      sortDirection
    );

    setPage(0);
  }, [intl.locale]);

  const ROUTE_TEMPLATE = (tab?: number) => {
    const queryParams: string[] = [];
    if (tab !== undefined) {
      queryParams.push(`tab=${tab}`);
    }
    return `/${INSTRUCTIONS_ROUTE_KEY}?${queryParams.join('&')}`;
  };

  const getNextInstructions = (
    page: number,
    filters: readonly FilterData[],
    sortKey?: string,
    sortDirection?: SortingDirection,
    loadAllPagesUntilTheGivenOne?: boolean
  ) => {
    getInstructions(
      isApproved,
      showArchive,
      page,
      filters,
      sortKey,
      sortDirection,
      loadAllPagesUntilTheGivenOne
    );
    setPage(page);
    setLastSearchData({
      page,
      filters,
      ...(sortKey && sortDirection
        ? { sortConfig: { key: sortKey, direction: sortDirection } }
        : {}),
    });
  };

  const onTabChanged = (index: number) => {
    setActiveTab(index);

    navigate(ROUTE_TEMPLATE(index));

    const { sortKey, sortDirection } = getLastSortConfigParams();

    setSelectedRows([]);

    getInstructions(
      index !== 2,
      index === 1,
      0,
      lastSearchData.filters || [],
      sortKey,
      sortDirection
    );

    setPage(0);
  };

  useToastrForError(instructionsOperationError);

  const previousActionsOperationLoading = usePrevious(
    instructionsOperationLoading
  );

  useEffect(() => {
    if (
      !!previousActionsOperationLoading &&
      previousActionsOperationLoading !== instructionsOperationLoading &&
      !instructionsOperationLoading &&
      !instructionsOperationError
    ) {
      if (instructionsOperation === OperationType.UPDATE) {
        toastrSuccess(
          `${intl.formatMessage({
            id: showArchive ? 'toast_restored' : 'toast_archived',
          })}`
        );
      } else if (instructionsOperation === OperationType.DELETE) {
        toastrSuccess(`${intl.formatMessage({ id: 'delete_success' })}`);
      }

      const { sortKey, sortDirection } = getLastSortConfigParams();

      setSelectedRows([]);
      getInstructions(
        isApproved,
        showArchive,
        lastSearchData.page || 0,
        lastSearchData.filters || [],
        sortKey,
        sortDirection,
        true
      );
    }
  }, [
    previousActionsOperationLoading,
    instructionsOperation,
    instructionsOperationLoading,
    instructionsOperationError,
    showArchive,
  ]);

  const onDeletePressed = () => {
    const items = selectedRows.map((selectionAction) => selectionAction.id);
    deleteInstructions(items);
  };

  const onArchivePressed = () => {
    const items = selectedRows.map((selectionAction) => selectionAction.id);
    archiveInstructions(items, !showArchive);
  };

  useToastrForError(instructionLoadingError);

  const onNewPressed = () => {
    cleanInstruction();
    navigate(`/${INSTRUCTIONS_ROUTE_KEY}/new`);
  };

  const { write } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION,
    ApplicationCategory.SHANNON
  );
  const { delete: configDelete } = useAuthorizationCheckRWD(
    UserPrivilegePermission.ACTION_CONFIG,
    ApplicationCategory.SHANNON
  );
  const { read: userHasMaintainerPrivilege } = useAuthorizationCheckRWD(
    UserPrivilegePermission.MAINTAINER_ACTION_DEFINITIONS,
    ApplicationCategory.SHANNON
  );

  const tabBarElements = [
    <FormattedMessage id="active" />,
    <FormattedMessage id="archive" />,
    <FormattedMessage id="pending" />,
  ];

  return (
    <div style={{ backgroundColor: '#f7f7f7' }}>
      <Header
        title={ShannonConfig.Actions.label ?? ''}
        backButtonTitle={ShannonConfig.Dashboard.label}
        onBackPressed={() => navigate('/', { replace: true })}
      />
      <ScreenToolbar></ScreenToolbar>
      <FullWidthCard>
        <SortableTable
          contentOverHeader={(contentSticky) => (
            <TableHeaderContainer contentSticky={contentSticky}>
              <TabBar
                titles={tabBarElements}
                tabIndex={activeTab}
                onSelectedIndexChange={onTabChanged}
              />
              <InstructionActionButtons
                onDeletePressed={onDeletePressed}
                onArchivePressed={onArchivePressed}
                onNewPressed={onNewPressed}
                deleteEnabled={!!selectedRows.length && configDelete}
                archiveEnabled={!!selectedRows.length && configDelete}
                newEnabled={true}
                archiveShown={showArchive}
                deleteCount={selectedRows.length}
                isEditAllowed={write}
              />
            </TableHeaderContainer>
          )}
          stickyHeaderOffset={72}
          dataLength={instructions.length}
          hasMoreResults={moreInstructionsCanBeLoaded}
          columns={columns}
          getNextResults={getNextInstructions}
          initialSearchData={initialSearchData}
          loadingInProgress={instructionLoadingInProgress}
          onAllRowsCheckboxChanged={(checked) =>
            setSelectedRows(checked ? instructions : [])
          }
          page={page}
          openFiltersOnMount
          totalElements={totalElements}
        >
          {instructions.map((instruction) => {
            return (
              <InstructionRow
                key={instruction.id}
                data={instruction}
                disabled={
                  instruction.accessLevel === 'EXPERT' &&
                  !userHasMaintainerPrivilege
                }
                onRowChecked={(checked) => selectRow(instruction, checked)}
                selected={isRowSelected(instruction)}
                showManufacturingEntityHierarchy={!!showHierarchy}
              />
            );
          })}
        </SortableTable>
      </FullWidthCard>
    </div>
  );
};

export default Instructions;
