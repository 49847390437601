import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Step } from 'react-joyride';
import { CONFIG as INSTRUCTION_CONFIG } from '../../screens/instructions';

export interface StepWithTitle extends Step {
  titleText?: string;
}

export const pages: Record<string, StepWithTitle[]> = {
  dashboard: [
    {
      target: '#dashboard_link_' + INSTRUCTION_CONFIG.label,
      titleText: 'update',
      content: <FormattedMessage id="instruction_change_hint" />,
      disableBeacon: true, // This makes the tour to start automatically without clicking
    },
  ],
};
