import { RootReducerInterface } from '../../../../model/redux/interfaces';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ShannonConfig from '@dev/base-web/dist/model/domain/authorization/shannon';
import ActionsList from './view';
import { createGetInstructionsThunks } from '../../../../model/redux/instruction_definition/thunks';
import { InstructionListStoreProps } from './interfaces';

export const CONFIG = ShannonConfig.Actions;

const mapStateToProps = (
  state: RootReducerInterface
): InstructionListStoreProps => {
  return {
    instructions:
      state.data.instructionDefinitionState.instructions.data.results,
    moreInstructionsCanBeLoaded:
      state.data.instructionDefinitionState.instructions.data.hasMoreResults,
    instructionLoadingInProgress:
      state.data.instructionDefinitionState.instructions.meta.loadingInProgress,
    instructionLoadingError:
      state.data.instructionDefinitionState.instructions.meta.error,
    previousInstructionsSearchData:
      state.data.instructionDefinitionState.instructions.meta
        .previousSearchData,
  };
};

export default connect(mapStateToProps, {
  getInstructions: createGetInstructionsThunks(),
})(injectIntl(ActionsList));
