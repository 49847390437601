import { Checkbox } from '@dev/base-web/dist/view/components/global/checkbox';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import React from 'react';
import { InstructionOverview } from '../../../../../model/domain/instruction/instruction';
import { StyledRightColumn } from '../../../../../components/StyledComponents';
import { useNavigate } from 'react-router';
import { INSTRUCTIONS_ROUTE_KEY } from '../../../../instructions/interfaces';
import styled from 'styled-components';
import {
  CheckboxCell,
  LeftAlignedTableCell,
  StyledFirstCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { FormattedMessage } from 'react-intl';
import UserCell from '@dev/base-web/dist/view/components/global/user_cell';

const InstructionName = styled(StyledFirstCell)`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #242424;
`;

interface InstructionRowProps {
  data: InstructionOverview;
  onRowChecked?: (selected: boolean) => void;
  disabled?: boolean;
  selected?: boolean;
}

const InstructionRow: React.FC<InstructionRowProps> = ({
  data: { id, createdDate, name, createdBy, accessLevel },
  selected,
  disabled = false,
  onRowChecked,
}) => {
  const { formatTimestamp } = useLocalizedDateFormatters();
  const navigate = useNavigate();

  const onInstructionChecked = (event: React.FormEvent<HTMLInputElement>) => {
    if (onRowChecked) {
      onRowChecked(!selected);
    }
    event.stopPropagation();
  };

  const onRowSelected = () => {
    navigate(`/${INSTRUCTIONS_ROUTE_KEY}/${id}`);
  };

  return (
    <StyledRow
      disabled={disabled}
      cursor={disabled ? 'default' : 'pointer'}
      onClick={disabled ? undefined : onRowSelected}
    >
      {onRowChecked && (
        <CheckboxCell>
          <Checkbox
            onChange={disabled ? undefined : onInstructionChecked}
            checked={selected}
          />
        </CheckboxCell>
      )}
      <InstructionName>
        <TextWithEllipsisAndTooltip text={name} lines={2} />
      </InstructionName>
      <LeftAlignedTableCell>
        <FormattedMessage id={accessLevel.toLowerCase()} />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <UserCell user={createdBy} />
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        {formatTimestamp(createdDate)}
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <StyledRightColumn>
          <Icon name={'arrow-right-bold'} color={'#8c8c8c'} size={16} />
        </StyledRightColumn>
      </LeftAlignedTableCell>
    </StyledRow>
  );
};

export default InstructionRow;
