import { Checkbox } from '@dev/base-web/dist/view/components/global/checkbox';
import Icon from '@dev/base-web/dist/view/components/global/icon';
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  CenterAlignedTableCell,
  CheckboxCell,
  LeftAlignedTableCell,
  StyledRow,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import { useEventTypeLook } from '@dev/base-web/dist/view/components/global/common';
import Event from '../../../../model/domain/event/event_definition';

const EventName = styled.div`
  font-family: Relative;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #242424;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledEventRow = styled(StyledRow)`
  height: 64px;
`;

interface EventRowProps {
  data: Event;
  onRowChecked: (selected: boolean) => void;
  selected: boolean;
}

const EventRow: React.FC<EventRowProps> = ({
  data,
  selected,
  onRowChecked,
}) => {
  const { name, type, manufacturingEntities } = data;

  const intl = useIntl();
  const { color, icon } = useEventTypeLook(type);
  const locationSet = new Set(
    manufacturingEntities.map((entity) => entity.name)
  );
  const locations = [...locationSet].join(', ');

  const onActionChecked = (event: React.FormEvent<HTMLInputElement>) => {
    onRowChecked(!selected);
    event.stopPropagation();
  };

  const onRowSelected = () => {
    onRowChecked(!selected);
  };

  return (
    <StyledEventRow onClick={onRowSelected}>
      <CheckboxCell>
        <Checkbox onChange={onActionChecked} checked={selected} />
      </CheckboxCell>
      <CenterAlignedTableCell>
        <Icon name={icon} color={color} size={14} />
      </CenterAlignedTableCell>
      <LeftAlignedTableCell>
        <EventName>
          <TextWithEllipsisAndTooltip
            text={
              name
                ? intl.formatMessage({ id: name, defaultMessage: name })
                : '-'
            }
            lines={2}
            style={{ width: 'calc(100% - 32px)' }}
          />
        </EventName>
      </LeftAlignedTableCell>
      <LeftAlignedTableCell>
        <TextWithEllipsisAndTooltip
          text={locations}
          lines={2}
        ></TextWithEllipsisAndTooltip>
      </LeftAlignedTableCell>
    </StyledEventRow>
  );
};

export default EventRow;
