import React from 'react';
import {
  CenterAlignedTableCell,
  StyledFirstCell,
  LeftAlignedTableCell,
  StyledCell,
} from '@dev/base-web/dist/view/components/sortable_table/styled_components';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import TextWithEllipsisAndTooltip from '@dev/base-web/dist/view/components/sortable_table/components/text_with_ellipsis_and_tooltip';
import ParallelEventsEntry, {
  EventRule,
} from '../../../model/domain/parallel_events/parallel_events';
import { Button } from '@dev/base-web/dist/view/components/global/button';
import { useLocalizedDateFormatters } from '@dev/base-web/dist/view/helpers/date_hooks';
import ManufacturingEntityHierarchyElement from '@dev/base-web/dist/view/components/global/manufacturing_entity_hierarchy_element';
import { ManufacturingEntityType } from '@dev/base-web/dist/model/domain/manufacturing_entity/manufacturing_entity';

const EventsRow = styled.div`
  padding-top: 30px;
  height: 64px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    padding-top: 20px;
  }
`;

const LastOccuranceCell = styled(LeftAlignedTableCell)`
  position: relative;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  bottom: 18px;
  right: 30px;
`;

const StyledRow = styled(Table.Row)`
  vertical-align: top;
  border-radius: 15px;
  height: 64px;
  padding: 12px 3px 12px 7px;
`;

const StyledTextContainer = styled.div`
  padding-top: 20px;
`;

const StyledOccurencesCellContainer = styled.div`
  padding-top: 20px;
  font-weight: bold;
`;

interface ParallelEventsRowExternalProps {
  parallelEvents: ParallelEventsEntry;
  selectEvents: (rules: EventRule[]) => void;
}

export const ParallelEventsRow = (props: ParallelEventsRowExternalProps) => {
  const {
    parallelEvents: {
      eventRules,
      parallelOccurrences,
      singleOccurrence,
      lastOccurrence,
    },
    selectEvents,
  } = props;

  const { formatDate } = useLocalizedDateFormatters();

  const renderEventsRows = (eventRules: EventRule[]) => {
    return (
      <>
        {eventRules.map((entry) => {
          return (
            <EventsRow>
              <TextWithEllipsisAndTooltip text={entry.name} lines={1} />
            </EventsRow>
          );
        })}
      </>
    );
  };

  return (
    <StyledRow>
      <StyledFirstCell>
        <StyledTextContainer>
          <ManufacturingEntityHierarchyElement
            manufacturingEntity={eventRules[0].manufacturingEntity}
            hideHiddenTypes
            showShortNames
            hiddenTypes={[
              ManufacturingEntityType.FACTORY,
              ManufacturingEntityType.AREA,
              ManufacturingEntityType.LINE,
            ]}
            lines={2}
          />
        </StyledTextContainer>
      </StyledFirstCell>
      <StyledCell>{renderEventsRows(eventRules)} </StyledCell>
      <CenterAlignedTableCell>
        <StyledOccurencesCellContainer>
          {parallelOccurrences}
        </StyledOccurencesCellContainer>
      </CenterAlignedTableCell>
      <CenterAlignedTableCell>
        <StyledOccurencesCellContainer>
          {singleOccurrence}
        </StyledOccurencesCellContainer>
      </CenterAlignedTableCell>
      <LastOccuranceCell>
        <StyledTextContainer>
          {formatDate(lastOccurrence, 'Ppp')}
        </StyledTextContainer>
        <StyledButtonContainer>
          <Button
            type="primary"
            icon="duplicate"
            label={'merge'}
            onClick={() => {
              selectEvents(eventRules);
            }}
            // disabled={true}
          />
        </StyledButtonContainer>
      </LastOccuranceCell>
      <Table.Cell />
    </StyledRow>
  );
};
