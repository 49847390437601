import 'core-js';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import App from './App';
import 'semantic-ui-css/semantic.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import '@dev/base-web/dist/styles/global/index.css';
import '@dev/base-web/dist/resources/polyfills/abortcontroller.polyfill';
import { persistor, store } from './model/redux/store';
import { StyledToast } from '@dev/base-web/dist/view/helpers/notification_helpers';
import LocalisationWrapper from '@dev/base-web/dist/view/components/global/localisation_wrapper';
import mainTheme from '@dev/base-web/dist/view/themes/main';
import { ScrollStyle } from '@dev/base-web/dist/view/themes/helpers';
import { ToastGlobalProvider } from '@dev/base-web/dist/view/components/global/toast_global_provider';
import { notification } from './model/redux/notification';
import TenantSelectorWrapper from '@dev/base-web/dist/view/components/tenant_selector_wrapper';
import UserTenantThunks from './model/redux/user_tenant/thunks';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import userThunks from './model/redux/user/thunks';
import { UserContextProvider } from '@dev/base-web/dist/view/components/global/current_user_context';
import { ApiBlobProvider } from '@dev/rich-text-editor/dist/context/useApiBlob';
import { convertMediaToBlob } from './components/Media';

render(
  <Provider store={store}>
    <ApiBlobProvider convertUrlToBlob={convertMediaToBlob}>
      <PersistGate loading={null} persistor={persistor}>
        <UserContextProvider thunks={userThunks}>
          <LocalisationWrapper>
            <ThemeProvider theme={mainTheme}>
              <TenantSelectorWrapper
                getUserTenants={() =>
                  void (
                    store.dispatch as ThunkDispatch<any, unknown, AnyAction>
                  )(UserTenantThunks.getUserTenants())
                }
                setSelectedTenant={(tenantId: string) =>
                  (store.dispatch as ThunkDispatch<any, unknown, AnyAction>)(
                    UserTenantThunks.setSelectedTenantId(tenantId)
                  )
                }
              >
                <ToastGlobalProvider notification={notification}>
                  <ScrollStyle />
                  <App />
                  <StyledToast
                    timeOut={5000}
                    newestOnTop={false}
                    preventDuplicates
                    position="bottom-center"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                  />
                </ToastGlobalProvider>
              </TenantSelectorWrapper>
            </ThemeProvider>
          </LocalisationWrapper>
        </UserContextProvider>
      </PersistGate>
    </ApiBlobProvider>
  </Provider>,
  document.querySelector('#root')
);
