import { v4 as uuidv4 } from 'uuid';
import { DisplayedStep, StepDTO } from '../instruction/step';

export default interface CompletionChoice {
  readonly text: string;
}

export const suggestionToSteps = (
  suggestion: string,
  language: string
): StepDTO[] => {
  // eslint-disable-next-line no-useless-escape
  const suggestionSplit = suggestion.split(/\d{1,2}[\)\.]\s/);

  return suggestionSplit
    .filter((s) => s.trim() !== '')
    .map((s, idx) => {
      return {
        id: uuidv4(),
        type: 'INSTRUCTION',
        step: idx + 1,
        media: null,
        translations: [
          { language: language.toUpperCase(), translation: s.trim() },
        ],
        decisionOptions: [],
        additionalInfos: [],
      };
    });
};

export const suggestionToDisplayedStep = (
  suggestion: string,
  language: string
): DisplayedStep[] => {
  const steps = suggestionToSteps(suggestion, language);

  return steps.map((s) => {
    return {
      orig: s,
      isComplete: true,
      decisionOptions: [],
    };
  });
};
