import { BaseDataApi } from '@dev/base-web/dist/model/api/common/base_data_api';
import CompletionChoice from '../../domain/event/solution_suggestion';

const EVENT_URL = (id: string) => `/event/definition/${id}`;
export default class InstructionSuggestionApi extends BaseDataApi<CompletionChoice> {
  async getInstructionSuggestion(
    eventDefinitionId: string,
    token: string,
    useCache?: boolean
  ): Promise<CompletionChoice[]> {
    return await this.fetchAllData(
      `${EVENT_URL(
        eventDefinitionId
      )}/actions/suggest?stream=false&cache=${!!useCache}`,
      token
    );
  }
}
