import {
  SimpleSearchStateOptions,
  useNumberSearchState,
} from '@dev/base-web/dist/view/components/global/url_param_hooks';
import { useAppDispatch, useAppSelector } from '@/model/redux/hooks.ts';
import { ThunkAction } from 'redux-thunk';
import { RootReducerInterface } from '@/model/redux/interfaces.ts';
import { AnyAction } from 'redux';
import { DependencyList, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { setActiveTab } from '@/model/redux/instruction_definition/thunks.ts';

export const useGlobalConfigSync = <T>(
  value: T,
  actionCreator: (
    value: T | undefined
  ) => ThunkAction<void, RootReducerInterface, undefined, AnyAction>,
  deps: DependencyList
) => {
  const dispatch = useAppDispatch();

  const globalUpdate = useDebouncedCallback((value: T | undefined) => {
    dispatch(actionCreator(value));
  }, 1000);

  useEffect(() => {
    globalUpdate(value);
  }, [value, ...deps]);
};

export const useGlobalInstructionTabSearchState = ({
  key,
  defaultValue,
  onChange,
}: Pick<
  SimpleSearchStateOptions<number>,
  'key' | 'defaultValue' | 'onChange'
>) => {
  const globalValue = useAppSelector(
    (state) => state.data.instructionDefinitionState.activeTab
  );

  const state = useNumberSearchState({
    key,
    defaultValue: defaultValue || 0,
    initialValue: globalValue,
    onChange,
  });

  useGlobalConfigSync(state[0], (value) => setActiveTab(value || 0), []);

  return state;
};
